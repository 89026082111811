import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import {
  identificationTypeDropdownValue,
} from "../../Shared/constants";
import { useSnackbar } from "notistack";

export default function EditNextOfKin({
  open,
  onClose,
  nextofKin,
}) {

  const { enqueueSnackbar } = useSnackbar();
  const [phone_number, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [identityTypeID, setidentityTypeID] = useState("");
  const [identificationNUmber, setidentificationNUmber] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [relationship, setrelationship] = useState("");
  const [residentialLandmark, setresidentialLandmark] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [identificationType, setIdentificationType] = useState([]);

  const getIdentification = () => {
    Api()
      .get(`/general/dropdowns/${identificationTypeDropdownValue}/`)
      .then((response) => {
        setIdentificationType(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const editNextOfKin = async () => {
    const data = {
      phone_number: phone_number,
      name: name,
      identity_type_id: identityTypeID,
      id_number: identificationNUmber,
      address: homeAddress,
      relationship: relationship,
      residential_landmark: residentialLandmark,
    };

    setShowLoading(true);

    Api()
      .put(`/general/update-next-of-kin/${nextofKin.id}/`, data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
        setShowLoading(false);
      });
  };

  useEffect(() => {
    setPhoneNumber(nextofKin.phone_number);
    setName(nextofKin.name);
    setidentityTypeID(nextofKin.identity_type_id__name);
    setidentificationNUmber(nextofKin.id_number);
    sethomeAddress(nextofKin.address);
    setrelationship(nextofKin.relationship);
    setresidentialLandmark(nextofKin.residential_landmark);
  }, []);

  useEffect(() => {
    getIdentification();
  }, []);
  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Edit Next Of Kin
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-10">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Name</span>
              <input
                required
                type="text"
                name="name"
                defaultValue={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Phone Number</span>
              <input
                required
                type="tel"
                name="phone_number"
                defaultValue={phone_number}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="not-required-label">ID Type</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setidentityTypeID(e.target.value);
                }}
              >
                {identificationType?.map((idType, index) => {
                  return (
                    <option value={idType.id} key={index}>
                      {idType.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="block mt-5">
              <span className="not-required-label">ID Number</span>
              <input
                type="text"
                name="id_type"
                defaultValue={identificationNUmber}
                onChange={(e) => {
                  setidentificationNUmber(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="not-required-label">Relationship</span>
              <input
                type="text"
                name="relationship"
                defaultValue={relationship}
                onChange={(e) => {
                  setrelationship(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="not-required-label">Address</span>
              <input
                type="text"
                name="address"
                defaultValue={homeAddress}
                onChange={(e) => {
                  sethomeAddress(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="not-required-label">Residential landmark</span>
              <input
                type="text"
                name="resident"
                defaultValue={residentialLandmark}
                onChange={(e) => {
                  setresidentialLandmark(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <AddFormButton loading={showLoading} onClick={editNextOfKin} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}
