import React, {  useState } from "react";
import Api from "../../Services/api";

export default function SearchInput({
  getSearchData,
  searchURL,
  setTotalPages,
  placeholder,
  pageNumber,
}) {
  const [search, setSearch] = useState("");

  const getRepayments = async (value) => {
    const data = {
      search_query: value,
    };
    Api()
      .post(`${searchURL}/${pageNumber}/`, data)
      .then((response) => {
        getSearchData(response.data.data);
        setTotalPages(response.data.total_pages);
      })
      .catch((error) => {
      });
  };

  return (
    <div className="pl-5 pr-5 mb-5">
      <input
        type="search"
        className="border-2 rounded-md h-10 w-[23%] small:w-full mid:w-full ipad-pro:w-full ipad:w-full border-gray-400 placeholder:pl-3 pl-3"
        placeholder={placeholder}
        onChange={(e) => {
          setSearch(e.target.value);
          getRepayments(e.target.value);
        }}
      />
    </div>
  );
}
