import React, { useState } from "react";
import EarningsTable from "../Components/Tables/EarningsTable";
import OpenFormButton from "../Components/Shared/OpenFormButton";
import AddEarning from "../Components/Forms/AddEarning";

export default function Earnings() {
  const [visible, setVisible] = useState(false);

  const closeFormModal = () => setVisible(false);
  return (
    <div>
      <div className="m-5 flex justify-between ">
        <div className="text-3xl">Earnings</div>
        <OpenFormButton openModal={setVisible} buttonText="Add" />
      </div>

      <div>
        <AddEarning open={visible} onClose={closeFormModal} />
      </div>

      <div className="mt-10">
        <EarningsTable />
      </div>
    </div>
  );
}
