import React, {useState} from "react";
import AddUser from "../Components/Forms/AddUser";
import SystemUserTable from "../Components/Tables/SystemUserTable";
import OpenFormButton from "../Components/Shared/OpenFormButton";
import {
  deActivateUser, activeValue, activeUserText
} from "../Shared/constants";
import { superAdminRole } from "../Services/roles";

export default function Home() {
  const [userToPass, setUserToPass] = useState({});

  const [visible, setVisible] = useState(false);

  const closeFormModal = () => setVisible(false);


  const clearUserData = () => {
    setUserToPass({})
  }

  return (
    <div>

      <div className="m-5 flex justify-between grid-cols-3 sm:grid-cols-1 col-span-2">
      <div className="text-3xl">Active Users</div>
      {
        superAdminRole() ? (
          <OpenFormButton openModal={setVisible} buttonText='Add' clearUserData={clearUserData} />
        ) : ''
      }
        
      </div>

      <div>
      <AddUser open={visible} onClose={closeFormModal} />
      </div>

      <div className="mt-10">
        <SystemUserTable userToPass={userToPass} dataToFetchValue={activeValue} activationValue={deActivateUser} tableText={activeUserText} toolTipText="Deactivate" />
      </div>
    </div>
  );
}
