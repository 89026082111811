import React, { useState, useEffect } from "react";
import {
  repaymentCyclesValue,
  interestMethod,
  disbursed,
  gurantors,
  loanProductValue,
  loanStatusValue,
  addLoanFormTitle,
} from "../../Shared/constants";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import { useSnackbar } from "notistack";
import VerifyOTP from "./VerifyOPT";
import Cookies from "js-cookie";

export default function AddLoans({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [principal, setprincipal] = useState("");
  const [loanTitle, setloanTitle] = useState("");
  const [loanDesctiption, setloanDesctiption] = useState("");
  const [repaymentCycle, setrepaymentCycle] = useState(1);
  const [interestmethod, setinterestmethod] = useState(1);
  const [guarantorId, setguarantorId] = useState("");
  const [loanProduct, setloanProduct] = useState(1);
  const [disbursedBy, setdisbursedBy] = useState(1);
  const [loanStatus, setloanStatus] = useState(1);
  const [loanInterest, setloanInterest] = useState("");
  const [loanDuration, setloanDuration] = useState("");
  const [borrowersList, setborrowersList] = useState([]);
  const [borrowerid, setborrowerid] = useState("");
  const [openVerify, setopenVerify] = useState(false);

  const openVerifyModal = () => setopenVerify(true);
  const closeVerifyModal = () => setopenVerify(false);
  const defaultNumber = 1000000000;

  const [showLoading, setShowLoading] = useState(false);
  const [repaymentCycles, setrepaymentCycles] = useState([]);
  const [interestMethods, setinterestMethods] = useState([]);
  const [userDisbursed, setuserDisbursed] = useState([]);
  const [guarantors, setguarantors] = useState([]);
  const [loanProducts, setloanProducts] = useState([]);
  const [borrowerPhone, setborrowerPhone] = useState("");

  const [loanStatuses, setloanStatuses] = useState([]);

  const getRepaymentsCycles = () => {
    Api()
      .get(`/general/dropdowns/${repaymentCyclesValue}/`)
      .then((response) => {
        setrepaymentCycles(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getInterestMethods = () => {
    Api()
      .get(`/general/dropdowns/${interestMethod}/`)
      .then((response) => {
        setinterestMethods(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getDisbursedBy = () => {
    Api()
      .get(`/general/dropdowns/${disbursed}/`)
      .then((response) => {
        setuserDisbursed(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getGuarantors = () => {
    Api()
      .get(`/general/dropdowns/${gurantors}/`)
      .then((response) => {
        setguarantors(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getLoanProducts = () => {
    Api()
      .get(`/general/dropdowns/${loanProductValue}/`)
      .then((response) => {
        setloanProducts(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getLoanStatus = () => {
    Api()
      .get(`/general/dropdowns/${loanStatusValue}/`)
      .then((response) => {
        setloanStatuses(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getBorrowersList = async (value) => {
    Api()
      .get(`/general/get-borrower/${value !== "" ? value : defaultNumber}/`)
      .then((response) => {
        // localStorage.setItem('borrowerId', JSON.stringify(response.data.borrower))
        Cookies.set('borrowerPhone', value)
        localStorage.setItem('borrowerId', JSON.stringify(response.data.borrower) )
        setborrowersList(response.data.borrower);
        
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const sendOTP = async (value) => {
    const data = {
      phone_number: value
    }
    Api()
      .post('/loans/send-otp/', data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

//   const addLoans = async () => {
//     const data = {
//       principal: principal,
//       repayment_cycle: parseInt(repaymentCycle),
//       disbursed_by: parseInt(disbursedBy),
//       interest_method: parseInt(interestmethod),
//       guarantor_id: parseInt(guarantorId),
//       loan_product: parseInt(loanProduct),
//       loan_status: parseInt(loanStatus),
//       loan_interest: parseInt(loanInterest),
//       loan_duration: parseInt(loanDuration),
//       loan_title: loanTitle,
//       loan_description: loanDesctiption,
//     };
//     setShowLoading(true);
//     Api()
//       .post(`/loans/loans/${borrowersList[0].borrower_id}/`, data)
//       .then((response) => {
//         enqueueSnackbar(response.data.detail, {
//           variant: "success",
//           autoHideDuration: 1000,
//         });
//         setTimeout(() => {
//           window.location.reload();
//         }, 1500);
//       })
//       .catch((error) => {
//         enqueueSnackbar(error.response.data.detail, {
//           variant: "error",
//           autoHideDuration: 1500,
//         });
//         setShowLoading(false);
//       });

// };

  const saveLoanToStorage = () => {
    const data = {
      principal: principal,
      repayment_cycle: parseInt(repaymentCycle),
      disbursed_by: parseInt(disbursedBy),
      interest_method: parseInt(interestmethod),
      guarantor_id: parseInt(guarantorId),
      loan_product: parseInt(loanProduct),
      loan_status: parseInt(loanStatus),
      loan_interest: parseInt(loanInterest),
      loan_duration: parseInt(loanDuration),
      loan_title: loanTitle,
      loan_description: loanDesctiption,
    };

    localStorage.setItem('loanData', JSON.stringify(data));
    sendOTP(borrowerPhone)
    openVerifyModal();
  };

  useEffect(() => {
    getRepaymentsCycles();
    getInterestMethods();
    getDisbursedBy();
    getGuarantors();
    getLoanProducts();
    getLoanStatus();
  }, [borrowerid]);

  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {addLoanFormTitle}
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Principal</span>
              <input
                required
                type="number"
                name="principal"
                defaultValue={principal}
                onChange={(e) => {
                  setprincipal(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Title</span>
              <input
                required
                type="text"
                name="loanTitle"
                defaultValue={loanTitle}
                onChange={(e) => {
                  setloanTitle(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Duration</span>
              <input
                required
                type="number"
                name="loanDuration"
                defaultValue={loanDuration}
                onChange={(e) => {
                  setloanDuration(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">APR</span>
              <input
                required
                type="number"
                name="loanInterest"
                defaultValue={loanInterest}
                onChange={(e) => {
                  setloanInterest(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Description</span>
              <textarea
                required
                type="text"
                name="loanDesctiption"
                defaultValue={loanDesctiption}
                onChange={(e) => {
                  setloanDesctiption(e.target.value);
                }}
                className="input-style"
                style={{ height: "100px" }}
              />
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="not-required-label">Phone Number</span>
              <input
                required
                type="text"
                name="loanInterest"
                defaultValue={borrowerPhone}
                onChange={(e) => {
                  setborrowerPhone(e.target.value);
                  getBorrowersList(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Borrower</span>
              <input
                disabled
                required
                type="text"
                name="borrower"
                defaultValue={
                  borrowersList[0]?.first_name === undefined
                    ? ""
                    : borrowersList[0]?.first_name +
                      " " +
                      borrowersList[0]?.last_name
                }
                className="input-style"
                onChange={(e) => {
                  setborrowerid(e.target.value);
                }}
              />
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Repayment Cycle</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setrepaymentCycle(e.target.value);
                }}
              >
                {repaymentCycles?.map((repayCycle, index) => {
                  return (
                    <option value={repayCycle.id} key={index}>
                      {repayCycle.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="block mt-5">
              <span className="required-label">Interest Method</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setinterestmethod(e.target.value);
                }}
              >
                {interestMethods?.map((intMethod, index) => {
                  // console.log(intMethod)
                  return (
                    <option value={intMethod.id} key={index}>
                      {intMethod.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Product</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setloanProduct(e.target.value);
                }}
              >
                {loanProducts?.map((product, index) => {
                  return (
                    <option value={product.id} key={index}>
                      {product.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="block mt-5">
              <span className="required-label">Status</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setloanStatus(e.target.value);
                }}
              >
                {loanStatuses?.map((status, index) => {
                  return (
                    <option value={status.id} key={index}>
                      {status.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Disbursed By</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setdisbursedBy(e.target.value);
                }}
              >
                {userDisbursed?.map((disbursed, index) => {
                  return (
                    <option value={disbursed.id} key={index}>
                      {disbursed.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <Button auto flat color="primary" onClick={saveLoanToStorage}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      <VerifyOTP open={openVerify} onClose={closeVerifyModal} />
    </>
  );
}
