import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import moment from "moment";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Row, Tooltip, Spacer, useModal, Text } from "@nextui-org/react";
import { IconButton } from "../Icons/IconButton";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import PlusIcon from "../Icons/PlusIcon";
import DeleteIcon from "../Icons/DeleteIcon";
import DeleteData from "../Shared/DeleteData";
import EyeIcon from "../Icons/EyeIcon";
import AddRepayment from "../Forms/AddRepayment";
import Paginations from "../Shared/Pagination";
import { adminRole, superAdminRole } from "../../Services/roles.js";
import TicketIcon from "../Icons/TicketIcon";
import CreateTicket from "../Shared/CreateTicket";
import { adminsDropdownValue } from "../../Shared/constants";
import SearchInput from "../Shared/SearchInput";
import NoDataAvailable from "../Shared/NoDataAvailable";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import ApproveIcon from "../Icons/ApproveIcon";
import ApprovedIcon from "../Icons/ApprovedIcon";
import ApproveAction from "../Shared/ApproveAction";
import { EditIcon } from "../Icons/EditIcon";
import EditLoan from "../Forms/EditLoan";
import EditOutstandingLoan from "../Forms/EditOutstandingLoans";
import {currencyFormat} from "../../Services/validations"

export default function LoansTable() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setVisible, bindings } = useModal();
  const [pageNumber, setpageNumber] = useState(1);
  const [loans, setLoans] = useState([]);
  const [pageName, setpageName] = useState("");
  const [getBorrowerName, setgetBorrowerName] = useState("");
  const [loanData, setloanData] = useState('');

  const [isReady, setIsReady] = useState(false);
  const [visible, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [dataID, setdataID] = useState("");
  const [loanId, setloanId] = useState("");
  const [openEditLoan, setopenEditLoan] = useState(false);
  const loanUrl = "loans/delete-loan";

  const [visible3, setVisible3] = useState(false);
  const [approveId, setapproveId] = useState("");

  const [showOutStandingModal, setshowOutStandingModal] = useState(false);

  const approveTaskURL = "loans/approve-loan";
  const promtMessage = "Are you sure you want to Approve Loan?";

  const openApproveModal = () => setVisible3(true);

  const closeApproveModal = () => setVisible3(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const searchURL = `/loans/search-loans/${1}`;

  const [getAdmins, setgetAdmins] = useState([]);
  const routeToLoanDetails = (value) => {
    navigate("/loan-details", { state: loans });
    Cookies.set("loanID", value);
  };

  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getLoans(event);
  };

  const closeRepayModal = () => setVisible2(false);

  const sePageName = (value) => setpageName(Cookies.set("pagetitle", value));

  const closeModal = () => setVisible(false);

  const openDeleteModal = () => setVisible1(true);

  const openRepayModal = () => setVisible2(true);

  const closeDeleteModal = () => setVisible1(false);

  const openEditLoanModal = () => setopenEditLoan(true)

  const closeEditLoanModal = () => setopenEditLoan(false)

  const openOutstandingLoanModal = () => setshowOutStandingModal(true)

  const closeOutstandingLoanModal = () => setshowOutStandingModal(false)


  const getLoans = async (pageNumberSelected = 1) => {
    Api()
      .get(`/loans/get-loans/${pageNumberSelected}/`)
      .then((response) => {
        setLoans(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getSystemAdmins = async () => {
    Api()
      .get(`/general/dropdowns/${adminsDropdownValue}/`)
      .then((response) => {
        setgetAdmins(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getLoans();
    getSystemAdmins();
  }, []);

  return (
    <div>
      <SearchInput
        getSearchData={setLoans}
        setTotalPages={setTotalPages}
        searchURL={searchURL}
        placeholder="Search by name, phone no. or ID"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon />
        </div>
      ) : loans?.length === 0 ? (
        <div className="flex justify-center">
          <NoDataAvailable />
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Loan ID
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Borrower
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Principal
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Payable
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Paid
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Amnt Outstanding
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Due Date
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loans.map((loan, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    <Text
                      className=""
                      css={{
                        textGradient: "45deg, $green600 -20%, $green600 100%",
                      }}
                    >
                      {loan.id}
                    </Text>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {loan.borrower__first_name + " " + loan.borrower__last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                     {currencyFormat.format(loan.principal_amount)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                     {currencyFormat.format(loan.amount_payable)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                     {loan.total_paid === null ? " GHS 0.00" : currencyFormat.format(loan.total_paid)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {loan.amount_outstanding === null ? (
                      "Pending Approval"
                    ) : (
                      <div> {currencyFormat.format(loan.amount_outstanding)}</div>
                    )}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {loan.due_date === null
                      ? "Not set"
                      : moment(`${loan.due_date}`).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Row align="flex-start">
                      <Tooltip content="View" color="invert">
                        <IconButton
                          onClick={() => {
                            routeToLoanDetails(loan.id);
                            sePageName(loan.id);
                          }}
                        >
                          <EyeIcon fill="black" />
                        </IconButton>
                      </Tooltip>
                      <Spacer x={1} />
                      {
                        loan.is_approved && superAdminRole() ? (
                          <Tooltip content="Edit Outstanding Loan" color="primary">
                          <IconButton
                            onClick={() => {
                              setloanId(loan.id)
                              setloanData(loan)
                              openOutstandingLoanModal()
                            }}
                          >
                            <EditIcon size={20} fill="#8F00FF" />
                          </IconButton>
                        </Tooltip>
                        ) : (
                          <Tooltip content="Edit" color="primary">
                          <IconButton
                            onClick={() => {
                              setloanData(loan)
                              openEditLoanModal()
                            }}
                          >
                            <EditIcon size={20} fill="#007FFF" />
                          </IconButton>
                        </Tooltip>
                        )
                      }
                      <Spacer x={1} />
                      {adminRole() || superAdminRole() ? (
                        loan.is_approved ? (
                          <Tooltip content="Already Approved" color="success">
                            <ApprovedIcon fill="green" />
                          </Tooltip>
                        ) : (
                          <Tooltip content="Approve" color="primary">
                            <IconButton
                              onClick={() => {
                                setapproveId(loan.id);
                                openApproveModal();
                              }}
                            >
                              <ApproveIcon fill="#8F00FF" />
                            </IconButton>
                          </Tooltip>
                        )
                      ) : (
                        ""
                      )}

                      <Spacer x={1} />
                      {loan.is_approved ? (
                        <Tooltip
                          content={
                            loan.is_paid_off ? "Paid Off" : "Add Repayment"
                          }
                          color="primary"
                        >
                          <IconButton
                            onClick={() => {
                              setloanId(loan.id);
                              openRepayModal();
                              setgetBorrowerName(loan.borrower__first_name + " " + loan.borrower__last_name)
                            }}
                            disabled={loan.is_paid_off}
                          >
                            <PlusIcon size={20} fill="#8F00FF" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Task" color="warning">
                          <IconButton
                            onClick={() => {
                              setdataID(loan.id);
                              setVisible(true);
                            }}
                          >
                            <TicketIcon size={20} fill="#FFC300" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Spacer x={1} />
                      {loan.is_approved ? (
                        ""
                      ) : (
                        <Tooltip content="Delete" color="error">
                          <IconButton
                            onClick={() => {
                              setdataID(loan.id);
                              openDeleteModal();
                            }}
                          >
                            <DeleteIcon fill="#D22B2B" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Row>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {loans.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady ? "" : "List of Loans"}
        </div>
      )}

      <CreateTicket
        getAdmins={getAdmins}
        bindings={bindings}
        closeModal={closeModal}
      />
      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <ApproveAction
        visible={visible3}
        url={approveTaskURL}
        approveId={approveId}
        onClose={closeApproveModal}
        promptMessage={promtMessage}
      />

      <DeleteData
        visible={visible}
        url={loanUrl}
        deleteId={dataID}
        onClose={closeDeleteModal}
      />

      <EditLoan open={openEditLoan} onClose={closeEditLoanModal} loanData={loanData} />

      <AddRepayment open={visible2} onClose={closeRepayModal} loanId={loanId} getBorrowerName={getBorrowerName} />

      <EditOutstandingLoan open={showOutStandingModal} onClose={closeOutstandingLoanModal} data={loanData} Id={loanId} />
    </div>
  );
}

