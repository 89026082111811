import React, { useState, useEffect } from "react";
import AddFormButton from "../Shared/AddFormButton";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import { useSnackbar } from "notistack";
import {expenseTypes} from "../../Services/validations"

export default function AddExpenses({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setAmount] = useState("");
  const [expenseType, setexpenseType] = useState(1);
  const [expensesData, setexpensesData] = useState([]);

  const [showLoading, setShowLoading] = useState(false);


  const expensesType = () => {
    Api()
      .get(`/general/dropdowns/${17}/`)
      .then((response) => {
        setexpensesData(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const addExpenses = async (e) => {
    e.preventDefault();
    const data = {
      amount,
      expense_type_id:  parseInt(expenseType) ,
    };

    if( amount === "" || expenseType === "") {
      enqueueSnackbar('Fill all required fields', {
        variant: "info",
        autoHideDuration: 1500,
      });
    }else {
      setShowLoading(true);
      Api()
        .post("/transactions/add-expense/", data)
        .then((response) => {
          enqueueSnackbar(response.data.detail, {
            variant: "success",
            autoHideDuration: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            autoHideDuration: 1500,
          });
          setShowLoading(false);
        });
    }

  };

  useEffect(() => {
    expensesType()
  }, []);


  return (
    <div>
      <>
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={open}
          onClose={onClose}
          scroll
          width="600px"
          preventClose
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Add Expenses
            </Text>
          </Modal.Header>
          <Modal.Body className="small:m-0 m-5">
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">Amount</span>
                <input
                  required
                  type="number"
                  name="opening"
                  defaultValue={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
              <span className="required-label">Expense Type</span>
              <select
                className="input-style"
                onChange={(e) => {
                    setexpenseType(e.target.value);
                }}
              >
                {expensesData.map((expense, index) => {
                  return (
                    <option value={expense.id} key={index}>
                      {expense.name}
                    </option>
                  );
                })}
              </select>
            </label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button auto flat color="error" onClick={onClose}>
              Close
            </Button>
            <AddFormButton loading={showLoading} onClick={addExpenses} />
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}
