import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import { useSnackbar } from "notistack";

export default function EditRepayment({ open, onClose, repayData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setamount] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const editRepayment = async () => {
    const data = {
      amount: amount,
    };
    setShowLoading(true);
    Api()
      .put(`/loans/edit-repayment/${repayData.id}/`, data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        setShowLoading(false);
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    setamount(repayData?.amount);
  }, []);
  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Edit Repayment
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Amount</span>
              <input
                required
                type="number"
                name="amount"
                defaultValue={repayData?.amount || amount}
                onChange={(e) => {
                  setamount(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <AddFormButton loading={showLoading} onClick={editRepayment} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
