import {getCurrentUser} from "./auth"

export function adminRole () {
    const role = getCurrentUser().role__name
    return (role === "Admin")
}

export function superAdminRole () {
    const role = getCurrentUser().role__name
    return (role === "Super Admin")
}