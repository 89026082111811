import { useState, useEffect } from "react";
import Api from "../Services/api";
import moment from "moment";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, Tooltip } from "@nextui-org/react";
import Guarantor from "../Views/Guarantor";
import BounceLoader from "react-spinners/BounceLoader";
import { EditIcon } from "../Components/Icons/EditIcon";
import { IconButton } from "../Components/Icons/IconButton";
import EditLoan from "../Components/Forms/EditLoan";
import { useDisclosure } from "@chakra-ui/react";
import FileUploadService from "../Services/fileupload";
import Document from "../Components/Icons/Document";
import { FcSurvey } from "react-icons/fc";
import { FiChevronLeft } from "react-icons/fi";
import { useSnackbar } from "notistack";
import DeleteLoanIcon from "../Components/Icons/DeleteLoanIcon";
import DeleteData from "../Components/Shared/DeleteData";
import ImageIcon from "../Components/Icons/ImageIcon";
import DocxIcon from "../Components/Icons/DocxIcon";
import Rating from "@mui/material/Rating";
import { currencyFormat } from "../Services/validations";

export default function DetailsOfLoan() {
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [loanDetails, setloanDetails] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [guarantorDetails, setguarantorDetails] = useState([]);
  const [documents, setdocuments] = useState([]);
  const [profilePic, setprofilePic] = useState();
  const [userToPass, setUserToPass] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [visible, setVisible1] = useState(false);
  const [docId, setdocId] = useState("");
  let location = useLocation();
  const [fileLoading, setfileLoading] = useState(false);
  const [paySlipDetails, setpaySlipDetails] = useState([]);

  const documentURL = "documents/delete-file";

  const openDeleteModal = () => setVisible1(true);

  const closeDeleteModal = () => setVisible1(false);

  const dataID = Cookies.get("loanID")
    ? Cookies.get("loanID")
    : location.state[0].id;

  const defaultImage = "./images/default-avatar.png";

  const goBack = () => {
    navigate(-1);
  };

  const openForm = () => {
    onOpen();
  };

  const uploadFiles = (event) => {
    let fileList = event.target.files;

    Array.from(fileList).map((file) => {
      if (file.size > 20000000) {
        enqueueSnackbar("File size should not exceed 20MB", {
          variant: "error",
          autoHideDuration: 1500,
        });
      } else {
        setfileLoading(true);
        let url = `/documents/upload-loan-documents/${loanDetails.id}/`;
        FileUploadService.uploadFiles(file, url)
          .then((response) => {
            enqueueSnackbar(response.data.detail, {
              variant: "success",
              autoHideDuration: 1000,
            });
            setfileLoading(false);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data.detail, {
              variant: "error",
              autoHideDuration: 1500,
            });
          });
      }
    });
  };

  const getLoanDetails = () => {
    Api()
      .get(`/loans/get-loan/${dataID}/`)
      .then((response) => {
        setloanDetails(response.data.data.loan);
        setprofilePic(response.data.data);
        setguarantorDetails(response.data.data.guarantor);
        setdocuments(response.data.data.documents);
        setpaySlipDetails(response.data.data.payslip);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const generateReport = () => {
    Api()
      .get(`/reports/loan-statement/${dataID}/`)
      .then((response) => {
        navigate("/report", { state: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getLoanDetails();
  }, [dataID]);
  return (
    <div>
      {!isReady ? (
        <div className="mt-32 flex justify-center">
          <BounceLoader color="#007FFF" size={80} />
        </div>
      ) : (
        <>
          <div className="flex justify-between p-5">
            <FiChevronLeft
              className="cursor-pointer"
              size={30}
              title="Go Back"
              onClick={goBack}
            />

            <FcSurvey
              className="cursor-pointer"
              size={30}
              title="Generate Report"
              onClick={generateReport}
            />
          </div>
          <div className="grid grid-cols-3 small:grid-cols-1 p-5">
            {loanDetails.in_arrears === "No" ? (
              <Text
                className="text-2xl flex justify-center"
                css={{
                  textGradient: "45deg, $green600 -20%, $green600 100%",
                }}
              >
                In Arrears: {loanDetails.in_arrears}
              </Text>
            ) : (
              <Text
                className="text-2xl flex justify-center"
                css={{
                  textGradient: "45deg, $red600 -20%, $red600 100%",
                }}
              >
                In Arrears: {loanDetails.in_arrears}
              </Text>
            )}

            <Text
              className="text-2xl flex justify-center"
              css={{
                textGradient: "45deg, $green600 -20%, $green600 100%",
              }}
            >
              {dataID}
            </Text>

            {loanDetails.arrears_amount === "N/A" ? (
              <Text
                className="text-2xl flex justify-center"
                css={{
                  textGradient: "45deg, $green600 -20%, $green600 100%",
                }}
              >
                Arrears Amnt: {currencyFormat.format(0.00)}
              </Text>
            ) : (
              <Text
                className="text-2xl flex justify-center"
                css={{
                  textGradient: "45deg, $red600 -20%, $red600 100%",
                }}
              >
                Arrears Amnt:
                {currencyFormat.format(loanDetails?.arrears_amount)}
              </Text>
            )}
          </div>
          <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-5">
            <div className="card-background h-96 p-5">
              <div className=" mb-40">
                <div className="flex justify-center group mt-2">
                  <div className="absolute z-10 rounded-[50%] w-40 h-40 hidden group-hover:flex justify-center items-center bg-[rgba(22,21,21,0.5)]"></div>
                  <img
                    className="absolute rounded-[50%] w-40 h-40 object-cover"
                    alt="Profile img"
                    src={
                      profilePic.profile_image !== ""
                        ? profilePic.profile_image
                        : defaultImage
                    }
                  />
                </div>
              </div>
              <Text className="flex justify-center font-normal text-2xl">
                {loanDetails.borrower__first_name +
                  " " +
                  loanDetails.borrower__last_name}
              </Text>

              <div className="flex justify-center mt-2">
                <Rating
                  name="read-only"
                  value={loanDetails.loyalty}
                  precision={0.5}
                  readOnly
                  size="large"
                />
              </div>

              <div className="flex justify-center items-center w-full mt-3">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col justify-center items-center w-full h-20 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col justify-center items-center pt-2 pb-6">
                    <svg
                      aria-hidden="true"
                      className=" w-10 h-10 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className=" text-sm text-gray-500 dark:text-gray-400">
                      Click to upload loan documents.
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    accept=".docx, .ppt, .pptx, .xlx, .xlxs, .doc, .pdf, .jpeg, .png"
                    onChange={uploadFiles}
                    multiple="allowMultiple"
                  />
                </label>
              </div>
            </div>
            <div className="col-span-2 small:col-span-full   card-background p-5">
              <div className="flex justify-end">
                {loanDetails.is_approved ? (
                  ""
                ) : (
                  <Tooltip content="Edit" color="primary">
                    <IconButton
                      onClick={() => {
                        setUserToPass(loanDetails);
                        openForm();
                      }}
                    >
                      <EditIcon size={20} fill="#007FFF" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Principal Amnt:</span>
                    {currencyFormat.format(loanDetails.principal_amount)}
                  </Text>
                </div>
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">Amnt Payable: </span>

                    {currencyFormat.format(loanDetails.amount_payable)}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">Amnt Outstanding: </span>

                    {currencyFormat.format(loanDetails.amount_outstanding)}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Installments:</span>{" "}
                    {currencyFormat.format(loanDetails.amount_per_repayment)}
                  </Text>
                </div>
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">Amnt Paid: </span>
                    {currencyFormat.format(loanDetails.total_paid)}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">No Of Repayment:</span>{" "}
                    {loanDetails.number_of_repayment}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Duration:</span>{" "}
                    {loanDetails.loan_duration} Month(s)
                  </Text>
                </div>
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">APR %: </span>{" "}
                    {loanDetails.loan_interest}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">Payment Schedule:</span>{" "}
                    {loanDetails.repayment_cycle__name}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Interest Method:</span>{" "}
                    {loanDetails.interest_method__name}
                  </Text>
                </div>
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">Product Name: </span>{" "}
                    {loanDetails.loan_product__name}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">Status:</span>{" "}
                    {loanDetails.loan_status__name}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Requested On:</span>{" "}
                    {moment(`${loanDetails.requested_on}`).format("lll")}
                  </Text>
                </div>
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">Approved On: </span>{" "}
                    {loanDetails.approved_on === null
                      ? "Not Approved"
                      : moment(`${loanDetails.approved_on}`).format("lll")}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">Due Date:</span>{" "}
                    {loanDetails.due_date === null
                      ? "Not Set"
                      : moment(`${loanDetails.due_date}`).format("lll")}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-2 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Approved By:</span>{" "}
                    {loanDetails.approved_by__first_name === null
                      ? ""
                      : loanDetails.approved_by__first_name +
                        " " +
                        loanDetails.approved_by__last_name}
                  </Text>
                </div>
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">Appraised By: </span>{" "}
                    {loanDetails.appraised_by__first_name +
                      " " +
                      loanDetails.appraised_by__last_name}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-2 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Title:</span>{" "}
                    {loanDetails.loan_title}
                  </Text>
                </div>
                <div>
                  <Text>
                    <span className="text-style">Disbursed Through:</span>{" "}
                    {loanDetails.disbursed_by__name}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-1 gap-4 p-2">
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">Description: </span>{" "}
                    {loanDetails.loan_description}
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 p-5">
            <div className="card-background small:col-span-full   h-full p-5">
              <Text size={20}>Loan Documents</Text>

              {fileLoading ? (
                <div className=" flex justify-center">
                  <BounceLoader color="#007FFF" size={80} />
                </div>
              ) : (
                documents?.map((doc, index) => {
                  return (
                    <>
                      <div className="flex justify-between" key={index}>
                        <div className="flex justify-start mb-2 mt-2">
                          {doc?.document_url.split("/")[6].split(".")[1] ===
                          "pdf" ? (
                            <Document fill="red" />
                          ) : doc?.document_url.split("/")[6].split(".")[1] ===
                              "docx" ||
                            doc?.document_url.split("/")[6].split(".")[1] ===
                              "doc" ? (
                            <DocxIcon fill="blue" />
                          ) : doc?.document_url.split("/")[6].split(".")[1] ===
                              "jpg" ||
                            doc?.document_url.split("/")[6].split(".")[1] ===
                              "png" ? (
                            <ImageIcon fill="black" />
                          ) : (
                            <Document />
                          )}

                          <a
                            className="ml-1 hover:text-blue-500"
                            title="Click to download"
                            href={doc.document_url}
                            download
                          >
                            {doc?.document_url.split("/")[6]}
                          </a>
                        </div>

                        <div className="mt-2">
                          <Tooltip content="Delete doc" color="error">
                            <IconButton
                              onClick={() => {
                                setdocId(doc.id);
                                openDeleteModal();
                              }}
                            >
                              <DeleteLoanIcon fill="#D22B2B" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </div>
            <div className="col-span-2 small:col-span-full  card-background h-full p-5">
              <Guarantor guarantorDetails={guarantorDetails} loanId={dataID} />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 p-5">
            <div className="small:col-span-full   h-full p-5"></div>

            <div className="col-span-2 small:col-span-full  card-background h-full p-5">
              {paySlipDetails.length === 0 ? (
                <div className="flex justify-center">
                  <Text size={20}>No Payslip available.</Text>
                </div>
              ) : (
                <div className="grid grid-cols-4 small:grid-cols-1 gap-4">
                  {" "}
                  <Text className="">
                    <span className="text-style">Gross Salary: </span>

                    {currencyFormat.format(paySlipDetails.gross_salary)}
                  </Text>
                  <Text className="">
                    <span className="text-style">Total Deductions: </span>

                    {currencyFormat.format(paySlipDetails.total_deductions)}
                  </Text>
                  <Text className="">
                    <span className="text-style">Income Tax:</span>
                    {currencyFormat.format(paySlipDetails.income_tax)}
                  </Text>
                  <Text className="">
                    <span className="text-style">SSNIT:</span>
                    {currencyFormat
                      .format(paySlipDetails.ssnit)}
                  </Text>
                  <Text className="">
                    <span className="text-style">TPRS Affordability: </span>

                    {currencyFormat.format(paySlipDetails.tprs_affordability)}
                  </Text>
                  <Text className="">
                    <span className="text-style">Net Salary: </span>

                    {currencyFormat.format(paySlipDetails.net_salary)}
                  </Text>
                  <Text className="">
                    <span className="text-style">True Affordability:</span>{" "}
                    
                    {currencyFormat.format(paySlipDetails.true_affordability)}
                  </Text>
                </div>
              )}
            </div>
          </div>

          <DeleteData
            visible={visible}
            url={documentURL}
            deleteId={docId}
            onClose={closeDeleteModal}
          />

          <EditLoan
            userToPass={userToPass}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
          />
        </>
      )}
    </div>
  );
}
