import React from "react";
import PortfolioTable from "../Components/Tables/PortfolioTable";

export default function Portfolio() {
  return (
    <div>
      <div className="m-5 flex justify-start ">
        <div className="text-3xl">Portfolio</div>
      </div>
      <div className="mt-5">
        <PortfolioTable />
      </div>
    </div>
  );
}
