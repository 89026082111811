import React, { useState} from "react";
import ProfitTable from "../Components/Tables/ProfitTable";
import OpenFormButton from "../Components/Shared/OpenFormButton";
import Addprofit from "../Components/Forms/Addprofit";

export default function Profit() {
  const [visible, setVisible] = useState(false);

  const closeFormModal = () => setVisible(false);
  return (
    <div>
      <div className="m-5 flex justify-between ">
      <div className="text-3xl">Profit And Loss</div>
        <OpenFormButton openModal={setVisible} buttonText="Add" />
      </div>

      <Addprofit open={visible} onClose={closeFormModal} />
      <ProfitTable />
    </div>
  );
}
