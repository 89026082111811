import { useState, useEffect } from "react";
import Api from "../Services/api";
import Cookies from "js-cookie";
import { Text, Modal, useModal } from "@nextui-org/react";
import NextOfKin from "./NextOfKin";
import Payslip from "./Payslip";
import BounceLoader from "react-spinners/BounceLoader";
import FileUploadService from "../Services/fileupload";
import Loading from "../Components/Icons/Loading";
import Camera from "../Components/Icons/Camera";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { useSnackbar } from "notistack";


export default function DetailsOfBorrower() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setVisible, bindings } = useModal();
  const [borrowerDetails, setborrowerDetails] = useState([]);
  const [nextofKin, setnextofKin] = useState([]);
  const [payslipDetails, setpayslipDetails] = useState([]);

  const [nextOfKinVis, setnextOfKinVis] = useState(false);

  const openNextOfKin = () => setnextOfKinVis(true);

  const defaultImage = "./images/default-avatar.png";

  const [imageUploading, setimageUploading] = useState(false);
  const [file, setFile] = useState("");
  const [imagePreview, setFilePreview] = useState("");
  const [isReady, setIsReady] = useState(false);


  const goBack = () => {
    navigate(-1);
  };

  const closeImage = (event) => {
    setFile(null);
    setFilePreview(null);
  };

  const getImage = (event) => {
    setFile(event.target.files[0]);
    setFilePreview(URL.createObjectURL(event.target.files[0]));
  };

  const uploadImage = (event) => {
    setimageUploading(true);
    const url = `/documents/upload-profile-image/${dataID}/`;
    FileUploadService.uploadGeneral(file, url)
      .then((response) => {
        setimageUploading(false);
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const dataID = Cookies.get("borrowerID");

  const getBorrowerDetails = () => {
    Api()
      .get(`/general/borrower/${dataID}/`)
      .then((response) => {
        setborrowerDetails(response.data.data.borrower);
        setnextofKin(response.data.data.next_of_kin);
        setpayslipDetails(response.data.data.payslip);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getBorrowerDetails();
  }, [dataID]);
  return (
    <div>
      {!isReady ? (
        <div className="mt-32 flex justify-center">
          <BounceLoader color="#007FFF" size={80} />
        </div>
      ) : (
        <>
          <div className="flex justify-start p-5">
            <FiChevronLeft
              className="cursor-pointer mt-1"
              size={30}
              title="Go Back"
              onClick={goBack}
            />{" "}
            <div className="text-3xl mb-1">Borrower Details</div>
          </div>
          <div className="grid grid-cols-3 small:grid-cols-1 gap-4 small:gap-0  p-5">
            <div className="card-background h-[110%] small:h-full p-2 ">
              <div className=" mb-40">
                <div
                  className="flex justify-center group mt-2"
                  onClick={() => {
                    setVisible(true);
                    setFilePreview(borrowerDetails?.profile_image);
                  }}
                >
                  <div className="absolute z-10 rounded-[50%] w-40 h-40 hidden group-hover:flex justify-center items-center bg-[rgba(22,21,21,0.5)]">
                    <Camera />
                  </div>
                  <img
                    className="absolute rounded-[50%] w-40 h-40 object-cover"
                    src={
                      borrowerDetails.profile_image !== ""
                        ? borrowerDetails.profile_image
                        : defaultImage
                    }
                    alt="Profile Image"
                  />
                </div>
              </div>

              <Text className="flex justify-center font-normal text-2xl pb-1">
                {borrowerDetails.first_name + " " + borrowerDetails.last_name}
              </Text>
              <Text className="borrower-details pb-1">
                {borrowerDetails.primary_phone_number}
              </Text>
              <Text className="borrower-details pb-1">
                {borrowerDetails.city}{" "}
                {borrowerDetails.city === "" ? "" : <> <span className="ml-1 mr-1" style={{color: 'springgreen'}}> | </span></>}{" "}
                {borrowerDetails.country__name}
              </Text>
              <Text className="borrower-details pb-1">
                {borrowerDetails.bank_name}
              </Text>

              <Text className="borrower-details pb-1">
                {borrowerDetails.bank_account_number}
                  <span className="ml-1 mr-1" style={{color: 'springgreen'}}> | </span>
                  {borrowerDetails.bank_branch}
              </Text>

              <div
                className={
                  borrowerDetails.borrower_status_id === 1
                    ? "bg-buttonColor status-card"
                    : borrowerDetails.borrower_status_id === 2
                    ? "bg-green-500 status-card"
                    : borrowerDetails.borrower_status_id === 3
                    ? "bg-orange-600 status-card"
                    : "bg-red-500 status-card"
                }
              >
                {borrowerDetails.borrower_status__name}
              </div>
            
            </div>
            <div className="col-span-2 small:grid-cols-1 small:mt-4 card-background h-full p-5">
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">CAGD Agency: </span>
                    {borrowerDetails.cagd_agency}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">CAGD Department: </span>
                    {borrowerDetails.cagd_department}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">CAGD District:</span>{" "}
                    {borrowerDetails.cagd_district}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">CAGD Region:</span>{" "}
                    {borrowerDetails.cagd_region}
                  </Text>
                </div>
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">ID Type: </span>{" "}
                    {borrowerDetails.identity_type__name}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">ID Number:</span>{" "}
                    {borrowerDetails.identification_number}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Marital Status:</span>{" "}
                    {borrowerDetails.marital_status}
                  </Text>
                </div>
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">Address: </span>{" "}
                    {borrowerDetails.home_address}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">Landmark:</span>{" "}
                    {borrowerDetails.residential_landmark}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <div>
                  <Text className="">
                    <span className="text-style">Gender:</span>{" "}
                    {borrowerDetails.gender}
                  </Text>
                </div>
                <div>
                  <Text>
                    <span className="text-style">Working Status:</span>{" "}
                    {borrowerDetails.working_status}
                  </Text>
                </div>
                <div>
                  <Text className="">
                    <span className="text-style">Staff ID:</span>{" "}
                    {borrowerDetails.staff_id}
                  </Text>
                </div>
              </div>
              <hr style={{ height: "1px", background: "black" }} />
              <div className="grid grid-cols-1 gap-4 p-2">
                <div>
                  {" "}
                  <Text className="">
                    <span className="text-style">Description: </span>{" "}
                    {borrowerDetails.description}
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 p-5">
            <div></div>
            <div className="col-span-2 small:col-span-full card-background h-full p-5">
              <Payslip payslip={payslipDetails} borrowerID={dataID} />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 p-5">
            <div></div>
            <div className="col-span-2 small:col-span-full  card-background h-full p-5">
              <NextOfKin nextofKin={nextofKin} borrowerID={dataID} />
            </div>
          </div>
        </>
      )}

      <Modal
        closeButton
        scroll
        width="600px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        {...bindings}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Profile Picture
          </Text>
        </Modal.Header>
        <Modal.Body>
          {imagePreview && (
            <div className="flex justify-center mt-3 mb-4">
              <img
                className=""
                src={imagePreview || borrowerDetails.profile_image}
                alt=""
              />
            </div>
          )}

          {imagePreview ? (
            ""
          ) : (
            <div className="justify-center mt-4 mb-5">
              <label for="image" className="">
                Drag a picture into the box or{" "}
                <span style={{ color: "#0C4767", opacity: "70%" }}>
                  click here
                </span>{" "}
                to upload profile image.
              </label>
              <label for="image" className="">
                Tap here to upload profile image
              </label>
              <input
                type="file"
                id="image"
                className=""
                accept="image/x-png,image/gif,image/jpeg, image/jpg"
                onChange={getImage}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {imagePreview === null ? (
            ""
          ) : (
            <input
              type="file"
              id="image"
              className=""
              accept="image/x-png,image/gif,image/jpeg, image/jpg"
              onChange={getImage}
            />
          )}

          <button className="closeButtonStyle" onClick={closeImage}>
            <p className="font-medium text-white">Change</p>
          </button>
          <button className="saveButtonStyle" onClick={uploadImage}>
            <p className="font-medium text-white">
              {imageUploading ? <Loading /> : "Upload"}{" "}
            </p>
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
