import React, {useState} from "react";
import OpenFormButton from "./OpenFormButton";
import { Text } from "@nextui-org/react";
import AddGuarantor from "../Forms/AddGuarantor";

export default function OpenGurantorForm({loanId}) {

  const [openGua, setopenGua] = useState(false);

  const closeGuarantorForm = () => setopenGua(false)

  return (
    <div>
      <div className="flex justify-end ">
        <OpenFormButton openModal={setopenGua} buttonText="Add" />
      </div>

      <div className="flex justify-center">
        <Text size={20}>No Guarantor added.</Text>
      </div>

      <AddGuarantor open={openGua} onClose={closeGuarantorForm} loanId={loanId} />
    </div>
  );
}
