import React from 'react'
import TransactionStatsTable from '../Components/Tables/TransactionStatsTable'

export default function TransactionsStats() {
  return (
    <div>
    <div className="m-5 flex justify-start ">
     <div className='text-3xl'>Transactions Stats</div>
   </div>

   <div className="mt-10">
   <TransactionStatsTable />
   </div>
   
 </div>
  )
}
