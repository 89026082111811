import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import moment from "moment";
import { superAdminRole } from "../../Services/roles.js";
import { Row, Tooltip, Spacer, Modal, Button, Text } from "@nextui-org/react";
import { EditIcon } from "../Icons/EditIcon";
import { IconButton } from "../Icons/IconButton";
import EditUser from "../Forms/EditUser";
import Paginations from "../Shared/Pagination";
import SearchInput from "../Shared/SearchInput";
import NoDataAvailable from "../Shared/NoDataAvailable";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import ApproveIcon from "../Icons/ApproveIcon";
import DeactivateIcon from "../Icons/DeactivateIcon";
import Loading from "../../Components/Icons/Loading"


export default function SystemUserTable({
  activationValue,
  tableText,
  dataToFetchValue,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setpageNumber] = useState(1);
  const [systemUser, setSystemUser] = useState([]);
  const [userToPass, setUserToPass] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [loading, setloading] = useState(false);
  const [getUserKey, setgetUserKey] = useState('');

  const [visible, setVisible] = useState(false);

  const openFormModal = () => setVisible(true);

  const closeFormModal = () => setVisible(false);

  const [visible3, setVisible3] = useState(false);

  const openApproveModal = () => setVisible3(true);

  const closeApproveModal = () => setVisible3(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const searchURL = `/auth/search-users/${dataToFetchValue}`;

  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getSystemUsers(event);
  };

  const deactivateActivateUser = async () => {
    Api()
      .post(`/auth/change-user-access/${getUserKey}/${activationValue}/`)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getSystemUsers = async (pageNumberSelected = 1) => {
    Api()
      .post(`/auth/system-users/${pageNumberSelected}/${dataToFetchValue}/`)
      .then((response) => {
        setSystemUser(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getSystemUsers();
  }, []);

  return (
    <div>
      <SearchInput
        getSearchData={setSystemUser}
        searchURL={searchURL}
        setTotalPages={setTotalPages}
        placeholder="Search by name"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon />
        </div>
      ) : systemUser?.length === 0 ? (
        <div className="flex justify-center">
          <NoDataAvailable />
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Phone Number
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Role
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Date
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {systemUser.map((user, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {user.first_name + " " + user.last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user.phone_number}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user.role__name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(`${user.registration_date}`).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Row align="">
                      {superAdminRole() ? (
                        user.is_active ? (
                          <Tooltip content="Deactivate" color="error">
                            <IconButton
                              onClick={() => {
                                setgetUserKey(user.user_key)
                                openApproveModal();
                              }}
                            >
                              <DeactivateIcon fill="#D22B2B" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip content="Activate" color="primary">
                            <IconButton
                              onClick={() => {
                                setgetUserKey(user.user_key);
                                openApproveModal();
                                
                              }}
                            >
                              <ApproveIcon fill="#8F00FF" />
                            </IconButton>
                          </Tooltip>
                        )
                      ) : (
                        ""
                      )}
                      <Spacer x={1} />
                    {
                      superAdminRole() ? (
                        user.is_active ? (
                          <Tooltip content="Edit" color="primary">
                            <IconButton
                              onClick={() => {
                                setUserToPass(user);
                                openFormModal();
                              }}
                            >
                              <EditIcon size={20} fill="#007FFF" />
                            </IconButton>
                          </Tooltip>
                        ) : ''
                      ) : ''
                    }
                    </Row>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {systemUser.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady ? "" : tableText}
        </div>
      )}

      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible3}
        onClose={closeApproveModal}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
           Are you are you want to perform this Action?
          </Text>
        </Modal.Header>
        <Modal.Footer>
          <Button auto flat color="error" onClick={closeApproveModal}>
            Cancel
          </Button>
          <Button auto onClick={deactivateActivateUser}>
            {loading ? <Loading /> : "Yes"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <EditUser
        userToPass={userToPass}
        open={visible}
        onClose={closeFormModal}
      />
    </div>
  );
}
