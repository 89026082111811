import React from "react";
import RepymentsTable from "../Components/Tables/RepymentsTable";

export default function Repayments() {
  return (
    <div>
      <div className="m-5 flex justify-start ">
        <div className="text-3xl">Repayments</div>
      </div>
      <div className="mt-10">
        <RepymentsTable />
      </div>
    </div>
  );
}
