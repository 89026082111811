import React, { useState } from "react";
import Api from "../../Services/api";
import { addPayslipFormTitle } from "../../Shared/constants";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import { useSnackbar } from "notistack";

export default function AddPayslip({ open, onClose, borrowerIDs }) {
  const { enqueueSnackbar } = useSnackbar();
  const [salary, setsalary] = useState("");
  const [deductions, setdeductions] = useState("");
  const [tax, settax] = useState("");
  const [ssnit, setssnit] = useState("");
  const [affordability, setaffordability] = useState("");
  const [afford, setAfford] = useState("");
  const [netSalary, setnetSalary] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const addPayslip = async () => {
    const data = {
      gross_salary: salary,
      total_deductions: deductions,
      income_tax: tax,
      ssnit: ssnit,
      tprs_affordability: affordability,
      true_affordability: afford,
      net_salary: netSalary

    };

    setShowLoading(true);

    Api()
      .post(`/general/payslips/${borrowerIDs}/`, data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
        setShowLoading(false);
      });
  };

  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {addPayslipFormTitle}
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 gap-4">
            <label className="block mt-5">
              <span className="required-label">Gross Salary</span>
              <input
                required
                type="number"
                name="gross_salary"
                defaultValue={salary}
                onChange={(e) => {
                  setsalary(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Total Deductions</span>
              <input
                required
                type="number"
                name="deductions"
                defaultValue={deductions}
                onChange={(e) => {
                  setdeductions(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <label className="block mt-5">
              <span className="required-label">Income Tax</span>
              <input
                required
                type="number"
                name="tax"
                defaultValue={tax}
                onChange={(e) => {
                  settax(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">SSNIT</span>
              <input
                required
                type="number"
                name="tax"
                defaultValue={ssnit}
                onChange={(e) => {
                  setssnit(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <label className="block mt-5">
              <span className="required-label">True Affordability</span>
              <input
                required
                type="number"
                name="afford"
                defaultValue={afford}
                onChange={(e) => {
                  setAfford(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Net Salary</span>
              <input
                required
                type="number"
                name="tax"
                defaultValue={netSalary}
                onChange={(e) => {
                  setnetSalary(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">TPRS Affordability</span>
              <input
                type="number"
                name="affordability"
                defaultValue={affordability}
                onChange={(e) => {
                  setaffordability(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <AddFormButton loading={showLoading} onClick={addPayslip} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
