import React from "react";
import TopSideBar from "./TopSidebar";

export default function Sidebar({ pages }) {
  return (
    <div className="">
      <TopSideBar page={pages} />
    </div>
  );
}
