import React, { useState } from "react";
import { Text, Tooltip } from "@nextui-org/react";
import { EditIcon } from "../Components/Icons/EditIcon";
import { IconButton } from "../Components/Icons/IconButton";
import OpenPayslipForm from "../Components/Shared/OpenPayslipForm";
import EditPayslip from "../Components/Forms/EditPayslip";
import { currencyFormat } from "../Services/validations";

export default function Payslip({ payslip, borrowerID }) {
  const [payslipVis, setpayslipVis] = useState(false);

  const openPayslip = () => setpayslipVis(true);

  const closePayslip = () => setpayslipVis(false);

  return (
    <div>
      {payslip?.length <= 0 ? (
        <>
          <OpenPayslipForm borrowerID={borrowerID} />
        </>
      ) : (
        <>
          <div className="flex justify-between mb-5">
            <div>
              <Text size={20}>Payslip Details</Text>
            </div>
            <div className="flex justify-between">
              <Tooltip content="Edit" color="primary">
                <IconButton
                  onClick={() => {
                    openPayslip();
                  }}
                >
                  <EditIcon size={20} fill="#007FFF" />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
              <div>
                {" "}
                <Text className="">
                  <span className="text-style">Gross Salary: </span>
                  {currencyFormat.format(payslip.gross_salary)}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">Total Deductions: </span>
                  {currencyFormat.format(payslip.total_deductions)}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">Income Tax:</span>{" "}
                 {currencyFormat.format(payslip.income_tax)}
                </Text>
              </div>
            </div>
            <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
              <div>
                <Text className="">
                  <span className="text-style">SSNIT:</span>{currencyFormat.format(payslip.ssnit)}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">TPRS Affordability: </span>
                 {currencyFormat.format(payslip.tprs_affordability)}
                </Text>
              </div>

              <div>
                <Text className="">
                  <span className="text-style">Net Salary: </span>
                 {currencyFormat.format(payslip.net_salary)}
                </Text>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 p-2">
            <div>
                <Text className="">
                  <span className="text-style">True Affordability:</span>{" "}
                 {currencyFormat.format(payslip.true_affordability)}
                </Text>
              </div>
            </div>
          </div>
        </>
      )}

      <EditPayslip open={payslipVis} onClose={closePayslip} payslip={payslip} />

    </div>
  );
}
