import React, { useState } from "react";
import OpenFormButton from "./OpenFormButton";
import { Text } from "@nextui-org/react";
import AddPayslip from "../Forms/AddPayslip";

export default function OpenPayslipForm({ borrowerID }) {
  const [payslipVis, setpayslipVis] = useState(false);

  const closePayslip = () => setpayslipVis(false);
  return (
    <div>
      <div className="flex justify-end ">
        <OpenFormButton openModal={setpayslipVis} buttonText="Add" />
      </div>

      <div className="flex justify-center">
        <Text size={20}>No payslip for this borrower.</Text>
      </div>

      <AddPayslip
        open={payslipVis}
        onClose={closePayslip}
        borrowerIDs={borrowerID}
      />
    </div>
  );
}
