import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import { Modal, Text } from "@nextui-org/react";
import Loading from "../../Components/Icons/Loading";
import { useSnackbar } from "notistack";

export default function CreateTicket({ bindings, getAdmins, closeModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const [admin, setadmin] = useState('');
  const [taskMessage, settaskMessage] = useState("");
  const [loading, setloading] = useState();


  const createTicket = async () => {
    const data = {
      message: taskMessage,
    };

    if(taskMessage === "") {
      enqueueSnackbar('Message is required.', {
        variant: "warning",
        autoHideDuration: 1500,
      });
    } else {
      setloading(true);
      Api()
        .put(`/transactions/add-ticket/${ admin != "" ? admin : getAdmins[0]?.user_key}/`, data)
        .then((response) => {
          enqueueSnackbar(response.data.detail, {
            variant: "success",
            autoHideDuration: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          setloading(false);
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            autoHideDuration: 1500,
          });
          setloading(false);
        });
    }
  };
  useEffect(() => {
    
  }, [getAdmins]);
  return (
    <div>
      <Modal
        scroll
        width="600px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        {...bindings}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Create Task
          </Text>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-2">
              <span className="required-label">User</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setadmin(e.target.value);
                }}
              >
                {getAdmins.map((admin, index) => {
                  return (
                    <option value={admin.user_key} key={index}>
                      {admin.first_name + " " + admin.last_name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-2">
              <span className="required-label">Message</span>
              <textarea
                required
                rows={3}
                type="text"
                name="event_id"
                defaultValue={taskMessage}
                onChange={(e) => {
                  settaskMessage(e.target.value);
                }}
                className="input-style"
                style={{ height: "100px" }}
              />
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="closeButtonStyle" onClick={closeModal}>
            <p className="font-medium text-white">Cancel</p>
          </button>
          <button className="saveButtonStyle" onClick={createTicket}>
            <p className="font-medium text-white">
              {loading ? <Loading /> : "Create"}{" "}
            </p>
          </button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}
