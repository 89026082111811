import React, { useState } from "react";
import { Text, Tooltip, Spacer } from "@nextui-org/react";
import { EditIcon } from "../Components/Icons/EditIcon";
import { IconButton } from "../Components/Icons/IconButton";
import DeleteIcon from "../Components/Icons/DeleteIcon";
import OpenNextOfKinForm from "../Components/Shared/OpenNextOfKinForm";
import EditNextOfKin from "../Components/Forms/EditNextOfKin";
import DeleteData from "../Components/Shared/DeleteData";

export default function NextOfKin({ nextofKin, borrowerID }) {
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [deleteID, setdeleteID] = useState("");
  const url = "general/delete-next-of-kin";

  const openDeletePopup = () => setopenDeleteModal(true);
  const closeDeletePopup = () => setopenDeleteModal(false);

  const [openNextOfKin, setopenNextOfKin] = useState(false);

  const openNextOfKinfForm = () => setopenNextOfKin(true)

  const closeNextOfKin = () => setopenNextOfKin(false)


  return (
    <div>
      {nextofKin?.length <= 0 ? (
        <>
          <OpenNextOfKinForm borrowerID={borrowerID} />
        </>
      ) : (
        <>
          <div className="flex justify-between mb-5">
            <div>
              <Text size={20}>Next Of Kin Details</Text>
            </div>
            <div className="flex justify-between">
              <Tooltip content="Edit" color="primary">
                <IconButton
                  onClick={() => {
                    openNextOfKinfForm();
                  }}
                >
                  <EditIcon size={20} fill="#007FFF" />
                </IconButton>
              </Tooltip>
              <Spacer x={1} />
              <Tooltip content="Delete" color="error">
                <IconButton
                  onClick={() => {
                    openDeletePopup();
                    setdeleteID(nextofKin.id);
                  }}
                >
                  <DeleteIcon fill="#D22B2B"/>
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
              <div>
                {" "}
                <Text className="">
                  <span className="text-style">Name: </span>
                  {nextofKin.name}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">Phone No: </span>
                  {nextofKin.phone_number}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">ID Type:</span>{" "}
                  {nextofKin.identity_type__name}
                </Text>
              </div>
            </div>
            <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
              <div>
                <Text className="">
                  <span className="text-style">ID No:</span>{" "}
                  {nextofKin.id_number}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">Residential Address: </span>
                  {nextofKin.address}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">Relationship:</span>{" "}
                  {nextofKin.relationship}
                </Text>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <Text className="">
                  <span className="text-style">Residential Landmark:</span>{" "}
                  {nextofKin.residential_landmark}
                </Text>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <EditNextOfKin
          open={openNextOfKin}
          onClose={closeNextOfKin}
          nextofKin={nextofKin}
          borrowersID={borrowerID}
        />
      </div>

      <DeleteData
        deleteId={deleteID}
        visible={openDeleteModal}
        onClose={closeDeletePopup}
        url={url}
      />
    </div>
  );
}
