import React, {useState} from 'react'
import OpenFormButton from "../Components/Shared/OpenFormButton";
import AddTransaction from '../Components/Forms/AddTransaction';
import TransactionsTabs from './TransactionsTab';

export default function Transactions() {
  const [visible, setVisible] = useState(false);

  const closeFormModal = () => setVisible(false);
  return (
    <div>
       <div className="m-5 flex justify-between ">
        <div className='text-3xl'>Transactions</div>
        <OpenFormButton openModal={setVisible} buttonText='Add' />
      </div>

      <div>
      <AddTransaction open={visible} onClose={closeFormModal} />
      </div>

      <div className="mt-10">
       <TransactionsTabs />
      </div>
      
    </div>
  )
}
