import React, { useState } from "react";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import { useSnackbar } from "notistack";
import Loading from "../../Components/Icons/Loading"

export default function ApproveAction({ visible, onClose, approveId, url, promptMessage }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setloading] = useState(false);

  const approveRecord = async () => {
    setloading(true)
    Api()
      .put(`/${url}/${approveId}/`)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };
  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={onClose}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {promptMessage}
          </Text>
        </Modal.Header>
        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Cancel
          </Button>
          <Button auto onClick={approveRecord}>
            {loading ? <Loading /> : 'Yes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
