import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import {
  addBorrowerFormTitle,
  businessGroupDropdownValue,
  businessStatusDropdownValue,
  identificationTypeDropdownValue,
  genderType,
  maritalStatus,
  workStatus,
} from "../../Shared/constants";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import { useSnackbar } from "notistack";

export default function AddBorrower({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [countryID, setcountryID] = useState("");
  const [borrwerGroupID, setborrwerGroupID] = useState("");
  const [borrowerStatusID, setborrowerStatusID] = useState("");
  const [bankName, setbankName] = useState("");
  const [bankBranch, setbankBranch] = useState("");
  const [identityTypeID, setidentityTypeID] = useState("");
  const [identificationNUmber, setidentificationNUmber] = useState("");
  const [gender, setgender] = useState("Male");
  const [phoneNumber, setphoneNumber] = useState("");
  const [bankAccountNumber, setbankAccountNumber] = useState("");
  const [staffID, setstaffID] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [marritalStatus, setmarritalStatus] = useState("Married");
  const [homeAddress, sethomeAddress] = useState("");
  const [city, setcity] = useState("");
  const [description, setdescription] = useState("");
  const [reseidentialLandmark, setreseidentialLandmark] = useState("");
  const [cagdRegion, setcagdRegion] = useState("");
  const [cagdDistrict, setcagdDistrict] = useState("");
  const [cagdAgency, setcagdAgency] = useState("");
  const [cagdDepartment, setcagdDepartment] = useState("");
  const [cagdUnit, setcagdUnit] = useState("");
  const [workingStatus, setworkingStatus] = useState("Self Employed");

  const [showLoading, setShowLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [businessGroup, setbusinessGroup] = useState([]);
  const [businessStatus, setbusinessStatus] = useState([]);
  const [identificationType, setIdentificationType] = useState([]);

  const getCountries = async () => {
    Api()
      .get("/general/all-countries/")
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getBussinessGroups = () => {
    Api()
      .get(`/general/dropdowns/${businessGroupDropdownValue}/`)
      .then((response) => {
        setbusinessGroup(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getBussinessStatus = () => {
    Api()
      .get(`/general/dropdowns/${businessStatusDropdownValue}/`)
      .then((response) => {
        setbusinessStatus(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getIdentification = () => {
    Api()
      .get(`/general/dropdowns/${identificationTypeDropdownValue}/`)
      .then((response) => {
        setIdentificationType(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const addBorrower = async (e) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      country_id: countryID,
      borrower_group_id: borrwerGroupID,
      borrower_status_id: borrowerStatusID,
      bank_name: bankName,
      bank_branch: bankBranch,
      identity_type_id: identityTypeID,
      identification_number: identificationNUmber,
      gender: gender,
      primary_phone_number: phoneNumber,
      bank_account_number: bankAccountNumber,
      staff_id: staffID,
      date_of_birth: dateOfBirth,
      marital_status: marritalStatus,
      home_address: homeAddress,
      city: city,
      description: description,
      residential_landmark: reseidentialLandmark,
      cagd_region: cagdRegion,
      cagd_district: cagdDistrict,
      cagd_agency: cagdAgency,
      cagd_department: cagdDepartment,
      cagd_unit: cagdUnit,
      working_status: workingStatus,
    };

    setShowLoading(true);
    Api()
      .post("/general/borrower/", data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getCountries();
    getBussinessGroups();
    getBussinessStatus();
    getIdentification();
  }, []);
  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
        
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {addBorrowerFormTitle}
          </Text>
        </Modal.Header>
          <Modal.Body className="small:m-0 m-5">
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">First Name</span>
                <input
                  required
                  type="text"
                  name="first_name"
                  defaultValue={firstName}
                  onChange={(e) => {
                    setfirstName(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="required-label">Last Name</span>
                <input
                  required
                  type="text"
                  name="last_name"
                  defaultValue={lastName}
                  onChange={(e) => {
                    setlastName(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">Phone Number</span>
                <input
                  required
                  type="tel"
                  name="phone_number"
                  defaultValue={phoneNumber}
                  onChange={(e) => {
                    setphoneNumber(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Country</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setcountryID(e.target.value);
                  }}
                >
                  {countries?.map((country, index) => {
                    return (
                      <option value={country.id} key={index}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Borrower Group</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setborrwerGroupID(e.target.value);
                  }}
                >
                  {businessGroup?.map((bussinesGroup, index) => {
                    return (
                      <option value={bussinesGroup.id} key={index}>
                        {bussinesGroup.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Borrower Status</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setborrowerStatusID(e.target.value);
                  }}
                >
                  {businessStatus?.map((bussinesStatus, index) => {
                    return (
                      <option value={bussinesStatus.id} key={index}>
                        {bussinesStatus.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Bank Name</span>
                <input
                  type="text"
                  name="bank_name"
                  defaultValue={bankName}
                  onChange={(e) => {
                    setbankName(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Bank Branch</span>
                <input
                  type="text"
                  name="bank_branch"
                  defaultValue={bankBranch}
                  onChange={(e) => {
                    setbankBranch(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">ID Type</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setidentityTypeID(e.target.value);
                  }}
                >
                  {identificationType?.map((idType, index) => {
                    return (
                      <option value={idType.id} key={index}>
                        {idType.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="block mt-5">
                <span className="not-required-label">ID Number</span>
                <input
                  required
                  type="text"
                  name="bank_branch"
                  defaultValue={identificationNUmber}
                  onChange={(e) => {
                    setidentificationNUmber(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Bank Account No.</span>
                <input
                  type="text"
                  name="bank_number"
                  defaultValue={bankAccountNumber}
                  onChange={(e) => {
                    setbankAccountNumber(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Gender</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setgender(e.target.value);
                  }}
                >
                  {genderType?.map((gender, index) => {
                    return (
                      <option value={gender.value} key={index}>
                        {gender.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Staff ID</span>
                <input
                  type="text"
                  name="staff_id"
                  defaultValue={staffID}
                  onChange={(e) => {
                    setstaffID(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Date Of Birth</span>
                <input
                  type="date"
                  name="date_of_birth"
                  defaultValue={dateOfBirth}
                  onChange={(e) => {
                    setdateOfBirth(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Marital Status</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setmarritalStatus(e.target.value);
                  }}
                >
                  {maritalStatus?.map((marritalStatus, index) => {
                    return (
                      <option value={marritalStatus.value} key={index}>
                        {marritalStatus.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Home Address</span>
                <input
                  type="text"
                  name="home_address"
                  defaultValue={homeAddress}
                  onChange={(e) => {
                    sethomeAddress(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Description</span>
                <textarea
                  type="text"
                  style={{ height: "100px" }}
                  name="description"
                  defaultValue={description}
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">City</span>
                <input
                  type="text"
                  name="city"
                  defaultValue={city}
                  onChange={(e) => {
                    setcity(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Residential Landmark</span>
                <input
                  type="text"
                  name="residential_landmark"
                  defaultValue={reseidentialLandmark}
                  onChange={(e) => {
                    setreseidentialLandmark(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">CAGD Region</span>
                <input
                  type="text"
                  name="cagd_region"
                  defaultValue={cagdRegion}
                  onChange={(e) => {
                    setcagdRegion(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="not-required-label">CAGD District</span>
                <input
                  type="text"
                  name="cagd_district"
                  defaultValue={cagdDistrict}
                  onChange={(e) => {
                    setcagdDistrict(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">CAGD Agency</span>
                <input
                  type="text"
                  name="cagd_agency"
                  defaultValue={cagdAgency}
                  onChange={(e) => {
                    setcagdAgency(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="not-required-label">CAGD Department</span>
                <input
                  type="text"
                  name="cagd_department"
                  defaultValue={cagdDepartment}
                  onChange={(e) => {
                    setcagdDepartment(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Working Status</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setworkingStatus(e.target.value);
                  }}
                >
                  {workStatus?.map((status, index) => {
                    return (
                      <option value={status.name} key={index}>
                        {status.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="block mt-5">
                <span className="not-required-label">CAGD Unit</span>
                <input
                  type="text"
                  name="cagd_unit"
                  defaultValue={cagdUnit}
                  onChange={(e) => {
                    setcagdUnit(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button auto flat color="error" onClick={onClose}>
              Close
            </Button>
            <AddFormButton loading={showLoading} onClick={addBorrower}  />
          </Modal.Footer>
      </Modal>
    </>
  );
}
