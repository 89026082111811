import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Paginations from "../Shared/Pagination";
import SearchInput from "../Shared/SearchInput";
import { Row, Tooltip } from "@nextui-org/react";
import { IconButton } from "../Icons/IconButton";
import { useSnackbar } from "notistack";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import NoDataAvailable from "../Shared/NoDataAvailable";
import ApproveIcon from "../Icons/ApproveIcon";
import ApproveAction from "../Shared/ApproveAction";
import ApprovedIcon from "../Icons/ApprovedIcon";

export default function TicketsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setpageNumber] = useState(1);
  const [tickets, settickets] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [visible, setVisible] = useState(false);
  const [approveId, setapproveId] = useState('');

  const approveTaskURL = 'transactions/complete-ticket'
  const promtMessage = "Are you sure you want to Complete Task?";

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const openApproveModal = () => setVisible(true);

  const closeApproveModal = () => setVisible(false);

  const searchURL = `/transactions/search-tickets-received`;


  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getTickets(event);
  };

  const getTickets = async (pageNumberSelected = 1) => {
    Api()
      .post(`/transactions/tickets-received/${pageNumberSelected}/`)
      .then((response) => {
        settickets(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getTickets();
  }, []);

  return (
    <div>
      <SearchInput
        getSearchData={settickets}
        searchURL={searchURL}
        setTotalPages={setTotalPages}
        placeholder="Search by name of sender"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon color="#007FFF" size={80} />
        </div>
      ) : tickets?.length <= 0 ? (
        <p className="flex justify-center">
          <NoDataAvailable />
        </p>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Message
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Issuer
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Date
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tickets.map((ticket, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" className="w-[50%]">
                    {ticket.message}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {ticket.issuer__first_name + " " + ticket.issuer__last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(`${ticket.issued_date}`).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {
                      ticket.is_completed ? (
                        <Tooltip content="Completed" color="primary">
                        <ApprovedIcon fill="green" />
                    </Tooltip>
                      ) : (
                        <Row align="flex-start">
                        <Tooltip content="Complete" color="primary">
                          <IconButton onClick={() => {
                            setapproveId(ticket.id)
                            openApproveModal()
                          }}>
                            <ApproveIcon fill='#8F00FF' />
                          </IconButton>
                        </Tooltip>
                      </Row>
                      )
                    }
              
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {tickets.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">{!isReady ? "" : "List of Tasks"}</div>
      )}
      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <ApproveAction  
        visible={visible}
        url={approveTaskURL}
        approveId={approveId}
        onClose={closeApproveModal}
        promptMessage={promtMessage}
      />
    </div>
  );
}
