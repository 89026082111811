import React from "react";
import { inActiveValue } from "../Shared/constants";
import BorrowerTable from "../Components/Tables/BorrowerTable";

export default function InactiveBorrower() {
  return (
    <div>
      <div className="m-5 flex justify-start ">
        <div className="text-3xl">Inactive Borrowers</div>
      </div>

      <div className="mt-10">
        <BorrowerTable
          tableText="List of Inactive Borrowers."
          dataToFetchValue={inActiveValue}
        />
      </div>
    </div>
  );
}
