import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import NoDataAvailable from "../Shared/NoDataAvailable";
import Paginations from "../Shared/Pagination";
import SearchInput from "../Shared/SearchInput";
import { useSnackbar } from "notistack";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import { currencyFormat } from "../../Services/validations";
import EyeIcon from "../Icons/EyeIcon";
import { Row, Tooltip, Text } from "@nextui-org/react";
import { IconButton } from "../Icons/IconButton";
import GetOverDueLoan from "../Views/GetOverDueLoan";

export default function OverdueLoansTable({ type }) {
  const { enqueueSnackbar } = useSnackbar();
  const [overDues, setoverDues] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [gerOverdueLoan, setgerOverdueLoan] = useState();

  const [getSinlgeView, setgetSinlgeView] = useState(false);

  const openView = () => setgetSinlgeView(true);

  const closeView = () => setgetSinlgeView(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const searchURL = `/loans/search-loans/${0}`;

  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getOverdueLoans(event);
  };

  const getOverdueLoans = async (pageNumberSelected = 1) => {
    Api()
      .get(`/loans/overdue-loans/${type}/${pageNumberSelected}/`)
      .then((response) => {
        setoverDues(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getOverdueLoans();
  }, []);
  return (
    <div>
      <SearchInput
        getSearchData={setoverDues}
        setTotalPages={setTotalPages}
        searchURL={searchURL}
        placeholder="Search by borrower name"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon />
        </div>
      ) : overDues?.length <= 0 ? (
        <div className="flex justify-center">
          <NoDataAvailable />
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Loan ID
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Borrower
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Principal
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Payable
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Paid
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Amnt Outstanding
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Due Date
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {overDues.map((overdue, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                  <Text
                      className=""
                      css={{
                        textGradient: "45deg, $green600 -20%, $green600 100%",
                      }}
                    >
                      {overdue.id}
                    </Text>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {overdue.borrower__first_name +
                      " " +
                      overdue.borrower__last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {currencyFormat.format(overdue.principal_amount)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {currencyFormat.format(overdue.amount_payable)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {overdue.total_paid === null
                      ? " 0.00"
                      : currencyFormat.format(overdue.total_paid)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {overdue.amount_outstanding === null
                      ? "0.00"
                      : currencyFormat.format(overdue.amount_outstanding)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {overdue.due_date === null
                      ? "Not set"
                      : moment(`${overdue.due_date}`).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Row align="flex-start">
                      <Tooltip content="View" color="invert">
                        <IconButton
                          onClick={() => {
                            setgerOverdueLoan(overdue);
                            openView();
                          }}
                        >
                          <EyeIcon fill="black" />
                        </IconButton>
                      </Tooltip>
                    </Row>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {overDues.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady ? "" : "List of Overdue Loans"}
        </div>
      )}

      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <GetOverDueLoan
        open={getSinlgeView}
        onClose={closeView}
        gerOverdueLoan={gerOverdueLoan}
      />
    </div>
  );
}
