import React, { useState } from "react";
import Api from "../Services/api";
import { Text, Spacer } from "@nextui-org/react";
import Loading from "../Components/Icons/Loading";
import { logoutUser } from "../Services/auth";
import { useSnackbar } from "notistack";

export default function ChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const [confirmPassword, setconfirmPassword] = useState("");
  const [inputType, setinputType] = useState("password");
  const [inputType1, setinputType1] = useState("password");
  const [inputType2, setinputType2] = useState("password");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const passwordToggle = (value) => {
    if (value === "text") {
      setinputType("password");
    } else {
      setinputType("text");
    }
  };

  const passwordToggle1 = (value) => {
    if (value === "text") {
      setinputType1("password");
    } else {
      setinputType1("text");
    }
  };

  const passwordToggle2 = (value) => {
    if (value === "text") {
      setinputType2("password");
    } else {
      setinputType2("text");
    }
  };

  const ChangePass = async (e) => {
    e.preventDefault();
    const changePass = {
      password: oldPassword,
      new_password: password,
    };

    setShowLoading(true);
    Api()
      .put("/auth/change-password/", changePass)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          logoutUser();
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const checkPasswordValidity = (value) => {
    if (password.length > 0 && value.length > 0 && password !== value) {
      setShowPasswordError(true);
      seterrorMessage("Passwords do not match.");
    } else {
      setShowPasswordError(false);
    }
  };


  return (
    <div className="flex justify-center py-40 small:py-20 ">

      <form onSubmit={ChangePass}>
      <p className="flex justify-center  page-leading-text">Change Password </p>
        <div className="change-password">
          <span className="required-label">Current Password</span>
          <span className="flex">
            <input
            required
              type={inputType}
              defaultValue={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              className="auth-input"
              placeholder="Old Password"
            />
            <p
              onClick={() => {
                passwordToggle(inputType);
              }}
              className="-ml-11 mt-2 cursor-default"
              aria-label=""
            >
              {inputType === "password" ? "Show" : "Hide"}{" "}
            </p>
          </span>
          <Spacer y={0.7} />

          <span className="required-label">New Password</span>
          <span className="flex">
            <input
            required
              type={inputType1}
              defaultValue={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              className="auth-input"
              placeholder="New Password"
            />
            <p
              onClick={() => {
                passwordToggle1(inputType1);
              }}
              className="-ml-11 mt-2 cursor-default"
              aria-label=""
            >
              {inputType1 === "password" ? "Show" : "Hide"}{" "}
            </p>
          </span>
          <Spacer y={0.7} />

          <span className="required-label">Confirm Password</span>

          <span className="flex">
            <input
            required
              type={inputType2}
              defaultValue={confirmPassword}
              onChange={(e) => {
                setconfirmPassword(e.target.value);
                checkPasswordValidity(e.target.value);
              }}
              className="auth-input"
              placeholder="New Password"
            />
            <p
              onClick={() => {
                passwordToggle2(inputType2);
              }}
              className="-ml-11 mt-2 cursor-default"
              aria-label=""
            >
              {inputType2 === "password" ? "Show" : "Hide"}{" "}
            </p>
          </span>
          {showPasswordError ? (
            <Text size={14} color="red" css={{ margin: "$0" }}>
              {errorMessage}
            </Text>
          ) : (
            ``
          )}

          <button
            type="submit"
            className={
              showPasswordError
                ? "bg-gray-500 mt-5 w-full disabled-button"
                : "mt-5 w-80 button-prop"
            }
            disabled={showPasswordError}
          >
            {" "}
            {showLoading ? <Loading /> : `Submit`}
          </button>
        </div>
      </form>
    </div>
  );
}
