import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import {
  addTransactionFormTitle,
  identificationTypeDropdownValue,
  transactionTypeDropdownValue,
  agencyDropdownValue,
} from "../../Shared/constants";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import { useSnackbar } from "notistack";

export default function AddTransaction({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setamount] = useState("");
  const [transactionTypeId, settransactionTypeId] = useState(1);
  const [identityTypeID, setidentityTypeID] = useState(1);
  const [identificationNumber, setidentificationNumber] = useState("");
  const [bankName, setbankName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [bankAccountNumber, setbankAccountNumber] = useState("");
  const [bankBranch, setbankBranch] = useState("");
  const [customerName, setcustomerName] = useState("");
  const [agencyId, setagencyId] = useState(2);

  const [identificationType, setIdentificationType] = useState([]);
  const [agencyIds, setagencyIds] = useState([]);
  const [transactionsType, settransactionsType] = useState([]);

  const [showLoading, setShowLoading] = useState(false);

  const getIdentification = () => {
    Api()
      .get(`/general/dropdowns/${identificationTypeDropdownValue}/`)
      .then((response) => {
        setIdentificationType(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getTransactionsType = () => {
    Api()
      .get(`/general/dropdowns/${transactionTypeDropdownValue}/`)
      .then((response) => {
        settransactionsType(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getAgencies = () => {
    Api()
      .get(`/general/dropdowns/${agencyDropdownValue}/`)
      .then((response) => {
        setagencyIds(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const addTransaction = async (e) => {
    e.preventDefault();
    const data = {
      amount: amount,
      transaction_type_id: parseInt(transactionTypeId),
      identity_type_id: parseInt(identityTypeID),
      agency_id: parseInt(agencyId),
      identification_number: identificationNumber,
      customer_phone_number: phoneNumber,
      bank_account_number: bankAccountNumber,
      bank_account_branch: bankBranch,
      customer_name: customerName,
      bank_name: bankName
    };

    setShowLoading(true);
    Api()
      .post("/transactions/transaction/", data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
        setShowLoading(false);
      });
  };
  useEffect(() => {
    getIdentification();
    getTransactionsType();
    getAgencies();
  }, []);
  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {addTransactionFormTitle}
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Name</span>
              <input
                required
                type="text"
                name="customer_name"
                defaultValue={customerName}
                onChange={(e) => {
                  setcustomerName(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Amount</span>
              <input
                required
                type="number"
                name="amount"
                defaultValue={amount}
                onChange={(e) => {
                  setamount(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Phone No.</span>
              <input
                required
                type="tel"
                name="phone_number"
                defaultValue={phoneNumber}
                onChange={(e) => {
                  setphoneNumber(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Transaction Type</span>
              <select
                className="input-style"
                onChange={(e) => {
                  settransactionTypeId(e.target.value);
                }}
              >
                {transactionsType?.map((transact, index) => {
                  return (
                    <option value={transact.id} key={index}>
                      {transact.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
          <label className="block mt-5">
              <span className="not-required-label">Bank Name</span>
              <input
                type="text"
                name="identity_number"
                defaultValue={bankName}
                onChange={(e) => {
                  setbankName(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="not-required-label">Bank Account No.</span>
              <input
                type="text"
                name="bank_account"
                defaultValue={bankAccountNumber}
                onChange={(e) => {
                  setbankAccountNumber(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 gap-4">
          <label className="block mt-5">
              <span className="not-required-label">Bank Branch</span>
              <input
                type="text"
                name="bank_branch"
                defaultValue={bankBranch}
                onChange={(e) => {
                  setbankBranch(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="not-required-label">Agency</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setagencyId(e.target.value);
                }}
              >
                {agencyIds?.map((agency, index) => {
                  return (
                    <option value={agency.id} key={index}>
                      {agency.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="not-required-label">Identity</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setidentityTypeID(e.target.value);
                }}
              >
                {identificationType?.map((identity, index) => {
                  return (
                    <option value={identity.id} key={index}>
                      {identity.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="block mt-5">
              <span className="not-required-label">Identification No.</span>
              <input
                type="text"
                name="identity_number"
                defaultValue={identificationNumber}
                onChange={(e) => {
                  setidentificationNumber(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
        
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <AddFormButton loading={showLoading} onClick={addTransaction} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
