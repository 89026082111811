
// regex for enail validation
export const validEmail = (email) => {
    const re = /^(?=.*\d)(?=.*[!@#\]$~/%[^&|`'//?.{}+_\\,;=\-()<>":}*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(String(email).toLowerCase());
}

  // regex for checking the requirements of password
  export const passw = /^(?=.*\d)(?=.*[!@#\]$~/%[^&|`'//?.{}+_\\,;=\-()<>":}*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

// regex for phone number validation
export const phoneValidation = /^\d{10}$/;


// curency format
export const currencyFormat = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GHS",
});

const d = new Date();

// get current year
export const year = d.getFullYear()

export function eventTypes(){
  return [
      { name: 'Loan', value: '1'},
      { name: 'Transaction', value: '3'},
      { name: 'Repayment', value: '2'},
  ];
}

export function overdueTypes(){
  return [
      { name: 'All', value: '1'},
      { name: 'Today', value: '2'},
      { name: 'Week', value: '3'},
  ];
}

export function repaymentType(){
  return [
      { name: 'Normal Repayment', value: '1'},
      { name: 'Outright Settlement', value: '2'},
      { name: 'Return Loan', value: '3'},
      { name: 'Refinance', value: '4'},
  ];
}

export function portfolioType(){
  return [
      { name: 'Last Month', value: '1'},
      { name: 'Last 3 Months', value: '3'},
      { name: 'Last 6 Months', value: '6'},
  ];
}

export function backDatesValues(){
  return [
      { name: '1 month', value: '1'},
      { name: '3 months', value: '3'},
      { name: '6 months', value: '6'},
      { name: '12 months', value: '12'},
      { name: '24 Months', value: '24'},
  ];
}

export function months(){
  return [
      { name: 'January', value: '01'},
      { name: 'Febuary', value: '02'},
      { name: 'March', value: '03'},
      { name: 'April', value: '04'},
      { name: 'May', value: '05'},
      { name: 'June', value: '06'},
      { name: 'July', value: '07'},
      { name: 'August', value: '08'},
      { name: 'September', value: '09'},
      { name: 'October', value: '10'},
      { name: 'November', value: '11'},
      { name: 'December', value: '12'},
  ];
}


export function expenseTypes(){
  return [
      { name: 'AWS', value: '1'},
      { name: 'Light', value: '2'},
      { name: 'Water', value: '3'},
      { name: 'Furniture', value: '4'},
      { name: 'Computer And Accessories', value: '5'},
      { name: 'Internet data', value: '6'},
      { name: 'Airtime', value: '7'},
      { name: 'Repairs', value: '8'},
      { name: 'Waste Disposal', value: '9'},
  ];
}

