import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import {
  addGuarantorFormTitle,
  identificationTypeDropdownValue,
} from "../../Shared/constants";
import { useSnackbar } from "notistack";

export default function AddGuarantor({ open, onClose, loanId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [phone_number, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [identityTypeID, setidentityTypeID] = useState("");
  const [identificationNUmber, setidentificationNUmber] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [occupation, setoccupation] = useState("");
  const [position, setposition] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [identificationType, setIdentificationType] = useState([]);

  const getIdentification = () => {
    Api()
      .get(`/general/dropdowns/${identificationTypeDropdownValue}/`)
      .then((response) => {
        setIdentificationType(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const addGurantor = async () => {
    const data = {
      phone_number: phone_number,
      first_name: firstName,
      last_name: lastName,
      identity_type_id: identityTypeID,
      id_number: identificationNUmber,
      address: homeAddress,
      occupation: occupation,
      position: position,
    };

    setShowLoading(true);

    Api()
      .post(`/general/guarantor/${loanId}/`, data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getIdentification();
  }, []);
  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {addGuarantorFormTitle}
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Phone Number</span>
              <input
                required
                type="tel"
                name="phone_number"
                defaultValue={phone_number}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">First Name</span>
              <input
                required
                type="text"
                name="firstName"
                defaultValue={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Last Name</span>
              <input
                required
                type="text"
                name="lastName"
                defaultValue={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                className="input-style"
              />
            </label>
            {/* <label className="block mt-5">
                <span className="not-required-label">Borrower</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setborrowerID(e.target.value);
                  }}
                >
                  {borrowersList?.map((borrower, index) => {
                    return (
                      <option value={borrower.borrower_id} key={index}>
                        {borrower.first_name + " " + borrower.last_name }
                      </option>
                    );
                  })}
                </select>
              </label> */}
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="not-required-label">ID Type</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setidentityTypeID(e.target.value);
                }}
              >
                {identificationType?.map((idType, index) => {
                  return (
                    <option value={idType.id} key={index}>
                      {idType.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="block mt-5">
              <span className="not-required-label">ID Number</span>
              <input
                type="text"
                name="id_number"
                defaultValue={identificationNUmber}
                onChange={(e) => {
                  setidentificationNUmber(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="not-required-label">Ocupation</span>
              <input
                type="text"
                name="ocupation"
                defaultValue={occupation}
                onChange={(e) => {
                  setoccupation(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="not-required-label">Position</span>
              <input
                type="text"
                name="position"
                defaultValue={position}
                onChange={(e) => {
                  setposition(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="not-required-label">Address</span>
              <input
                type="text"
                name="address"
                defaultValue={homeAddress}
                onChange={(e) => {
                  sethomeAddress(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <AddFormButton loading={showLoading} onClick={addGurantor} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}
