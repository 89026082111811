import React, { useState} from "react";
import OpenFormButton from "../Components/Shared/OpenFormButton";
import {activeValue} from "../Shared/constants"
import BorrowerTable from "../Components/Tables/BorrowerTable";
import AddBorrower from "../Components/Forms/AddBorrower";

export default function Borrower() {

  const [visible, setVisible] = useState(false);

  const closeFormModal = () => setVisible(false);

  return (
    <div>
    <div className="m-5 flex justify-between ">
    <div className='text-3xl'>Active Borrowers</div>
      <OpenFormButton openModal={setVisible} buttonText='Add' />
    </div>

    <div>
    <AddBorrower open={visible} onClose={closeFormModal}  />
    </div>

    <div className="mt-10">
      <BorrowerTable tableText='List of Active Borrowers.' dataToFetchValue={activeValue}   />
    </div>
  </div>
  )
}
