import React, { useEffect, useState } from "react";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import { editUserFormTitle, SystemUserType } from "../../Shared/constants";
import AddFormButton from "../Shared/AddFormButton";
import { useSnackbar } from "notistack";

export default function EditUser({ open, onClose, userToPass }) {
  const { enqueueSnackbar } = useSnackbar();
  const [phone_number, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [staffID, setStaffID] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countries, setCountries] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const getCountries = () => {
    Api()
      .get("/general/all-countries/")
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const editSystemuser = async (e) => {
    e.preventDefault();
    const data = {
      phone_number: phone_number,
      first_name: firstName,
      last_name: lastName,
      role_id: roleId,
      staff_id: staffID,
      country_id: countryId,
    };

    setShowLoading(true);

    Api()
      .put(`/auth/update-user/${userToPass.user_key}/`, data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        setShowLoading(false);
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getCountries();
    setPhoneNumber(userToPass.phone_number);
    setFirstName(userToPass.first_name);
    setLastName(userToPass.last_name);
    setRoleId(userToPass.role_id);
    setStaffID(userToPass.staff_id);
    setCountryId(userToPass.country_id);
  }, []);
  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {editUserFormTitle}
          </Text>
        </Modal.Header>
          <Modal.Body className="small:m-0 m-5">
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">Phone Number</span>
                <input
                  required
                  type="tel"
                  name="phone_number"
                  defaultValue={userToPass.phone_number || phone_number}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="required-label">First Name</span>
                <input
                  required
                  type="text"
                  name="firstName"
                  defaultValue={userToPass.first_name || firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">Last Name</span>
                <input
                  required
                  type="text"
                  name="lastName"
                  defaultValue={userToPass.last_name || lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="required-label">Role </span>
                <select
                  className="input-style"
                  defaultValue={userToPass.role_id || roleId}
                  onChange={(e) => {
                    setRoleId(e.target.value);
                  }}
                >
                  {SystemUserType?.map((userType, index) => {
                    return (
                      <option value={userType.value} key={index}>
                        {userType.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Country</span>
                <select
                  className="input-style"
                  defaultValue={userToPass.country_id || countryId}
                  onChange={(e) => {
                    setCountryId(e.target.value);
                  }}
                >
                  {countries?.map((country, index) => {
                    return (
                      <option value={country.id} key={index}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Staff ID</span>
                <input
                  type="text"
                  name="staffId"
                  defaultValue={userToPass.staff_id || staffID}
                  onChange={(e) => {
                    setStaffID(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button auto flat color="error" onClick={onClose}>
              Close
            </Button>
            <AddFormButton loading={showLoading} onClick={editSystemuser} />
          </Modal.Footer>
      </Modal>
    </div>
  );
}
