import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import { repaymentType } from "../../Services/validations";
import { useSnackbar } from "notistack";

export default function AddRepayment({ open, onClose, loanId, getBorrowerName }) {
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setAmount] = useState("");
  const [repaymentTypes, setrepaymentTypes] = useState("1");
  const [showLoading, setShowLoading] = useState(false);
  const [disableAmount, setdisableAmount] = useState(false);


  const getDiscountedAmount = (value) => {
    const data = {
      repayment_type: parseInt(value)
    }

    Api().post(`/loans/discount-amount/${loanId}/`, data)
    .then((response) => {
      setAmount(response.data.amount)
    })
    .catch((error) => {
    })
  }

  const addRepayment = async (e) => {
    e.preventDefault();
    const data = {
      amount,
      repayment_type_id: parseInt(repaymentTypes),
    };
    setShowLoading(true);
    Api()
      .post(`/loans/repayment/${loanId}/`, data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        setShowLoading(false);
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };



  useEffect(() => {
  
  }, [repaymentTypes, amount]);


  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Add Repayment
          </Text>
        </Modal.Header>
        <p className="font-bold">{getBorrowerName}</p>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Amount</span>
              <input
                required
                type="number"
                name="amount"
                disabled={disableAmount}
                defaultValue={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Repayment Type</span>
              <select
                required
                className="input-style"
                onChange={(e) => {
                  setrepaymentTypes(e.target.value);
                  getDiscountedAmount(e.target.value)
                }}
              >
                {repaymentType()?.map((repay, index) => {
                  return (
                    <option value={repay.value} key={index}>
                      {repay.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <AddFormButton loading={showLoading} onClick={addRepayment} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
