import React from "react";
import { Grid } from "@nextui-org/react";

export default function OpenFormButton({ openModal, buttonText }) {

  const openFormModal = () => openModal(true);

  return (
    <div>
      <Grid>
        <button className="buttonStyle" onClick={() => openFormModal()}>
          <p className="font-medium text-white">{buttonText}</p>
        </button>
      </Grid>
    </div>
  );
}
