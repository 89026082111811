import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Row, Tooltip, Spacer, useModal, Text } from "@nextui-org/react";
import { IconButton } from "../Icons/IconButton";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import DeleteIcon from "../Icons/DeleteIcon";
import DeleteData from "../Shared/DeleteData";
import Paginations from "../Shared/Pagination";
import { adminRole, superAdminRole } from "../../Services/roles.js";
import NoDataAvailable from "../Shared/NoDataAvailable";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import ApproveIcon from "../Icons/ApproveIcon";
import ApprovedIcon from "../Icons/ApprovedIcon";
import ApproveAction from "../Shared/ApproveAction";
import { EditIcon } from "../Icons/EditIcon";
import EditExpenses from "../Forms/EditExpenses";
import SearchInput from "../Shared/SearchInput";
import { currencyFormat } from "../../Services/validations";

export default function ExpenseTable({ type, url }) {
  const { enqueueSnackbar } = useSnackbar();
  const { setVisible } = useModal();
  const [pageNumber, setpageNumber] = useState(1);
  const [expenses, setexpenses] = useState([]);
  const [expenseData, setexpenseData] = useState("");

  const [isReady, setIsReady] = useState(false);
  const [visible, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [dataID, setdataID] = useState("");
  const expenseUrl = "transactions/delete-expense";

  const [visible3, setVisible3] = useState(false);
  const [approveId, setapproveId] = useState("");

  const approveTaskURL = "transactions/approve-expense";
  const promtMessage = "Are you sure you want to Approve Expense?";

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

//   const searchURL = `/loans/search-loans/${1}`;

  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getExpenses(event);
  };

//   const closeModal = () => setVisible(false);

  const openEditModal = () => setVisible1(true);

  const closeEditModal = () => setVisible1(false);

  const openDeleteModal = () => setVisible2(true);

  const closeDeleteModal = () => setVisible2(false);

  const openApproveModal = () => setVisible3(true);

  const closeApproveModal = () => setVisible3(false);

  const getExpenses = async (pageNumberSelected = 1) => {
    Api()
      .post(`/transactions/all-expenses/${type}/${pageNumberSelected}/`)
      .then((response) => {
        setexpenses(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getExpenses();
  }, []);

  return (
    <div>
      <SearchInput
        getSearchData={setexpenses}
        setTotalPages={setTotalPages}
        searchURL={url}
        placeholder="Search by expense type"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon />
        </div>
      ) : expenses?.length === 0 ? (
        <div className="flex justify-center">
          <NoDataAvailable />
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Amount
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Recorded By
                </StyledTableCell>
                {
                  type === 2 ? (
                    <StyledTableCell
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                    align="left"
                  >
                    Deleted By
                  </StyledTableCell>
                  ) : ''
                }
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Date
                </StyledTableCell>
                {type === 2 ? (
                  ""
                ) : (
                  <StyledTableCell
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                    align="left"
                  >
                    Actions
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>



            <TableBody>
              {expenses.map((expense, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    <Text>{expense.expense_type__name}</Text>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   {currencyFormat.format(expense.amount)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {expense.recorded_by__first_name === null
                      ? ""
                      : expense.recorded_by__first_name +
                        " " +
                        expense.recorded_by__last_name}
                  </StyledTableCell>
                  {
                    type === 2 ? (
                      <StyledTableCell align="left">
                      {expense.deleted_by__first_name === null
                        ? "No Name"
                        : expense.deleted_by__first_name +
                          " " +
                          expense.deleted_by__last_name}
                    </StyledTableCell>
                    ) : ''
                  }
                  <StyledTableCell align="left">
                    {expense.recorded_on === null
                      ? "Not set"
                      : moment(`${expense.recorded_on}`).format("lll")}
                  </StyledTableCell>
                  {type === 2 ? (
                    ""
                  ) : (
                    <StyledTableCell align="left">
                      <Row align="flex-start">
                        {expense.is_approved && superAdminRole() ? (
                          ""
                        ) : (
                          <Tooltip content="Edit" color="primary">
                            <IconButton
                              onClick={() => {
                                setdataID(expense.id);
                                setexpenseData(expense);
                                openEditModal();
                              }}
                            >
                              <EditIcon size={20} fill="#007FFF" />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Spacer x={1} />
                        {adminRole() || superAdminRole() ? (
                          expense.is_approved ? (
                            <Tooltip content="Already Approved" color="success">
                              <ApprovedIcon fill="green" />
                            </Tooltip>
                          ) : (
                            <Tooltip content="Approve" color="primary">
                              <IconButton
                                onClick={() => {
                                  setapproveId(expense.id);
                                  openApproveModal();
                                }}
                              >
                                <ApproveIcon fill="#8F00FF" />
                              </IconButton>
                            </Tooltip>
                          )
                        ) : (
                          ""
                        )}

                        <Spacer x={1} />
                        {expense.is_approved ? (
                          ""
                        ) : (
                          <Tooltip content="Delete" color="error">
                            <IconButton
                              onClick={() => {
                                setdataID(expense.id);
                                openDeleteModal();
                              }}
                            >
                              <DeleteIcon fill="#D22B2B" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Row>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {expenses.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady ? "" : "List of Expesnses"}
        </div>
      )}

      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <ApproveAction
        visible={visible3}
        url={approveTaskURL}
        approveId={approveId}
        onClose={closeApproveModal}
        promptMessage={promtMessage}
      />

      <DeleteData
        visible={visible2}
        url={expenseUrl}
        deleteId={dataID}
        onClose={closeDeleteModal}
      />

      <EditExpenses
        open={visible}
        onClose={closeEditModal}
        data={expenseData}
        id={dataID}
      />
    </div>
  );
}
