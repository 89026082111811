import React, { useState } from "react";
import AddFormButton from "../Shared/AddFormButton";
import Api from "../../Services/api";
import { Modal, Text, Button } from "@nextui-org/react";
import { useSnackbar } from "notistack";
import { currencyFormat } from "../../Services/validations";

export default function EditOutstandingLoan({ open, onClose, data, Id }) {
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setamount] = useState();

  const [showLoading, setShowLoading] = useState(false);

  const editLoanAmount = async (e) => {
    e.preventDefault();
    const data = {
        amount_outstanding: amount
    };

      setShowLoading(true);
      Api()
        .put(`/loans/edit-loan/${Id}/`, data)
        .then((response) => {
          enqueueSnackbar(response.data.detail, {
            variant: "success",
            autoHideDuration: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            autoHideDuration: 1500,
          });
          setShowLoading(false);
        });

  };
  return (
    <div>
      <>
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={open}
          onClose={onClose}
          scroll
          width="600px"
          preventClose
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Edit Outstanding Loan
            </Text>
          </Modal.Header>
          <Modal.Body className="small:m-0 m-5">
            <div className="grid grid-cols-1 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">Outstanding Amount</span>
                <input
                  required
                  type="number"
                  name="amount"
                  defaultValue={data.amount_outstanding || amount}
                  onChange={(e) => {
                    setamount(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button auto flat color="error" onClick={onClose}>
              Close
            </Button>
            <AddFormButton loading={showLoading} onClick={editLoanAmount} />
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}
