import React from "react";
import OverdueTabs from "./OverdueLoansTab";

export default function OverdueLoans() {
  return (
    <div>
      <div className="m-5 flex justify-start ">
        <div className="text-3xl">Overdue Loans</div>
      </div>
      <div className="mt-10">
        <OverdueTabs />
      </div>
    </div>
  );
}
