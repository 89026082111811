import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import { months } from "../../Services/validations";
import { Text } from "@nextui-org/react";
import { useSnackbar } from "notistack";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import { currencyFormat } from "../../Services/validations";

export default function TransactionStatsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const [isReady, setIsReady] = useState(false);
  const [allTrans, setallTrans] = useState([]);
  const [weekTrans, setweekTrans] = useState([]);
  const [monhtlyTrans, setmonhtlyTrans] = useState([]);
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");

  const years = ["2021", "2022", "2023", "2024", "2025", "2026"];

  const getAllTransactionStats = async () => {
    Api()
      .post(`/reports/transaction-statistics/${1}/`)
      .then((response) => {
        setallTrans(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getWeeklyTransactionStats = async () => {
    Api()
      .post(`/reports/transaction-statistics/${2}/`)
      .then((response) => {
        setweekTrans(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const fixedDate = new Date();

  const getMontlyTransactionStats = async () => {
    const data = {
      month: month === "" ? fixedDate.getMonth() + 1 : month.split("-")[1],
      year: month === "" ? fixedDate.getFullYear() : month.split("-")[0],
    };
    Api()
      .post(`/reports/transaction-statistics/${3}/`, data)
      .then((response) => {
        setmonhtlyTrans(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getMontlyTransactionStats();
  }, [month, year]);

  useEffect(() => {
    getAllTransactionStats();
    getWeeklyTransactionStats();
  }, []);

  return (
    <div>
      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon />
        </div>
      ) : (
        <div className="grid grid-cols-2 small:grid-cols-1 ipad-pro:grid-cols-1 ipad:grid-cols-1 next-hub:grid-cols-2 gap-4 p-4">
          <div className="rounded-md shadow-xl border-2 border-cyan-800 p-5 bg-slate-50">
            <div className="flex justify-between">
              <div className="mb-4 underline">
                {" "}
                <Text size={20}>Monthly</Text>
              </div>
              {/* <div>
                <button
                  type=""
                  className="saveButtonStyle text-white"
                  onClick={getMontlyTransactionStats}
                >
                  Submit
                </button>
              </div> */}
            </div>
            <div>
              <div className="grid grid-cols-2 small:grid-cols-1 gap-4 mb-3">
                <div>
                  <input
                    type="date"
                    className="rounded-md w-full h-10 border-2"
                    onChange={(e) => {
                      setmonth(e.target.value);
                    }}
                  />
                  {/* <select
                    className="input-style "
                    onChange={(e) => {
                      setmonth(e.target.value);
                    }}
                  >
                    {months().map((month, index) => {
                      return (
                        <option value={month.value} key={index}>
                          {month.name}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
                <div>
                  {/* <select
                    className="input-style "
                    onChange={(e) => {
                      setyear(e.target.value);
                    }}
                  >
                    {years.map((year, index) => {
                      return (
                        <option value={year} key={index}>
                          {year}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
              </div>

              <div className="flex justify-between mb-3">
                <Text size={20}>Total Bayport Amount</Text>
                <Text size={20} color="#007FFF">
                 
                  {currencyFormat.format(monhtlyTrans.total_bayport_amount)}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Bayport Count</Text>
                <Text size={20} color="#007FFF">
                  {monhtlyTrans.total_bayport_count}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Other Banks Amount</Text>
                <Text size={20} color="#007FFF">
                
                  {currencyFormat.format(
                    monhtlyTrans.total_other_banks_amount
                  )}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Other Banks Count</Text>
                <Text size={20} color="#007FFF">
                  {monhtlyTrans.total_other_banks_count}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Principal Amount</Text>
                <Text size={20} color="#007FFF">
                 
                  {currencyFormat.format(
                    monhtlyTrans.total_principal_amount
                  )}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Principal Count</Text>
                <Text size={20} color="#007FFF">
                  {monhtlyTrans.total_principal_count}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Repayment Amount</Text>
                <Text size={20} color="#007FFF">
                  
                  {currencyFormat.format(
                    monhtlyTrans.total_repayment_amount
                  )}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Repayment Count</Text>
                <Text size={20} color="#007FFF">
                  {monhtlyTrans.total_repayment_count}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Transactions Amount</Text>
                <Text size={20} color="#007FFF">
                 
                  {currencyFormat.format(
                    monhtlyTrans.total_transaction_amount
                  )}
                </Text>
              </div>
              <div className="flex justify-between mb-3">
                <Text size={20}>Total Transaction Count</Text>
                <Text size={20} color="#007FFF">
                  {monhtlyTrans.total_transaction_count}
                </Text>
              </div>
            </div>
          </div>
          <div className="rounded-md shadow-xl border-2  border-cyan-800 p-5 bg-slate-50 ">
            <div className="mb-5 underline">
              {" "}
              <Text size={20}>All</Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Bayport Amount</Text>
              <Text size={20} color="#007FFF">
              
                {currencyFormat.format(allTrans.total_bayport_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Bayport Count</Text>
              <Text size={20} color="#007FFF">
                {allTrans.total_bayport_count}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Other Banks Amount</Text>
              <Text size={20} color="#007FFF">
              
                {currencyFormat.format(allTrans.total_other_banks_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Oter Banks Count</Text>
              <Text size={20} color="#007FFF">
                {allTrans.total_other_banks_count}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Principal Amount</Text>
              <Text size={20} color="#007FFF">
              
                {currencyFormat.format(allTrans.total_principal_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Principal Count</Text>
              <Text size={20} color="#007FFF">
                {allTrans.total_principal_count}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Repayment Amount</Text>
              <Text size={20} color="#007FFF">
              
                {currencyFormat.format(allTrans.total_repayment_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Repayment Count</Text>
              <Text size={20} color="#007FFF">
                {allTrans.total_repayment_count}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Transactions Amount</Text>
              <Text size={20} color="#007FFF">
            
                {currencyFormat.format(allTrans.total_transaction_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Transaction Count</Text>
              <Text size={20} color="#007FFF">
                {allTrans.total_transaction_count}
              </Text>
            </div>
          </div>

          <div className=" rounded-md shadow-xl border-2 border-cyan-800 p-5 bg-slate-50 ">
            <div className="flex justify-start mb-5">
              <div>
                <div className="underline">
                  {" "}
                  <Text size={20}>Weekly</Text>
                </div>
              </div>
            </div>

            <div className="flex justify-between mb-3">
              <Text size={20}>Total Bayport Amount</Text>
              <Text size={20} color="#007FFF">
               
                {currencyFormat.format(weekTrans.total_bayport_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Bayport Count</Text>
              <Text size={20} color="#007FFF">
                {weekTrans.total_bayport_count}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Other Banks Amount</Text>
              <Text size={20} color="#007FFF">
               
                {currencyFormat.format(weekTrans.total_other_banks_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Other Banks Count</Text>
              <Text size={20} color="#007FFF">
                {weekTrans.total_other_banks_count}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Principal Amount</Text>
              <Text size={20} color="#007FFF">
              
                {currencyFormat.format(weekTrans.total_principal_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Principal Count</Text>
              <Text size={20} color="#007FFF">
                {weekTrans.total_principal_count}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Repayment Amount</Text>
              <Text size={20} color="#007FFF">
              
                {currencyFormat.format(weekTrans.total_repayment_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Repayment Count</Text>
              <Text size={20} color="#007FFF">
                {weekTrans.total_repayment_count}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Transactions Amount</Text>
              <Text size={20} color="#007FFF">
            
                {currencyFormat.format(weekTrans.total_transaction_amount)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>Total Transaction Count</Text>
              <Text size={20} color="#007FFF">
                {weekTrans.total_transaction_count}
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
