import React, { useState } from 'react'
import LoansTable from '../Components/Tables/LoansTable'
import OpenFormButton from "../Components/Shared/OpenFormButton";
import AddLoans from '../Components/Forms/AddLoans';

export default function Loans() {

  const [visible, setVisible] = useState(false);

  const closeFormModal = () => setVisible(false);
   
  return (
    <div>
       <div className="m-5 flex justify-between ">
       <div className="text-3xl">Loans</div>
        <OpenFormButton openModal={setVisible} buttonText='Add' />
      </div>

      <div>
      <AddLoans open={visible} onClose={closeFormModal} />
      </div>

      <div className="mt-10">
        <LoansTable   />
      </div>
    </div>
  )
}
