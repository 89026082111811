import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import moment from "moment";
import { Row, Tooltip, Spacer, useModal } from "@nextui-org/react";
import { IconButton } from "../Icons/IconButton";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import EyeIcon from "../Icons/EyeIcon";
import DeleteData from "../Shared/DeleteData";
import DeleteIcon from "../Icons/DeleteIcon";
import SingleTransactions from "../Views/SingleTransactions";
import Paginations from "../Shared/Pagination";
import { adminRole, superAdminRole } from "../../Services/roles.js";
import { adminsDropdownValue } from "../../Shared/constants";
import TicketIcon from "../Icons/TicketIcon";
import CreateTicket from "../Shared/CreateTicket";
import SearchInput from "../Shared/SearchInput";
import { useSnackbar } from "notistack";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import NoDataAvailable from "../Shared/NoDataAvailable";
import ApproveIcon from "../Icons/ApproveIcon";
import ApprovedIcon from "../Icons/ApprovedIcon";
import ApproveAction from "../Shared/ApproveAction";
import { currencyFormat } from "../../Services/validations";
import { EditIcon } from "../Icons/EditIcon";
import EditTransactions from "../Forms/EditTransactions";

export default function TransactionsTable({ type, url }) {
  const { setVisible, bindings } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [transactions, setTransactions] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [dataID, setdataID] = useState("");
  const [visible, setVisible1] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [getTransact, setgetTransact] = useState("");
  const [totalPages, setTotalPages] = useState([]);

  const [visible2, setVisible2] = useState(false);
  const [approveId, setapproveId] = useState("");

  const approveTaskURL = "transactions/approve-transaction";
  const promtMessage = "Are you sure you want to Approve Transaction?";

  const openApproveModal = () => setVisible2(true);

  const closeApproveModal = () => setVisible2(false);

  const [getSinlgeView, setgetSinlgeView] = useState(false);
  const [openeditView, setopeneditView] = useState(false);

  const openEditView = () => setopeneditView(true);

  const closeEditView = () => setopeneditView(false);

  const openView = () => setgetSinlgeView(true);

  const closeView = () => setgetSinlgeView(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const searchURL = `/transactions/search-transactions-earnings/${1}`;

  const [getAdmins, setgetAdmins] = useState([]);
  const transactionUrl = "transactions/delete-transaction";

  const closeModal = () => setVisible(false);

  const openDeleteModal = () => setVisible1(true);

  const closeDeleteModal = () => setVisible1(false);

  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getTransactions(event);
  };

  const getTransactions = async (pageNumber = 1) => {
    Api()
      .get(`/transactions/transactions/${type}/${pageNumber}/`)
      .then((response) => {
        setTransactions(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getSystemAdmins = async () => {
    Api()
      .get(`/general/dropdowns/${adminsDropdownValue}/`)
      .then((response) => {
        setgetAdmins(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getTransactions();
    getSystemAdmins();
  }, []);
  return (
    <div>
      <SearchInput
        getSearchData={setTransactions}
        searchURL={url}
        setTotalPages={setTotalPages}
        placeholder="Search by agency, name or ID"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon color="#007FFF" size={80} />
        </div>
      ) : transactions?.length <= 0 ? (
        <p className="flex justify-center">
          <NoDataAvailable />
        </p>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Amount
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Agency
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Transaction
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Date
                </StyledTableCell>
                {type === 2 ? (
                  <StyledTableCell
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                    align="left"
                  >
                    Deleted By
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                    align="left"
                  >
                    Actions
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transact, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {transact.customer_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {currencyFormat.format(transact.amount)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {transact.agency__name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {transact.transaction_type__name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(`${transact.paid_on}`).format("lll")}
                  </StyledTableCell>
                  {type === 2 ? (
                    <StyledTableCell align="left">
                      {transact.deleted_by__first_name === null
                        ? "No name"
                        : transact.deleted_by__first_name +
                          " " +
                          transact.deleted_by__last_name}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="left">
                      <Row align="flex-start">
                      {
                        transact.is_approved ? (
                          ""
                        ) : (
                          <Tooltip content="Edit" color="primary">
                          <IconButton
                            onClick={() => {
                              setgetTransact(transact);
                              openEditView();
                            }}
                          >
                            <EditIcon size={20} fill="#007FFF" />
                          </IconButton>
                        </Tooltip>
                        )
                      }
                        <Spacer x={1} />
                        <Tooltip content="View" color="invert">
                          <IconButton
                            onClick={() => {
                              setgetTransact(transact);
                              openView();
                            }}
                          >
                            <EyeIcon fill="black" />
                          </IconButton>
                        </Tooltip>
                        <Spacer x={1} />
                        {adminRole() || superAdminRole() ? (
                          transact.is_approved ? (
                            <Tooltip content="Already Approved" color="success">
                              <ApprovedIcon fill="green" />
                            </Tooltip>
                          ) : (
                            <Tooltip content="Approve" color="primary">
                              <IconButton
                                onClick={() => {
                                  setapproveId(transact.id);
                                  openApproveModal();
                                }}
                              >
                                <ApproveIcon fill="#8F00FF" />
                              </IconButton>
                            </Tooltip>
                          )
                        ) : (
                          ""
                        )}

                        <Spacer x={1} />
                        {transact.is_approved ? (
                          ""
                        ) : (
                          <Tooltip content="Task" color="warning">
                            <IconButton
                              onClick={() => {
                                setdataID(transact.id);
                                setVisible(true);
                              }}
                            >
                              <TicketIcon fill="#FFC300" />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Spacer x={1} />
                        {transact.is_approved ? (
                          ""
                        ) : (
                          <Tooltip content="Delete" color="error">
                            <IconButton
                              onClick={() => {
                                setdataID(transact.id);
                                openDeleteModal();
                              }}
                            >
                              <DeleteIcon fill="#D22B2B" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Row>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {transactions.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady
            ? ""
            : `${
                type === 2
                  ? "List of Deleted Transactions"
                  : type === 1
                  ? "List of Active Transactions"
                  : "List of Staff Transactions"
              }`}
        </div>
      )}

      <CreateTicket
        getAdmins={getAdmins}
        bindings={bindings}
        closeModal={closeModal}
      />

      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <DeleteData
        visible={visible}
        url={transactionUrl}
        deleteId={dataID}
        onClose={closeDeleteModal}
      />

      <ApproveAction
        visible={visible2}
        url={approveTaskURL}
        approveId={approveId}
        onClose={closeApproveModal}
        promptMessage={promtMessage}
      />

      <EditTransactions
        open={openeditView}
        onClose={closeEditView}
        dataToPass={getTransact}
      />

      <SingleTransactions
        open={getSinlgeView}
        onClose={closeView}
        getTransact={getTransact}
      />
    </div>
  );
}
