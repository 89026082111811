import React, { useEffect } from "react";
import { Modal, Text } from "@nextui-org/react";
import moment from "moment";
import { currencyFormat } from "../../Services/validations";

export default function SingleTransactions({ open, onClose, getTransact }) {
  useEffect(() => {}, []);
  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <div className="flex justify-center">
          <img
            src="./images/Logo.jpg"
            alt="Default Image"
            width={100}
            height={100}
          />
        </div>
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Detailed Transaction
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Name: </span>
              {getTransact.customer_name}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Amount: </span>
             {currencyFormat.format(getTransact.amount)}
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Phone No: </span>
              {getTransact.customer_phone_number}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Agency: </span>
              {getTransact.agency__name}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Collected By: </span>
              {getTransact.collected_by__first_name +
                " " +
                getTransact.collected_by__last_name}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Approved: </span>
              {getTransact.is_approved ? "YES" : "NO"}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Bank Name: </span>
              {getTransact.bank_name}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Account No: </span>
              {getTransact.bank_account_number}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Bank Branch: </span>
              {getTransact.bank_account_branch}
            </label>
            <label className="block mt-5">
              <span className="font-bold">ID Type: </span>
              {getTransact.identity_type__name}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">ID No: </span>
              {getTransact.identification_number}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Transaction Type: </span>
              {getTransact.transaction_type__name}
            </label>
          </div>

          <div className="grid grid-cols-1 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Paid Date: </span>
              {moment(`${getTransact.paid_on}`).format("lll")}
            </label>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
