import React, { useState, useEffect} from "react";
import Api from "../../Services/api";
import { Modal, Text, Button, Spacer } from "@nextui-org/react";
import { useSnackbar } from "notistack";
import Loading from "../../Components/Icons/Loading"
import Cookies from "js-cookie";

export default function VerifyOTP({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [borrowerId, setBorrowerId] = useState("");
  const [countDown, setCountDown] = useState(300);
  const [disabled, setDisabled] = useState(true);
  const [loanDetails, setloanDetails] = useState("");

  const [showLoading, setShowLoading] = useState(false);


  const resendOTP = async () => {
    const data = {
        phone_number: Cookies.get('borrowerPhone')
      }
      setDisabled(true)
      Api()
        .post('/loans/send-otp/', data)
        .then((response) => {
        countDownTime(5)
          enqueueSnackbar(response.data.detail, {
            variant: "success",
            autoHideDuration: 1000,
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            autoHideDuration: 1500,
          });
        });
  }

//   console.log('loanDetails', loanDetails)

  const verifyOtpNumber = async (e) => {
   
    const data = {
      otp: otp,
      phone_number: Cookies.get('borrowerPhone')
    };

    if( otp === "") {
      enqueueSnackbar('Fill the OTP field', {
        variant: "info",
        autoHideDuration: 1500,
      });
    }else {
      setShowLoading(true);
      Api()
        .put("/loans/verify-otp/", data)
        .then( async(response) => {
          enqueueSnackbar(response.data.detail, {
            variant: "success",
            autoHideDuration: 1000,
          });
          console.log('borrower', borrowerId)
          console.log('loanDetails', loanDetails)
          await addLoans()
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            autoHideDuration: 1500,
          });
          setShowLoading(false);
        });
    }

  };

  const addLoans = async () => {
    console.log('passing through the loan function')
      setShowLoading(true);
      Api()
        .post(`/loans/loans/${borrowerId[0].borrower_id}/`, loanDetails)
        .then((response) => {
          enqueueSnackbar(response.data.detail, {
            variant: "success",
            autoHideDuration: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          localStorage.removeItem('loanData')
          Cookies.remove('borrowerPhone')
          localStorage.removeItem('borrowerId')
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            autoHideDuration: 1500,
          });
          setShowLoading(false);
        });

  };

  function countDownTime(minutes) {
    var seconds = 60;
    var mins = minutes;

    function tick() {
      //This script expects an element with an ID = "counter". You can change that to what ever you want.

      var current_minutes = mins - 1;
      seconds--;
      const cnt =
        current_minutes.toString() +
        ":" +
        (seconds < 10 ? "0" : "") +
        String(seconds);
      if (seconds > 0) {
        setTimeout(tick, 1000);
      } else if (mins > 1) {
        countDownTime(mins - 1);
      } else {
        setDisabled(false);
      }
      setCountDown(cnt);
    }
    tick();
  }

  useEffect(() => {
    // setBorrowerId(JSON.parse(localStorage?.getItem('borrowerId')))
    // setloanDetails(Cookies?.get('loanData'))
  }, []);
  
  
  useEffect(() => {
    console.log('borrower',  JSON.parse(localStorage?.getItem('borrowerId')))
    setBorrowerId( JSON.parse(localStorage?.getItem('borrowerId')))
    console.log('loan', JSON.parse(localStorage?.getItem('loanData')))
    setloanDetails(JSON.parse(localStorage?.getItem('loanData')))
    if (countDown === 300 && open === true) countDownTime(5);
  }, [open]);

  return (
    <div>
      <>
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={open}
          onClose={onClose}
          scroll
          width="600px"
          preventClose
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Verify OTP 
            </Text>
          </Modal.Header>
          <Modal.Body className="small:m-0 m-5">
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">OTP</span>
                <input
                  required
                  type="number"
                  name="OTP"
                  defaultValue={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="required-label">Phone Number</span>
                <input
                  required
                  type="number"
                  disabled
                  name="phone"
                  defaultValue={Cookies.get('borrowerPhone')}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
          </Modal.Body>

          <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
              Close
            </Button>
            <Spacer />
            <Button auto flat color="secondary" onClick={resendOTP} disabled={disabled}>
              {disabled ? <h4 className="mt-2">{countDown}</h4> : `Resend OTP`} 
            </Button>
            <Button auto flat color="primary" onClick={verifyOtpNumber}>
              {showLoading ? <Loading /> : `Verify`} 
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}
