import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import {
  portfolioType,
  backDatesValues,
  months,
} from "../../Services/validations";
import { Text } from "@nextui-org/react";
import { useSnackbar } from "notistack";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import { currencyFormat } from "../../Services/validations";

export default function PortfolioTable() {
  const fixedDate = new Date();
  const { enqueueSnackbar } = useSnackbar();
  const [isReady, setIsReady] = useState(false);
  const [stats, setStats] = useState([]);
  const [month, setmonth] = useState(1);
  const [portfolio, setPortfolio] = useState([]);
  const [backDate, setBackDate] = useState("1");
  const [profitMonth, setProfitMonth] = useState("");
  const [profityear, setprofityear] = useState();
  const [getprofitAndLoss, setgetprofitAndLoss] = useState([]);

  const years = ["2021", "2022", "2023", "2024", "2025", "2026"];


  const getStatistics = async () => {
    Api()
      .get("/reports/statistics/")
      .then((response) => {
        setStats(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getPortfolio = async (value) => {
    Api()
      .post(`/reports/portfolio-at-risk/${value ? value : 1}/`)
      .then((response) => {
        setPortfolio(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getProfitLoss = async () => {
    const data = {
        month: profitMonth === "" ? (fixedDate.getMonth() + 1).toString() : profitMonth.split('-')[1] ,
        year: profitMonth === "" ? fixedDate.getFullYear().toString() :  profitMonth.split('-')[0] ,
        back_date: parseInt(backDate)
    }
    Api()
      .post('/reports/monthly-profits/', data)
      .then((response) => {
        setgetprofitAndLoss(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };


  useEffect(() => {
    getProfitLoss()
  }, [profitMonth]);

  useEffect(() => {
    getStatistics();
    getPortfolio();
  }, []);

  return (
    <div>
      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon />
        </div>
      ) : (
        <div className="grid grid-cols-2 small:grid-cols-1 ipad-pro:grid-cols-1 ipad:grid-cols-1 next-hub:grid-cols-2 gap-4 p-4">
         
         <div className="rounded-md shadow-xl border-2 border-cyan-800 p-5 bg-slate-50">
            <div className="flex justify-between">
              <div className="mb-4 underline">
                {" "}
                <Text  size={20}>
                  Profit And Loss
                </Text>
              </div>
              <div>
                <button type="" className="saveButtonStyle text-white" onClick={getProfitLoss}>Submit</button>
              </div>
            </div>
            <div>
            <div className="grid grid-cols-3 small:grid-cols-1 gap-4 mb-3">
              <div>
                <select
                  className="input-style "
                  onChange={(e) => {
                    setBackDate(e.target.value);
                  }}
                >
                  {backDatesValues().map((month, index) => {
                    return (
                      <option value={month.value} key={index}>
                        {month.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
              <input
                    type="date"
                    className="rounded-md w-full h-10 border-2 mt-1"
                    onChange={(e) => {
                      setProfitMonth(e.target.value);
                    }}
                  />
                {/* <select
                  className="input-style "
                  onChange={(e) => {
                    setProfitMonth(e.target.value);
                  }}
                >
                  {months().map((month, index) => {
                    return (
                      <option value={month.value} key={index}>
                        {month.name}
                      </option>
                    );
                  })}
                </select> */}
              </div>
              <div>
                {/* <select
                  className="input-style "
                  onChange={(e) => {
                    setprofityear(e.target.value);
                  }}
                >
                  {years.map((year, index) => {
                    return (
                      <option value={year} key={index}>
                        {year}
                      </option>
                    );
                  })}
                </select> */}
              </div>
            </div>

            <div className="flex justify-between mb-3">
              <Text  size={20}>
                Monthly Interest Expected
              </Text>
              <Text  size={20} color="#007FFF">
              {currencyFormat.format(getprofitAndLoss.monthly_interest_expected)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
              Monthly Interest Received
              </Text>
              <Text  size={20} color="#007FFF">
              {currencyFormat.format(getprofitAndLoss.monthly_interest_received)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
                 Monthly Principal Expected
              </Text>
              <Text size={20} color="#007FFF">
               {currencyFormat.format(getprofitAndLoss.monthly_principal_expected)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
              Monthly Principal Received
              </Text>
              <Text  size={20} color="#007FFF">
               {currencyFormat.format(getprofitAndLoss.monthly_principal_received)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
                Total Expected Payable
              </Text>
              <Text size={20} color="#007FFF">
               {currencyFormat.format(getprofitAndLoss.total_expected_payable)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
              Total Expected Disbursed
              </Text>
              <Text  size={20} color="#007FFF">
               {currencyFormat.format(getprofitAndLoss.total_principal_disbured)}
              </Text>
            </div>
            </div>
          </div>
          <div className="rounded-md shadow-xl border-2  border-cyan-800 p-5 bg-slate-50 ">
            <div className="mb-5 underline">
              {" "}
              <Text  size={20}>
                Statistics.
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>
                Borrowers
              </Text>
              <Text size={20} color="#007FFF">
                {stats.total_borrowers}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>
                Loans
              </Text>
              <Text size={20} color="#007FFF">
                {stats.total_loans}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text size={20}>
                Active Loans
              </Text>
              <Text size={20} color="#007FFF">
                {stats.active_loans}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
                Principal
              </Text>
              <Text  size={20} color="#007FFF">
               {currencyFormat.format(stats.total_gross_principal)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
                Cash Inflows
              </Text>
              <Text  size={20} color="#007FFF">
               {currencyFormat.format(stats.total_cash_ins)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
                Cash Outflows
              </Text>
              <Text  size={20} color="#007FFF">
               {currencyFormat.format(stats.total_cash_outs)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
                Disbursed
              </Text>
              <Text  size={20} color="#007FFF">
               {currencyFormat.format(stats.total_disbursed)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
                Repayments
              </Text>
              <Text  size={20} color="#007FFF">
               {currencyFormat.format(stats.total_repayments)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text  size={20}>
                Oustanding Payable
              </Text>
              <Text  size={20} color="#007FFF">
               {currencyFormat.format(stats.total_outstanding_payable)}
              </Text>
            </div>
          </div>

         


          <div className="h-60 rounded-md shadow-xl border-2 border-cyan-800 p-5 bg-slate-50 ">
            <div className="flex justify-between mb-5">
              <div>
                <div className="underline">
                  {" "}
                  <Text  size={20}>
                    Portfolio At Risk
                  </Text>
                </div>
              </div>

              <div>
                <select
                  className="input-style "
                  onChange={(e) => {
                    setmonth(e.target.value);
                    getPortfolio(e.target.value);
                  }}
                >
                  {portfolioType().map((month, index) => {
                    return (
                      <option value={month.value} key={index}>
                        {month.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="">
              <div className="flex justify-between">
                <Text size={18} >
                  Recovered:{" "}
                </Text>{" "}
                <div className=" ml-2 text-lg text-buttonColor mt-1">
                  {portfolio.percentage_recovered}&#37;
                </div>
              </div>
              <div className="flex justify-between">
                <Text size={18} >
                  Disbursed:{" "}
                </Text>{" "}
                <div className=" ml-2 text-lg text-buttonColor mt-1">
                  {" "}
                 {currencyFormat.format(portfolio.total_disbursed)}
                </div>
              </div>
              <div className="flex justify-between">
                <Text size={18} >
                  Retrieved:{" "}
                </Text>{" "}
                <div className=" ml-2 text-lg text-buttonColor mt-1">
                  {" "}
                 {currencyFormat.format(portfolio.total_retrieved)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


    </div>
  );
}
