import React from "react";
import { Text } from "@nextui-org/react";
import { noDataAvailableText } from "../../Shared/constants";

export default function NoDataAvailable() {
  return (
    <div className="mt-32 ">
      <p className="text-3xl ">{noDataAvailableText}</p>
    </div>
  );
}
