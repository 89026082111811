import React, { useState } from "react";
import OpenFormButton from "./OpenFormButton";
import { Text } from "@nextui-org/react";
import AddNextOfKin from "../Forms/AddNextOfKin";

export default function OpenNextOfKinForm({ borrowerID }) {
  const [openNextOfKin, setopenNextOfKin] = useState(false);

  const closeNextOfKinForm = () => setopenNextOfKin(false);
  return (
    <div>
      <div className="flex justify-end ">
        <OpenFormButton openModal={setopenNextOfKin} buttonText="Add" />
      </div>

      <div className="flex justify-center">
        <Text size={20}>No Next of Kin added for this borrower.</Text>
      </div>

      <AddNextOfKin
        open={openNextOfKin}
        onClose={closeNextOfKinForm}
        borrowerIDs={borrowerID}
      />
    </div>
  );
}
