import React, { useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import Api from "../../Services/api";
import moment from "moment";
import Cookies from "js-cookie";
import { Row, Tooltip, Spacer, Modal, Text, Button } from "@nextui-org/react";
import { EditIcon } from "../Icons/EditIcon";
import { IconButton } from "../Icons/IconButton";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import EyeIcon from "../Icons/EyeIcon";
import DeleteIcon from "../Icons/DeleteIcon";
import EditBorrower from "../Forms/EditBorrower";
import DeleteData from "../Shared/DeleteData";
import Paginations from "../Shared/Pagination";
import { adminRole, superAdminRole } from "../../Services/roles";
import SearchInput from "../Shared/SearchInput";
import NoDataAvailable from "../Shared/NoDataAvailable";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import { useSnackbar } from "notistack";
import ApproveIcon from "../Icons/ApproveIcon";
import Loading from "../../Components/Icons/Loading";
import CopyIcons from "../Icons/CopyIcons";

export default function BorrowerTable({ tableText, dataToFetchValue }) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setpageNumber] = useState(1);
  const [borrowers, setBorrowers] = useState([]);
  const [userToPass, setUserToPass] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [dataID, setdataID] = useState("");
  const [visible, setVisible] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [getUserKey, setgetUserKey] = useState("");
  const [loading, setloading] = useState(false);
  const borrowerUrl = "general/delete-borrower";

  const [visible1, setVisible1] = useState(false);

  const [visible3, setVisible3] = useState(false);

  const openApproveModal = () => setVisible3(true);

  const closeApproveModal = () => setVisible3(false);

  const promptMessage = "Are you sure you want to Activate Borrower again?";

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const searchURL = `/general/search-borrowers/${dataToFetchValue}`;

  const copyPhoneNumber = (value) => {
    console.log("value", value);
    copy(value);
    enqueueSnackbar("Number copied successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };

  const routeToBorrowerDetails = (value) => {
    navigate("/borrower-details", { state: borrowers });
    Cookies.set("borrowerID", value);
  };

  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getBorrowers(event);
  };

  const openFormModal = () => setVisible1(true);

  const closeFormModal = () => setVisible1(false);

  const openDeleteModal = () => setVisible(true);

  const closeDeleteModal = () => setVisible(false);

  const getBorrowers = async (pageNumberSelected = 1) => {
    Api()
      .post(`/general/all-borrowers/${dataToFetchValue}/${pageNumberSelected}/`)
      .then((response) => {
        setBorrowers(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const activateBorrower = async () => {
    setloading(true);
    Api()
      .post(`/general/reactivate-borrower/${getUserKey}/`)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getBorrowers();
  }, []);
  return (
    <div>
      <SearchInput
        getSearchData={setBorrowers}
        searchURL={searchURL}
        setTotalPages={setTotalPages}
        placeholder="Search by name"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon />
        </div>
      ) : borrowers?.length === 0 ? (
        <div className="flex justify-center">
          <NoDataAvailable />
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Phone Number
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Bank
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  ID Type
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  ID Number
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Registration Date
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {borrowers.map((borrower, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {borrower.first_name + " " + borrower.last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className="flex justify-between">
                      {borrower.primary_phone_number}
                      <IconButton
                        onClick={() => {
                          copyPhoneNumber(borrower.primary_phone_number);
                        }}
                      >
                        <CopyIcons fill="#8F00FF" />
                      </IconButton>
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {borrower.bank_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {borrower.identity_type__name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {borrower.identification_number}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(`${borrower.registration_date}`).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Row align="flex-start">
                      <Tooltip content="View" color="invert">
                        <IconButton
                          onClick={() => {
                            setdataID(borrower.borrower_id);
                            routeToBorrowerDetails(borrower.borrower_id);
                          }}
                        >
                          <EyeIcon fill="black" />
                        </IconButton>
                      </Tooltip>
                      <Spacer x={1} />
                      {adminRole() || superAdminRole() ? (
                        <Tooltip content="Edit" color="primary">
                          <IconButton
                            onClick={() => {
                              setUserToPass(borrower);
                              openFormModal();
                            }}
                          >
                            <EditIcon size={20} fill="#007FFF" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <Spacer x={1} />
                      {adminRole() || superAdminRole() ? (
                        borrower.is_deleted ? (
                          <Tooltip content="Reactivate" color="primary">
                            <IconButton
                              onClick={() => {
                                setgetUserKey(borrower.borrower_id);
                                openApproveModal();
                              }}
                            >
                              <ApproveIcon fill="#8F00FF" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip content="Delete" color="error">
                            <IconButton
                              onClick={() => {
                                openDeleteModal(borrower.borrower_id);
                                setdataID(borrower.borrower_id);
                              }}
                            >
                              <DeleteIcon fill="#D22B2B" />
                            </IconButton>
                          </Tooltip>
                        )
                      ) : (
                        ""
                      )}
                    </Row>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {borrowers.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady ? "" : tableText}
        </div>
      )}

      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <EditBorrower
        open={visible1}
        onClose={closeFormModal}
        userToPass={userToPass}
      />

      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible3}
        onClose={closeApproveModal}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {promptMessage}
          </Text>
        </Modal.Header>
        <Modal.Footer>
          <Button auto flat color="error" onClick={closeApproveModal}>
            Cancel
          </Button>
          <Button auto onClick={activateBorrower}>
            {loading ? <Loading /> : "Yes"}
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteData
        visible={visible}
        onClose={closeDeleteModal}
        deleteId={dataID}
        url={borrowerUrl}
      />
    </div>
  );
}
