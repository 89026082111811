import React, { useState } from "react";
import SystemUserTable from "../Components/Tables/SystemUserTable";
import {
  activateUser,
  inActiveValue,
  inActiveUserText,
} from "../Shared/constants";

export default function InactiveUsers() {
  const [userToPass, setUserToPass] = useState({});

  return (
    <div>
      <div className="m-5 flex justify-start ">
        <div className="text-3xl">Inactive Users</div>
      </div>

      <div className="mt-10">
        <SystemUserTable
          userToPass={userToPass}
          dataToFetchValue={inActiveValue}
          activationValue={activateUser}
          tableText={inActiveUserText}
          toolTipText="Activate"
        />
      </div>
    </div>
  );
}
