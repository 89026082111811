import React, { useState, useEffect } from "react";
import Api from "../Services/api";
import { useModal } from "@nextui-org/react";
import TicketsTable from "../Components/Tables/Ticketstable";
import CreateTicket from "../Components/Shared/CreateTicket";
import { adminsDropdownValue } from "../Shared/constants";
import TicketsTab from "./TicketsTab";


export default function Tickets() {
  const { setVisible, bindings } = useModal();
  const [getAdmins, setgetAdmins] = useState([]);

  const closeModal = () => setVisible(false);

  const getSystemAdmins = async () => {
    Api()
      .get(`/general/dropdowns/${adminsDropdownValue}`)
      .then((response) => {
        setgetAdmins(response.data.data);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    getSystemAdmins();
  }, []);
  return (
    <div className="mt-10">
      <div className="m-5 flex justify-between ">
      <div className="text-3xl">Tasks</div>
        <button
          className="task-button"
          onClick={() => {
            setVisible(true);
          }}
        >
            Add
        </button>
      </div>

      <TicketsTab />

      <CreateTicket
        getAdmins={getAdmins}
        bindings={bindings}
        closeModal={closeModal}
      />
      
    </div>
  );
}
