export const developersCompanyname = "Swift Technologies. All Rights Reserved.";
export const forgotPasswordText = "Forgot password?";
export const forgotPasswordTitle = "Forgot Password";
export const applicationAbbrieviation = "LLMS";
export const resetPasswordTitle = "Reset Password";
export const usersDashboardTitle = "System Users";
export const addUserFormTitle = "Add System User";
export const addProfitFormTitle = "Add Profit and Loss";
export const editProfitFormTitle = "Edit Profit and Loss";
export const addPayslipFormTitle = "Add Payslip";
export const editPayslipFormTitle = "Edit Payslip";
export const addTransactionFormTitle = "Add Transaction";
export const addEarningsFormTitle = "Add Earnings";
export const addLoanFormTitle = "Add Loan";
export const editLoanFormTitle = "Edit Loan";
export const addBorrowerFormTitle = "Add Borrower";
export const addGuarantorFormTitle = "Add Guarantor";
export const editGuarantorFormTitle = "Edit Guarantor";
export const addNextOfKinFormTitle = "Add Next Of Kin";
export const editNextOfKinFormTitle = "Edit Next Of Kin";
export const editBorrowerFormTitle = "Edit Borrower";
export const editUserFormTitle = "Edit System User";
export const activeValue = 1;
export const inActiveValue = 0;
export const activeUserText = 'List of Active Users.'
export const inActiveUserText = 'List of Inactive Users.'
export const activeBorrowerText = 'List of Active Borrowers.'
export const inActiveBorrowerText = 'List of Inactive Borrowers.'
export const noDataAvailableText = 'No data available currently.'
export const getInActiveUsersText = 'Get Inactive Users'
export const getInActiveBorrowersText = 'Get Inactive Borrowers'
export const activeUsersText = 'Get Active Users'
export const activeBorrowersText = 'Get Active Borrowers'
export const activateUser = 1
export const deActivateUser = 0
export const businessGroupDropdownValue = 10
export const businessStatusDropdownValue = 9
export const identificationTypeDropdownValue = 7
export const borrowerDropdownValue = 12
export const repaymentCyclesValue = 3
export const interestMethod = 6
export const disbursed = 8
export const gurantors = 15
export const loanProductValue = 5
export const loanStatusValue = 4
export const transactionTypeDropdownValue = 2
export const agencyDropdownValue = 11
export const adminsDropdownValue = 16

export const baseURl = 'https://lewifinance.com/report'

export const SystemUserType = [
  { name: "Staff", value: 2 },
  { name: "Admin", value: 1 },
  { name: "Super Admin", value: 3 },
];

export const genderType = [
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
];

export const maritalStatus = [
  { name: "Married", value: "Married" },
  { name: "Divorced", value: "Divorced" },
  { name: "Single", value: "Single" }
];

export const workStatus = [
  { name: "Self Employed", value: "Self Employed" },
  { name: "Government Worker", value: "Government Worker" },
  { name: "Unemployed", value: "Unemployed" },
  { name: "Entrepreneur", value: "Entrepreneur" },
];

