import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import { useSnackbar } from "notistack";
import { Text } from "@nextui-org/react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import NoDataAvailable from "../Shared/NoDataAvailable";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import { currencyFormat } from "../../Services/validations";

export default function MonthlyExpenseTable({ url }) {
  const { enqueueSnackbar } = useSnackbar();
  const [expenses, setexpenses] = useState([]);
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [search, setSearch] = useState("");

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const date = new Date();


  const getExpenses = async () => {
    const data = {
      month: month === "" ? date.getMonth() : parseInt(month.split("-")[1]),
      year:
        year === ""
          ? parseInt(date.getFullYear())
          : parseInt(month.split("-")[0]),
    };
    Api()
      .post(`/transactions/monthly-expenses-statistics/`, data)
      .then((response) => {
        setexpenses(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const searchExpenses = async (value) => {
    const data = {
      search_query: value,
      month: month === "" ? date.getMonth() : parseInt(month.split("-")[1]),
      year:
        year === ""
          ? parseInt(date.getFullYear())
          : parseInt(month.split("-")[0]),
    };
    Api()
      .post(`transactions/search-expenses/${2}/${1}/`, data)
      .then((response) => {
        setexpenses(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  

  useEffect(() => {
    getExpenses();
  }, [month]);

  return (
    <div>
      <div className="grid grid-cols-4 mb-5 gap-4">
      <input
        type="search"
        className="border-2 rounded-md h-10 small:w-full mid:w-full ipad-pro:w-full ipad:w-full border-gray-400 placeholder:pl-3 pl-3"
        placeholder='Search by expense type'
        onChange={(e) => {
          setSearch(e.target.value);
          searchExpenses(e.target.value);
        }}
      />
        <input
          className="border-2 rounded-md"
          type="month"
          onChange={(e) => {
            setmonth(e.target.value);
          }}
        />
      </div>

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon />
        </div>
      ) : expenses?.length === 0 ? (
        <div className="flex justify-center">
          <NoDataAvailable />
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Total
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Count
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {expenses.map((expense, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    <Text>{expense.expense_type__name}</Text>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    &#8373;{" "}
                    {currencyFormat.format(expense.total_amount)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {expense.total_count}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {expenses.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady ? "" : "List of Monthly Expesnses"}
        </div>
      )}

    </div>
  );
}
