import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import NoDataAvailable from "../Shared/NoDataAvailable";
import { currencyFormat } from "../../Services/validations";

export default function StaffTransactionsTable({ type }) {
  const { enqueueSnackbar } = useSnackbar();
  const [transactions, setTransactions] = useState([]);
  const [systemUser, setsystemUser] = useState([]);
  const [user, setUser] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const date = new Date();

  const ID = `6f29d585-dce4-4c36-8e12-357f2328549a`;

  const getStaffTransactions = async () => {
    const data = {
      month: month === "" ? 1 : parseInt(month.split("-")[1]),
      year:
        year === ""
          ? parseInt(date.getFullYear())
          : parseInt(month.split("-")[0]),
    };
    Api()
      .post(
        `/transactions/calculate-staff-transactions/${
          user === "" ? ID : user
        }/`,
        data
      )
      .then((response) => {
        setTransactions(response.data.data);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getSystemUsers = async () => {
    Api()
      .post(`/auth/system-users/1/1/`)
      .then((response) => {
        setsystemUser(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getSystemUsers();
  }, []);

  useEffect(() => {
    getStaffTransactions();
  }, [month, user]);

  return (
    <div>
      <div className="grid grid-cols-4 mb-5 gap-4">
        <input
          className="border-2 rounded-md"
          type="month"
          onChange={(e) => {
            setmonth(e.target.value);
          }}
        />
        <select
          className="input-style"
          onChange={(e) => {
            setUser(e.target.value);
          }}
          placeholder="Select user"
        >
          {systemUser?.map((user, index) => {
            return (
              <option value={user.user_key} key={index}>
                {user.first_name + " " + user.last_name}
              </option>
            );
          })}
        </select>
      </div>

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon color="#007FFF" size={80} />
        </div>
      ) : transactions?.length <= 0 ? (
        <p className="flex justify-center">
          <NoDataAvailable />
        </p>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Agency
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Amount
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Count
                </StyledTableCell>
        
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transact, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                  {transact.agency__name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   {currencyFormat.format(transact.total_amount) }
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   {transact.total_count}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
