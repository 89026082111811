import React, { useState } from "react";
import { Modal, Text, Button } from "@nextui-org/react";
import moment from "moment";
import { currencyFormat } from "../../Services/validations";
import { useSnackbar } from "notistack";
import Api from "../../Services/api";
import Loading from "../../Components/Icons/Loading";

export default function GetOverDueLoan({

  open,
  onClose,
  gerOverdueLoan,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setloading] = useState(false);

  const sendReminder = async (id) => {
    const data = {
      loan_id: gerOverdueLoan.id,
    };
    Api()
      .post(`loans/send-due-loan-reminder/`, data)
      .then((response) => {
        setloading(true);
        enqueueSnackbar(response.data.details, {
          variant: "success",
          autoHideDuration: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };
  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="700px"
        preventClose
      >
        <div className="flex justify-center">
          <img
            src="./images/Logo.jpg"
            alt="Default Image"
            width={100}
            height={100}
          />
        </div>
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Detailed Loan Overdue
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Name: </span>
              {gerOverdueLoan?.borrower__first_name +
                " " +
                gerOverdueLoan?.borrower__last_name}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Principal Amount: </span>
              {currencyFormat.format(gerOverdueLoan?.principal_amount)}
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Due Date: </span>
              {moment(`${gerOverdueLoan?.due_date}`).format("ll")}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Approved On: </span>
              { moment(`${gerOverdueLoan?.approved_on
                }`).format(
                    "lll"
                  )}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Loan Title: </span>
              {gerOverdueLoan?.loan_title}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Loan Description: </span>
              {gerOverdueLoan?.loan_description}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Amount Payable: </span>
              {currencyFormat.format(gerOverdueLoan?.amount_payable)}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Amount Oustanding: </span>
              {currencyFormat.format(gerOverdueLoan?.amount_outstanding)}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold"> Installments: </span>
              {currencyFormat.format(gerOverdueLoan?.amount_per_repayment)}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Total Paid: </span>
              {currencyFormat.format(gerOverdueLoan?.total_paid)}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Approved: </span>
              {gerOverdueLoan?.is_approved ? 'Yes' : 'No'}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Paid Off: </span>
              {gerOverdueLoan?.is_paid_off ? 'Yes' : 'No'}
            </label>
          </div>


          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Loan Interest: </span>
              {gerOverdueLoan?.loan_interest}  &#37;
            </label>
            <label className="block mt-5">
              <span className="font-bold">Loan Duration: </span>
              {gerOverdueLoan?.repayment_cycle__name +
                "(" +
                gerOverdueLoan?.loan_duration +
                ")"}
            </label>
          </div>

          <Modal.Footer>
            <Button auto flat color="error" onClick={onClose}>
              Close
            </Button>
            <Button auto flat color="primary" onClick={sendReminder}>
              {loading ? <Loading /> : "Send Reminder"}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
}
