import React from 'react'
import { Loading } from "@nextui-org/react";

export default function LoadingIcon() {
  return (
    <div>
        <Loading type="default" size="xs" color="white" />
    </div>
  )
}
