import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import moment from "moment";
import DeleteIcon from "../Icons/DeleteIcon";
import { Row, Tooltip, Spacer } from "@nextui-org/react";
import { IconButton } from "../Icons/IconButton";
import DeleteData from "../Shared/DeleteData";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Paginations from "../Shared/Pagination";
import SearchInput from "../Shared/SearchInput";
import { adminRole, superAdminRole } from "../../Services/roles.js";
import { EditIcon } from "../Icons/EditIcon";
import EditProfit from "../Forms/EditProfit";
import { useSnackbar } from "notistack";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import NoDataAvailable from "../Shared/NoDataAvailable";
import ApproveIcon from "../Icons/ApproveIcon";
import ApprovedIcon from "../Icons/ApprovedIcon";
import ApproveAction from "../Shared/ApproveAction";
import { currencyFormat } from "../../Services/validations";

export default function ProfitTable() {
  const { enqueueSnackbar } = useSnackbar();
  const [isReady, setIsReady] = useState(false);
  const [visible, setVisible1] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [dataID, setdataID] = useState("");
  const profitUrl = "transactions/delete-profit";
  const [totalPages, setTotalPages] = useState([]);
  const [profitData, setProfitData] = useState("");

  const [editModal, seteditModal] = useState(false);

  const [visible2, setVisible2] = useState(false);
  const [approveId, setapproveId] = useState("");

  const approveTaskURL = "transactions/approve-profits";

  const promtMessage = 'Are you sure you want to Approve Profit?'

  const openApproveModal = () => setVisible2(true);

  const closeApproveModal = () => setVisible2(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const searchURL = "/transactions/search-profits";

  const openProfitModal = () => seteditModal(true);
  const closeProfitModal = () => seteditModal(true);

  const openDeleteModal = () => setVisible1(true);

  const closeDeleteModal = () => setVisible1(false);

  const [profits, setProfits] = useState([]);

  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getProfitAndLoss(event);
  };

  const getProfitAndLoss = async (pageNumberSelected = 1) => {
    Api()
      .get(`/transactions/profits/${pageNumberSelected}/`)
      .then((response) => {
        setProfits(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getProfitAndLoss();
  }, []);
  return (
    <div>
      <SearchInput
        getSearchData={setProfits}
        searchURL={searchURL}
        setTotalPages={setTotalPages}
        placeholder="Search by recorded date (YYYY-MM-DD)"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon color="#007FFF" size={80} />
        </div>
      ) : profits?.length <= 0 ? (
        <p className="flex justify-center">
          <NoDataAvailable />
        </p>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Recorded By
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Opening Bal.
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Closing Bal.
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Difference Bal.
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Recorded On
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Approved On
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {profits.map((profit, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {profit.recorded_by__first_name +
                      " " +
                      profit.recorded_by__last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   {currencyFormat.format(profit.opening_balance)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   {currencyFormat.format(profit.closing_balance)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   {currencyFormat.format(profit.difference)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(`${profit.recorded_on}`).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {profit.approved_on === null
                      ? "Not Approved"
                      : moment(`${profit.approved_on}`).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Row align="flex-start">
                      {adminRole() || superAdminRole() ? (
                        profit.is_approved ? (
                          <Tooltip content="Already Approved" color="success">
                            <ApprovedIcon fill="green" />
                          </Tooltip>
                        ) : (
                          <Tooltip content="Approve" color="primary">
                            <IconButton
                              onClick={() => {
                                setapproveId(profit.id);
                                openApproveModal();
                              }}
                            >
                              <ApproveIcon fill="#8F00FF" />
                            </IconButton>
                          </Tooltip>
                        )
                      ) : (
                        ""
                      )}

                      <Spacer x={1} />

                      {profit.is_approved ? (
                        ""
                      ) : (
                        <Tooltip content="Edit" color="primary">
                          <IconButton
                            onClick={() => {
                              setProfitData(profit);
                              openProfitModal();
                            }}
                          >
                            <EditIcon size={20} fill="#007FFF" show={false} />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Spacer x={1} />

                      {profit.is_approved ? (
                        ""
                      ) : (
                        <Tooltip content="Delete" color="error">
                          <IconButton
                            onClick={() => {
                              setdataID(profit.id);
                              openDeleteModal();
                            }}
                          >
                            <DeleteIcon fill="#D22B2B" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Row>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {profits.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady ? "" : "List of Profit and Loss"}
        </div>
      )}

      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <EditProfit
        profitData={profitData}
        open={editModal}
        onClose={closeProfitModal}
      />

      <ApproveAction
        visible={visible2}
        url={approveTaskURL}
        approveId={approveId}
        onClose={closeApproveModal}
        promptMessage={promtMessage}
      />

      <DeleteData
        visible={visible}
        url={profitUrl}
        deleteId={dataID}
        onClose={closeDeleteModal}
      />
    </div>
  );
}
