import React, { useState, useEffect } from "react";
import {
  repaymentCyclesValue,
  interestMethod,
  disbursed,
  loanProductValue,
  loanStatusValue,
  editLoanFormTitle,
} from "../../Shared/constants";
import Api from "../../Services/api";
import AddFormButton from "../Shared/AddFormButton";
import { Modal, Text, Button } from "@nextui-org/react";
import { useSnackbar } from "notistack";

export default function EditLoan({ open, onClose, loanData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [principal, setprincipal] = useState("");
  const [loanTitle, setloanTitle] = useState("");
  const [loanDesctiption, setloanDesctiption] = useState("");
  const [repaymentCycle, setrepaymentCycle] = useState("");
  const [interestmethod, setinterestmethod] = useState("");
  const [loanProduct, setloanProduct] = useState("");
  const [disbursedBy, setdisbursedBy] = useState("");
  const [loanStatus, setloanStatus] = useState("");
  const [loanInterest, setloanInterest] = useState("");
  const [loanDuration, setloanDuration] = useState("");
  const [borrowerid, setborrowerid] = useState("");

  const [showLoading, setShowLoading] = useState(false);
  const [repaymentCycles, setrepaymentCycles] = useState([]);
  const [interestMethods, setinterestMethods] = useState([]);
  const [userDisbursed, setuserDisbursed] = useState([]);
  const [loanProducts, setloanProducts] = useState([]);
  const [loanStatuses, setloanStatuses] = useState([]);


  const getRepaymentsCycles = () => {
    Api()
      .get(`/general/dropdowns/${repaymentCyclesValue}/`)
      .then((response) => {
        setrepaymentCycles(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getInterestMethods = () => {
    Api()
      .get(`/general/dropdowns/${interestMethod}/`)
      .then((response) => {
        setinterestMethods(response.data.data);
      })
      .catch((error) => {
        setShowLoading(false);
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getDisbursedBy = () => {
    Api()
      .get(`/general/dropdowns/${disbursed}/`)
      .then((response) => {
        setuserDisbursed(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getLoanProducts = () => {
    Api()
      .get(`/general/dropdowns/${loanProductValue}/`)
      .then((response) => {
        setloanProducts(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getLoanStatus = () => {
    Api()
      .get(`/general/dropdowns/${loanStatusValue}/`)
      .then((response) => {
        setloanStatuses(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const editLoans = async (e) => {
    e.preventDefault();
    const data = {
      principal_amount: principal,
      repayment_cycle_id: parseInt(repaymentCycle),
      disbursed_by_id: parseInt(disbursedBy),
      interest_method_id: parseInt(interestmethod),
      loan_product_id: parseInt(loanProduct),
      loan_status_id: parseInt(loanStatus),
      loan_interest: parseInt(loanInterest),
      loan_duration: parseInt(loanDuration),
      loan_title: loanTitle,
      loan_description: loanDesctiption,
    };

    setShowLoading(true);
    Api()
      .put(`/loans/edit-loan/${loanData.id}/`, data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
        setShowLoading(false);
      });
  };

  useEffect(() => {
    setprincipal(loanData?.principal_amount);
    setloanTitle(loanData?.loan_title);
    setloanDesctiption(loanData?.loan_description);
    setrepaymentCycle(loanData?.repayment_cycle_id);
    setinterestmethod(loanData?.interest_method_id);
  }, []);

  useEffect(() => {
    setloanProduct(loanData?.loan_product_id);
    setdisbursedBy(loanData?.disbursed_by_id);
    setloanStatus(loanData?.loan_status_id);
    setloanInterest(loanData?.loan_interest);
    setloanDuration(loanData?.loan_duration);
    setborrowerid(loanData?.borrower_id);
  }, []);

  useEffect(() => {
    getRepaymentsCycles();
    getInterestMethods();
    getDisbursedBy();
    getLoanProducts();
    getLoanStatus();
  }, []);

  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {editLoanFormTitle}
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 gap-4">
            <label className="block mt-5">
              <span className="required-label">Principal</span>
              <input
                required
                type="number"
                name="principal"
                defaultValue={loanData?.principal_amount || principal}
                onChange={(e) => {
                  setprincipal(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Title</span>
              <input
                required
                type="text"
                name="loanTitle"
                defaultValue={loanData?.loan_title || loanTitle}
                onChange={(e) => {
                  setloanTitle(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <label className="block mt-5">
              <span className="required-label">Duration</span>
              <input
                required
                type="text"
                name="loanDuration"
                defaultValue={loanData?.loan_duration || loanDuration}
                onChange={(e) => {
                  setloanDuration(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">APR</span>
              <input
                required
                type="text"
                name="loanInterest"
                defaultValue={loanData?.loan_interest || loanInterest}
                onChange={(e) => {
                  setloanInterest(e.target.value);
                }}
                className="input-style"
              />
            </label>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Desctiption</span>
              <textarea
                required
                type="text"
                name="loanDesctiption"
                defaultValue={loanData?.loan_description || loanDesctiption}
                onChange={(e) => {
                  setloanDesctiption(e.target.value);
                }}
                className="input-style"
                style={{ height: "100px" }}
              />
            </label>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <label className="block mt-5">
              <span className="required-label">Borrower</span>
              <input
                required
                type="text"
                name="borrower"
                disabled
                defaultValue={loanData?.borrower__first_name + " " + loanData?.borrower__last_name }
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Disbursed By</span>
              <select
                className="input-style"
                defaultValue={loanData?.disbursed_by_id || disbursedBy}
                onChange={(e) => {
                  setdisbursedBy(e.target.value);
                }}
              >
                {userDisbursed?.map((disbursed, index) => {
                  return (
                    <option value={disbursed.id} key={index}>
                      {disbursed.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <label className="block mt-5">
              <span className="required-label">Repayment Cycle</span>
              <select
                className="input-style"
                defaultValue={loanData?.repayment_cycle_id || repaymentCycle}
                onChange={(e) => {
                  setrepaymentCycle(e.target.value);
                }}
              >
                {repaymentCycles?.map((repayCycle, index) => {
                  return (
                    <option value={repayCycle.id} key={index}>
                      {repayCycle.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="block mt-5">
              <span className="required-label">Interest Method</span>
              <select
                className="input-style"
                defaultValue={loanData?.interest_method_id || interestmethod}
                onChange={(e) => {
                  setinterestmethod(e.target.value);
                }}
              >
                {interestMethods?.map((intMethod, index) => {
                  return (
                    <option value={intMethod.id} key={index}>
                      {intMethod.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <label className="block mt-5">
              <span className="required-label">Product</span>
              <select
                className="input-style"
                defaultValue={loanData?.loan_product_id || loanProduct}
                onChange={(e) => {
                  setloanProduct(e.target.value);
                }}
              >
                {loanProducts?.map((product, index) => {
                  return (
                    <option value={product.id} key={index}>
                      {product.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="block mt-5">
              <span className="required-label">Status</span>
              <select
                className="input-style"
                defaultValue={loanData?.loan_status_id || loanStatus}
                onChange={(e) => {
                  setloanStatus(e.target.value);
                }}
              >
                {loanStatuses?.map((status, index) => {
                  return (
                    <option value={status.id} key={index}>
                      {status.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <AddFormButton loading={showLoading} onClick={editLoans} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
