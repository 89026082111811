import { useState, useEffect } from "react";
import Api from "../../Services/api";
import {
  Navbar,
  Link,
  Text,
  Avatar,
  Dropdown,
  Badge,
  Image,
} from "@nextui-org/react";
import { Box } from "../Layouts/Box";
import { icons } from "../Icons/SidebarIcons";
import { adminRole, superAdminRole } from "../../Services/roles";
import { getCurrentUser, logoutUser } from "../../Services/auth";
import { FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  GiMoneyStack,
  GiTakeMyMoney,
  GiReceiveMoney,
  GiPayMoney,
  GiChart
} from "react-icons/gi";
import LogoutIcon from "../Icons/LogoutIcon";
import Password from "../Icons/Password";
import { FiBell } from "react-icons/fi";


export default function TopSideBar({ page }) {
  const [tickets, settickets] = useState([]);

  const getTickets = async (pageNumberSelected = 1) => {
    Api()
      .post(`/transactions/tickets-received/${pageNumberSelected}/`)
      .then((response) => {
        settickets(response.data.total_tasks);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getTickets();
  }, []);

  const mobileRoutes = [
    { name: "Active Borrowers", route: "/borrowers" },
    { name: "Inactive Borrowers", route: "inactive-borrowers" },
    { name: "Loans", route: "/loans" },
    { name: "Overdue Loans", route: "/overdue-loans" },
    { name: "Repayments", route: "/repayments" },
    { name: "Due Repayments", route: "/due-repayments" },
    { name: "Transactions", route: "/transactions" },
    { name: "Tasks", route: "/tasks" },
  ];

  const adminMobileRoutes = [
    { name: "Active Users", route: "/users" },
    { name: "Inactive Users", route: "inactive-users" },
    { name: "Earnings", route: "/earnings" },
    { name: "Profit", route: "/profit" },
  ];

  const superAdminMobileRoutes = [
    { name: "Active Users", route: "/users" },
    { name: "Inactive Users", route: "inactive-users" },
    { name: "Statistics", route: "/statistics" },
    { name: "Transactional Statistics", route: "/trans-stats" },
    { name: "Earnings", route: "/earnings" },
    { name: "Expenses", route: "/expenses" },
    { name: "Profit", route: "/profit" },
  ];

  let navigate = useNavigate();
  const logOutUser = () => {
    logoutUser();
    navigate("/", { replace: true });
  };

  const changePass = () => {
    navigate("/change-password");
  };

  const userName =
    getCurrentUser().first_name + " " + getCurrentUser().last_name;
  const userRole = getCurrentUser().role__name;

  return (
    <Box
      css={{
        maxW: "150%",
      }}
    >
      <Navbar borderWeight="bold" variant="floating">
        <Navbar.Toggle showIn="xs" />
        <Navbar.Brand>
          <Image
            src="./images/Logo.jpg"
            alt="Default Image"
            width={55}
            height={55}
          />
          {/* <Text size={20} color="inherit">
            LLMS
          </Text> */}
        </Navbar.Brand>
        <Navbar.Content
          enableCursorHighlight
          activeColor="primary"
          hideIn="xs"
          variant="highlight"
        >
          {adminRole() || superAdminRole() ? (
            <Dropdown isBordered>
              <Navbar.Item
                isActive={
                  window.location.pathname === "/users" ||
                  window.location.pathname === "/inactive-users"
                }
              >
                <Dropdown.Button
                  auto
                  light
                  css={{
                    px: 0,
                    svg: { pe: "none" },
                  }}
                  iconRight={icons.chevron}
                  ripple={false}
                >
                  Users
                </Dropdown.Button>
              </Navbar.Item>
              <Dropdown.Menu
                aria-label="Users options"
                css={{
                  $$dropdownMenuWidth: "150px",
                  $$dropdownItemHeight: "50px",
                  "& .nextui-dropdown-item": {
                    // dropdown item left icon
                    svg: {
                      color: "$primary",
                    },
                    // dropdown item title
                    "& .nextui-dropdown-item-content": {
                      w: "100%",
                      fontWeight: "$extrabold",
                      display: "flex",
                      justifyContent: "start",
                    },
                  },
                }}
              >
                <Dropdown.Item
                  key="autoscaling"
                  showFullDescription
                  icon={icons.activeUsers}
                >
                  <Navbar.Link href="/users">Active</Navbar.Link>
                </Dropdown.Item>
                <Dropdown.Item
                  key="usage_metrics"
                  showFullDescription
                  icon={<FiUsers />}
                >
                  <Navbar.Link href="/inactive-users">Inactive</Navbar.Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}

          <Dropdown isBordered>
            <Navbar.Item
              isActive={
                window.location.pathname === "/borrowers" ||
                window.location.pathname === "/inactive-borrowers"
              }
            >
              <Dropdown.Button
                auto
                light
                css={{
                  px: 0,
                  dflex: "center",
                  svg: { pe: "none" },
                }}
                iconRight={icons.chevron}
                ripple={false}
              >
                Borrowers
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="Borrowers options"
              css={{
                $$dropdownMenuWidth: "340px",
                $$dropdownItemHeight: "50px",
                "& .nextui-dropdown-item": {
                  // dropdown item left icon
                  svg: {
                    color: "$primary",
                  },
                  // dropdown item title
                  "& .nextui-dropdown-item-content": {
                    w: "100%",
                    fontWeight: "$extrabold",
                    display: "flex",
                    justifyContent: "start",
                  },
                },
              }}
            >
              <Dropdown.Item
                key="autoscaling"
                showFullDescription
                icon={<GiReceiveMoney size={25} className="mb-2" />}
              >
                <Navbar.Link href="/borrowers">Active</Navbar.Link>
              </Dropdown.Item>
              <Dropdown.Item
                key="usage_metrics"
                showFullDescription
                icon={<GiPayMoney size={25} className="" />}
              >
                <Navbar.Link href="/inactive-borrowers">Inactive</Navbar.Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown isBordered>
            <Navbar.Item
              isActive={
                window.location.pathname === "/loans" ||
                window.location.pathname === "/overdue-loans"
              }
            >
              <Dropdown.Button
                auto
                light
                css={{
                  px: 0,
                  dflex: "center",
                  svg: { pe: "none" },
                }}
                iconRight={icons.chevron}
                ripple={false}
              >
                Loans
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="Loans options"
              css={{
                $$dropdownMenuWidth: "340px",
                $$dropdownItemHeight: "50px",
                "& .nextui-dropdown-item": {
                  // dropdown item left icon
                  svg: {
                    color: "$primary",
                  },
                  // dropdown item title
                  "& .nextui-dropdown-item-content": {
                    w: "100%",
                    fontWeight: "$extrabold",
                    display: "flex",
                    justifyContent: "start",
                  },
                },
              }}
            >
              <Dropdown.Item
                key="autoscaling"
                showFullDescription
                icon={<GiMoneyStack size={25} />}
              >
                <Navbar.Link href="/loans">Loans</Navbar.Link>
              </Dropdown.Item>
              <Dropdown.Item
                key="usage_metrics"
                showFullDescription
                icon={<GiTakeMyMoney size={25} />}
              >
                <Navbar.Link href="/overdue-loans">Overdue</Navbar.Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {superAdminRole() ? (
             <Dropdown isBordered>
             <Navbar.Item
               isActive={
                 window.location.pathname === "/statistics" ||
                 window.location.pathname === "/trans-stats"
               }
             >
               <Dropdown.Button
                 auto
                 light
                 css={{
                   px: 0,
                   dflex: "center",
                   svg: { pe: "none" },
                 }}
                 iconRight={icons.chevron}
                 ripple={false}
               >
                 Statistics
               </Dropdown.Button>
             </Navbar.Item>
             <Dropdown.Menu
               aria-label="Loans options"
               css={{
                 $$dropdownMenuWidth: "340px",
                 $$dropdownItemHeight: "50px",
                 "& .nextui-dropdown-item": {
                   // dropdown item left icon
                   svg: {
                     color: "$primary",
                   },
                   // dropdown item title
                   "& .nextui-dropdown-item-content": {
                     w: "100%",
                     fontWeight: "$extrabold",
                     display: "flex",
                     justifyContent: "start",
                   },
                 },
               }}
             >
               <Dropdown.Item
                 key="autoscaling"
                 showFullDescription
                 icon={<GiMoneyStack size={25} />}
               >
                 <Navbar.Link href="/statistics">Portfolio</Navbar.Link>
               </Dropdown.Item>
               
               <Dropdown.Item
                 key="usage_metrics"
                 showFullDescription
                 icon={<GiChart size={25} />}
               >
                 <Navbar.Link href="/trans-stats">Transactions Stats</Navbar.Link>
               </Dropdown.Item>
             </Dropdown.Menu>
           </Dropdown>
          ) : (
            ""
          )}

          {adminRole() || superAdminRole() ? (
             <Dropdown isBordered>
             <Navbar.Item
               isActive={
                 window.location.pathname === "/earnings" ||
                 window.location.pathname === "/expenses"
               }
             >
               <Dropdown.Button
                 auto
                 light
                 css={{
                   px: 0,
                   dflex: "center",
                   svg: { pe: "none" },
                 }}
                 iconRight={icons.chevron}
                 ripple={false}
               >
                 Earnings
               </Dropdown.Button>
             </Navbar.Item>
             <Dropdown.Menu
               aria-label="Loans options"
               css={{
                 $$dropdownMenuWidth: "340px",
                 $$dropdownItemHeight: "50px",
                 "& .nextui-dropdown-item": {
                   // dropdown item left icon
                   svg: {
                     color: "$primary",
                   },
                   // dropdown item title
                   "& .nextui-dropdown-item-content": {
                     w: "100%",
                     fontWeight: "$extrabold",
                     display: "flex",
                     justifyContent: "start",
                   },
                 },
               }}
             >
               <Dropdown.Item
                 key="autoscaling"
                 showFullDescription
                 icon={<GiMoneyStack size={25} />}
               >
                 <Navbar.Link href="/earnings">Earnings</Navbar.Link>
               </Dropdown.Item>
               <Dropdown.Item
                 key="usage_metrics"
                 showFullDescription
                 icon={<GiTakeMyMoney size={25} />}
               >
                 <Navbar.Link href="/expenses">Expenses</Navbar.Link>
               </Dropdown.Item>
             </Dropdown.Menu>
           </Dropdown>
            // <Navbar.Link
            //   isActive={window.location.pathname === "/earnings"}
            //   href="/earnings"
            // >
            //   Earnings
            // </Navbar.Link>
          ) : (
            ""
          )}

          {adminRole() || superAdminRole() ? (
            <Navbar.Link
              isActive={window.location.pathname === "/profit"}
              href="/profit"
            >
              Profit
            </Navbar.Link>
          ) : (
            ""
          )}

   
          <Dropdown isBordered>
             <Navbar.Item
               isActive={
                 window.location.pathname === "/repayments" ||
                 window.location.pathname === "/repayments"
               }
             >
               <Dropdown.Button
                 auto
                 light
                 css={{
                   px: 0,
                   dflex: "center",
                   svg: { pe: "none" },
                 }}
                 iconRight={icons.chevron}
                 ripple={false}
               >
                 Repay
               </Dropdown.Button>
             </Navbar.Item>
             <Dropdown.Menu
               aria-label="Loans options"
               css={{
                 $$dropdownMenuWidth: "340px",
                 $$dropdownItemHeight: "50px",
                 "& .nextui-dropdown-item": {
                   // dropdown item left icon
                   svg: {
                     color: "$primary",
                   },
                   // dropdown item title
                   "& .nextui-dropdown-item-content": {
                     w: "100%",
                     fontWeight: "$extrabold",
                     display: "flex",
                     justifyContent: "start",
                   },
                 },
               }}
             >
               <Dropdown.Item
                 key="autoscaling"
                 showFullDescription
                 icon={<GiMoneyStack size={25} />}
               >
                 <Navbar.Link href="/repayments">Repayments</Navbar.Link>
               </Dropdown.Item>
               <Dropdown.Item
                 key="usage_metrics"
                 showFullDescription
                 icon={<GiTakeMyMoney size={25} />}
               >
                 <Navbar.Link href="/due-repayments">Due Repayments</Navbar.Link>
               </Dropdown.Item>
             </Dropdown.Menu>
           </Dropdown>
          <Navbar.Link
            isActive={window.location.pathname === "/transactions"}
            href="/transactions"
          >
            Transactions
          </Navbar.Link>
          <Navbar.Link
            isActive={window.location.pathname === "/tasks"}
            href="/tasks"
          >
            Tasks
          </Navbar.Link>
        </Navbar.Content>
        <Navbar.Content
          css={{
            "@xs": {
              w: "12%",
              jc: "flex-end",
            },
          }}
        >
          <Navbar.Link href="/tasks">
            <Badge color="error" size="md" content={tickets}>
              <FiBell size={30} />
            </Badge>
          </Navbar.Link>
          <Dropdown placement="bottom-right">
            <Navbar.Item>
              <Dropdown.Trigger>
                <Avatar
                  bordered
                  as="button"
                  color="primary"
                  size="lg"
                  src="./images/default-avatar.png"
                />
              </Dropdown.Trigger>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="User menu actions"
              color="primary"
              onAction={(actionKey) => {
                actionKey === "logout" ? logOutUser() : changePass();
              }}
            >
              <Dropdown.Item key="profile" css={{ height: "$18" }}>
                <Text color="inherit" css={{ d: "flex" }}>
                  Signed in as
                </Text>
                <Text color="inherit" css={{ d: "flex" }}>
                  {userName} ({userRole})
                </Text>
              </Dropdown.Item>

              <Dropdown.Item
                textValue="change"
                key="change-password"
                withDivider
                icon={<Password />}
              >
                Change Password
              </Dropdown.Item>
              <Dropdown.Item
                textValue="log"
                key="logout"
                withDivider
                color="error"
                icon={<LogoutIcon />}
              >
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Content>
        <Navbar.Collapse>
          {adminRole()
            ? adminMobileRoutes.map((item) => (
                <Navbar.CollapseItem
                  key={item.name}
                  activeColor="primary"
                  isActive={item.route === window.location.pathname}
                >
                  <Link
                    color="inherit"
                    css={{
                      minWidth: "100%",
                    }}
                    href={item.route}
                  >
                    {item.name}
                  </Link>
                </Navbar.CollapseItem>
              ))
            : superAdminRole()
            ? superAdminMobileRoutes.map((item) => (
                <Navbar.CollapseItem
                  key={item.name}
                  activeColor="primary"
                  isActive={item.route === window.location.pathname}
                >
                  <Link
                    color="inherit"
                    css={{
                      minWidth: "100%",
                    }}
                    href={item.route}
                  >
                    {item.name}
                  </Link>
                </Navbar.CollapseItem>
              ))
            : ""}
          {mobileRoutes.map((item) => (
            <Navbar.CollapseItem
              key={item.name}
              activeColor="primary"
              isActive={item.route === window.location.pathname}
            >
              <Link
                color="inherit"
                css={{
                  minWidth: "100%",
                }}
                href={item.route}
              >
                {item.name}
              </Link>
            </Navbar.CollapseItem>
          ))}
        </Navbar.Collapse>
      </Navbar>
      <div>{page}</div>
    </Box>
  );
}
