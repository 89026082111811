import React, { useState, useEffect } from "react";
import { Pagination } from "@nextui-org/react";

export default function Paginations({
  initialPage,
  page,
  onChange,
  total,
  length,
  onpageLoad,
}) {
  useEffect(() => {
    onpageLoad(false);
  }, [page]);

  return (
    <div className="flex justify-center mt-5 mb-10">
      {length < 2 ? (
        ""
      ) : (
        <Pagination
          total={total}
          page={page}
          initialPage={initialPage}
          onChange={onChange}
          rounded
          loop
          siblings={1}
          animated
        />
      )}
    </div>
  );
}
