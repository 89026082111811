import React from "react";
import RepaymnetsTabs from "./RepaymentsTab";

export default function DueRepayments() {
  return (
    <div className="mt-10">
         <div className="m-5 text-3xl flext justify-start">Due Repayments</div>
      <RepaymnetsTabs />
    </div>
  );
}
