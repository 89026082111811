import React from "react";
import Loading from "../../Components/Icons/Loading"
import { Grid } from "@nextui-org/react";

export default function AddFormButton({ onClick , loading}) {


  return (
    <div>
        <Grid>
        <button type="submit" className="saveButtonStyle" onClick={onClick}  >
        {loading ? <Loading /> : <p className="font-medium text-white">Save</p> }
        </button>
      </Grid>
    </div>
  );
}
