import React from 'react'
import BounceLoader from "react-spinners/BounceLoader";

export default function PageLoaderIcon() {
  return (
    <div className='mt-32'>
        <BounceLoader color='#007FFF' size={80}/>
        <p>Loading data...</p>
    </div>
  )
}
