import axios from 'axios'
import Cookies from 'js-cookie'
import config from '../public/config'

export default () => {
    const authToken = Cookies.get('authToken')
    return axios.create({
        baseURL: config.api,
        withCredentials: false,
        headers: {
            "Access-Control-Allow-Origin" : "*",
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: authToken ? `Bearer ${authToken}` : null
        }
    })
}


