import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import {
  agencyDropdownValue,
  addEarningsFormTitle,
} from "../../Shared/constants";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import { useSnackbar } from "notistack";

export default function AddEarning({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setAmount] = useState("");
  const [agencyId, setAgencyId] = useState(2);
  const [showLoading, setShowLoading] = useState(false);

  const [agencyIds, setAgencyIds] = useState([]);

  const getAgencies = () => {
    Api()
      .get(`/general/dropdowns/${agencyDropdownValue}/`)
      .then((response) => {
        setAgencyIds(response.data.data);
      })
      .catch((error) => {});
  };


  const addEarnings = async (e) => {
    e.preventDefault();
    const data = {
      amount: amount,
      agency_id: agencyId,
    };

    setShowLoading(true);
    Api()
      .post("/general/earning/", data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getAgencies();
  }, []);

  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {addEarningsFormTitle}
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="required-label">Amount</span>
              <input
                required
                type="number"
                name="amount"
                defaultValue={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                className="input-style"
              />
            </label>
            <label className="block mt-5">
              <span className="required-label">Agency</span>
              <select
                className="input-style"
                onChange={(e) => {
                  setAgencyId(e.target.value);
                }}
              >
                {agencyIds?.map((agency, index) => {
                  return (
                    <option value={agency.id} key={index}>
                      {agency.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button auto flat color="error" onClick={onClose}>
            Close
          </Button>
          <AddFormButton loading={showLoading} onClick={addEarnings} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
