import React, { useEffect } from "react";
import { Modal, Text } from "@nextui-org/react";
import moment from "moment";
import { currencyFormat } from "../../Services/validations";

export default function GetRepayments({ open, onClose, getRepayment }) {
  useEffect(() => {}, []);
  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <div className="flex justify-center">
          <img
            src="./images/Logo.jpg"
            alt="Default Image"
            width={100}
            height={100}
          />
        </div>
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Detailed Repayment
          </Text>
        </Modal.Header>
        <Modal.Body className="small:m-0 m-5">
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Amount: </span>
             {currencyFormat.format(getRepayment.amount)}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Borrower: </span>
              {getRepayment.borrower_first_name +
                " " +
                getRepayment.borrower_last_name}
            </label>
          </div>
          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Approved By: </span>
              {getRepayment.approved_by__first_name === null
                ? "Not Approved"
                : getRepayment.approved_by__first_name +
                  " " +
                  getRepayment.approved_by__last_name}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Collected By: </span>
              {getRepayment.collected_by__first_name +
                " " +
                getRepayment.collected_by__last_name}
            </label>
          </div>

          <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Approved Date: </span>
              {getRepayment.approved_on === null
                ? "Not Approved"
                : moment(`${getRepayment.approved_on}`).format("lll")}
            </label>
            <label className="block mt-5">
              <span className="font-bold">Approved: </span>
              {getRepayment.is_approved ? "YES" : "NO"}
            </label>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <label className="block mt-5">
              <span className="font-bold">Repayment Type: </span>
              {getRepayment.repayment_type}
            </label>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
