import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import moment from "moment";
import EyeIcon from "../Icons/EyeIcon";
import { Row, Tooltip, Spacer, useModal, Text } from "@nextui-org/react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import DeleteData from "../Shared/DeleteData";
import DeleteIcon from "../Icons/DeleteIcon";
import { EditIcon } from "../Icons/EditIcon";
import { IconButton } from "../Icons/IconButton";
import GetRepayments from "../Views/GetRepayments";
import Paginations from "../Shared/Pagination";
import { adminRole, superAdminRole } from "../../Services/roles.js";
import { adminsDropdownValue } from "../../Shared/constants";
import CreateTicket from "../Shared/CreateTicket";
import TicketIcon from "../Icons/TicketIcon";
import SearchInput from "../Shared/SearchInput";
import { useSnackbar } from "notistack";
import PageLoaderIcon from "../Shared/PageLoaderIcon";
import NoDataAvailable from "../Shared/NoDataAvailable";
import ApproveIcon from "../Icons/ApproveIcon";
import ApprovedIcon from "../Icons/ApprovedIcon";
import ApproveAction from "../Shared/ApproveAction";
import { currencyFormat } from "../../Services/validations";

export default function RepymentsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const { setVisible, bindings } = useModal();
  const [isReady, setIsReady] = useState(false);
  const [getRepayment, setgetRepayment] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [repayData, setrepayData] = useState("");
  const [visible, setVisible1] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [dataID, setdataID] = useState("");
  const repayUrl = "loans/delete-repayment";
  const [getRepay, setgetRepay] = useState("");

  const [visible2, setVisible2] = useState(false);
  const [approveId, setapproveId] = useState("");

  const approveTaskURL = "loans/approve-repayment";

  const promtMessage = 'Are you sure you want to Approve Repayments?'

  const openApproveModal = () => setVisible2(true);

  const closeApproveModal = () => setVisible(false);

  const [getSinlgeView, setgetSinlgeView] = useState(false);

  const openView = () => setgetSinlgeView(true);

  const closeView = () => setgetSinlgeView(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const searchURL = "/loans/search-repayments";

  const [getAdmins, setgetAdmins] = useState([]);

  const closeModal = () => setVisible(false);

  const openDeleteModal = () => setVisible1(true);

  const closeDeleteModal = () => setVisible1(false);

  const handlePageChange = async (event) => {
    setpageNumber(event);
    await getRepayments(event);
  };


  const getRepayments = async (pageNumberSelected = 1) => {
    Api()
      .get(`/loans/repayments/${pageNumberSelected}/`)
      .then((response) => {
        setgetRepayment(response.data.data);
        setTotalPages(response.data.total_pages);
        setIsReady(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  const getSystemAdmins = async () => {
    Api()
      .get(`/general/dropdowns/${adminsDropdownValue}/`)
      .then((response) => {
        setgetAdmins(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  useEffect(() => {
    getRepayments();
    getSystemAdmins();
  }, []);
  return (
    <div>
      <SearchInput
        getSearchData={setgetRepayment}
        searchURL={searchURL}
        setTotalPages={setTotalPages}
        placeholder="Search by borrower name"
        pageNumber={pageNumber}
      />

      {!isReady ? (
        <div className="flex justify-center">
          <PageLoaderIcon color="#007FFF" size={80} />
        </div>
      ) : getRepayment?.length <= 0 ? (
        <p className="flex justify-center">
          <NoDataAvailable />
        </p>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Loan ID
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Borrower
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Amount
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Collected By
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Collected On
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  align="left"
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {getRepayment.map((repay, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                  <Text
                      className=""
                      css={{
                        textGradient: "45deg, $green600 -20%, $green600 100%",
                      }}
                    >
                      {repay.loan_id}
                    </Text>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {repay.borrower_first_name + " " + repay.borrower_last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   {currencyFormat.format(repay.amount)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {repay.collected_by__first_name +
                      " " +
                      repay.collected_by__last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {repay.collected_on === null
                      ? "Not Approved"
                      : moment(`${repay.collected_on}`).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Row align="flex-start">
                      <Tooltip content="View" color="invert">
                        <IconButton
                          onClick={() => {
                            setgetRepay(repay);
                            openView();
                          }}
                        >
                          <EyeIcon fill="black" />
                        </IconButton>
                      </Tooltip>
                      <Spacer x={1} />
                      {adminRole() || superAdminRole() ? (
                        repay.is_approved ? (
                          <Tooltip content="Already Approved" color="success">
                            <ApprovedIcon fill="green" />
                          </Tooltip>
                        ) : (
                          <Tooltip content="Approve" color="primary">
                            <IconButton
                              onClick={() => {
                                setapproveId(repay.id);
                                openApproveModal()
                              }}
                            >
                              <ApproveIcon fill="#8F00FF" />
                            </IconButton>
                          </Tooltip>
                        )
                      ) : (
                        ""
                      )}

                      <Spacer x={1} />
                      {repay.is_approved ? (
                        ""
                      ) : (
                        <Tooltip content="Edit" color="primary">
                          <IconButton
                            onClick={() => {
                              setrepayData(repay);
                            }}
                          >
                            <EditIcon
                              size={20}
                              fill="#007FFF"
                              repayData={repayData}
                              show={true}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Spacer x={1} />
                      {repay.is_approved ? (
                        ""
                      ) : (
                        <Tooltip content="Task" color="warning">
                          <IconButton
                            onClick={() => {
                              setdataID(repay.id);
                              setVisible(true);
                            }}
                          >
                            <TicketIcon fill="#FFC300" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Spacer x={1} />
                      {repay.is_approved ? (
                        ""
                      ) : (
                        <Tooltip content="Delete" color="error">
                          <IconButton
                            onClick={() => {
                              setdataID(repay.id);
                              openDeleteModal();
                            }}
                          >
                            <DeleteIcon fill="#D22B2B" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Row>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {getRepayment.length === 0 ? (
        ""
      ) : (
        <div className="mt-5 flex justify-center">
          {!isReady ? "" : "List of Repayments"}
        </div>
      )}

      <CreateTicket
        getAdmins={getAdmins}
        bindings={bindings}
        closeModal={closeModal}
      />
      <Paginations
        page={pageNumber}
        onChange={handlePageChange}
        initialPage={1}
        total={totalPages}
        length={totalPages}
        onpageLoad={setIsReady}
      />

      <DeleteData
        visible={visible}
        url={repayUrl}
        deleteId={dataID}
        onClose={closeDeleteModal}
      />

      <ApproveAction
        visible={visible2}
        url={approveTaskURL}
        approveId={approveId}
        onClose={closeApproveModal}
        promptMessage={promtMessage}
      />

      <GetRepayments
        open={getSinlgeView}
        onClose={closeView}
        getRepayment={getRepay}
      />
    </div>
  );
}
