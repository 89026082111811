import React, { useState } from "react";
import { Text, Tooltip, Spacer } from "@nextui-org/react";
import { EditIcon } from "../Components/Icons/EditIcon";
import { IconButton } from "../Components/Icons/IconButton";
import DeleteIcon from "../Components/Icons/DeleteIcon";
import EditGuarantor from "../Components/Forms/EditGuarantor";
import DeleteData from "../Components/Shared/DeleteData";
import OpenGurantorForm from "../Components/Shared/OpenGurantorForm";

export default function Guarantor({ guarantorDetails, loanId }) {
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [deleteID, setdeleteID] = useState("");
  const url = "general/delete-guarantor";

  const [openEdit, setopenEdit] = useState(false);

  const openEditForm = () => setopenEdit(true)

  const closeEditForm = () => setopenEdit(false)


  const openDeletePopup = () => setopenDeleteModal(true);
  const closeDeletePopup = () => setopenDeleteModal(false);

  return (
    <div>
      {guarantorDetails?.length <= 0 ? (
        <>
        <OpenGurantorForm loanId={loanId} />
        </>
      ) : (
        <>
          <div className="flex justify-between mb-5">
            <div>
              <Text size={20}>Guarantor Details</Text>
            </div>
            <div className="flex justify-between">
              <Tooltip content="Edit" color="primary">
                <IconButton
                  onClick={() => {
                    openEditForm();
                  }}
                >
                  <EditIcon size={20} fill="#007FFF" />
                </IconButton>
              </Tooltip>
              <Spacer x={1} />
              <Tooltip content="Delete" color="error">
                <IconButton
                  onClick={() => {
                    openDeletePopup();
                    setdeleteID(guarantorDetails.id);
                  }}
                >
                  <DeleteIcon fill="#D22B2B" />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
              <div>
                {" "}
                <Text className="">
                  <span className="text-style">Name: </span>
                  {guarantorDetails.first_name +
                    " " +
                    guarantorDetails.last_name}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">Phone No: </span>
                  {guarantorDetails.phone_number}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">ID Type:</span>{" "}
                  {guarantorDetails.identity_type__name}
                </Text>
              </div>
            </div>
            <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
              <div>
                <Text className="">
                  <span className="text-style">ID No:</span>{" "}
                  {guarantorDetails.id_number}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">Occupation: </span>
                  {guarantorDetails.occupation}
                </Text>
              </div>
              <div>
                <Text className="">
                  <span className="text-style">Position:</span>{" "}
                  {guarantorDetails.position}
                </Text>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-3 small:grid-cols-1 gap-4 p-2">
                <Text className="">
                  <span className="text-style">Address:</span>{" "}
                  {guarantorDetails.address}
                </Text>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <EditGuarantor
          open={openEdit}
          onClose={closeEditForm}
          guarantorDetails={guarantorDetails}
        />
      </div>

      <DeleteData
        deleteId={deleteID}
        visible={openDeleteModal}
        onClose={closeDeletePopup}
        url={url}
      />
    </div>
  );
}
