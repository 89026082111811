import React, { useState } from "react";
import Api from "../../Services/api";
import Loading from "../Icons/Loading";
import { resetPasswordTitle } from "../../Shared/constants";
import { Text, Spacer } from "@nextui-org/react";
import { passw } from "../../Services/validations";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function ResetPassword() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [inputType, setinputType] = useState("password");
  const [inputType1, setinputType1] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordDontMatch, setshowPasswordDontMatch] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const passwordToggle = (value) => {
    if (value === "text") {
      setinputType("password");
    } else {
      setinputType("text");
    }
  };

  const passwordToggle1 = (value) => {
    if (value === "text") {
      setinputType1("password");
    } else {
      setinputType1("text");
    }
  };

  const checkPasswordValidity = (password) => {
    if (!password.match(passw)) {
      setShowPasswordError(true);
    } else {
      setShowPasswordError(false);
    }
  };

  const passwordMatch = (value) => {
    if (value !== password) {
      setshowPasswordDontMatch(true);
    } else setshowPasswordDontMatch(false);
  };

  const resetPassword = (e) => {
    e.preventDefault();
    const data = {
      reset_code: resetCode,
      password: password,
      confirm_password: confirmPassword,
    };

    setShowLoading(true);

    Api()
      .post("/auth/reset-password/", data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };
  return (
    <div className="py-56 small:py-30 bg-no-repeat bg-cover bg-[url('https://coolbackgrounds.io/images/backgrounds/white/white-triangle-369b8d2d.jpg')]">

      <div className="text-center">
        <p className="page-leading-text">{resetPasswordTitle}</p>

        <p className="page-subtitle-text">Set your new password.</p>
      </div>

      <form onSubmit={resetPassword}>
        <div className="auth-spacing">
          <span className="required-label">Code</span>
          <input
          required
            placeholder="Code"
            className="auth-input"
            defaultValue={resetCode}
            onChange={(e) => {
              setResetCode(e.target.value);
            }}
          />
          <Spacer y={0.7} />

          <span className="required-label">Password</span>
          <span className="flex">
            <input
            required
              type={inputType}
              defaultValue={password}
              onChange={(e) => {
                setPassword(e.target.value);
                checkPasswordValidity(e.target.value);
              }}
              className="auth-input"
              placeholder="Password"
            />
            <p
              onClick={() => {
                passwordToggle(inputType);
              }}
              className="-ml-11 mt-1 cursor-default"
              aria-label=""
            >
              {inputType === "password" ? "Show" : "Hide"}{" "}
            </p>
          </span>
          <Spacer y={0.4} />
          {showPasswordError ? (
            <Text size={14} color="red" css={{ margin: "$0" }}>
              Password min of 8, a num & special character.
            </Text>
          ) : (
            ``
          )}

          <span className="required-label">Confirm Password</span>
          <span className="flex">
            <input
            required
              type={inputType1}
              defaultValue={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                passwordMatch(e.target.value);
              }}
              className="auth-input"
              placeholder="New Password"
            />
            <p
              onClick={() => {
                passwordToggle1(inputType1);
              }}
              className="-ml-11 mt-1 cursor-default"
              aria-label=""
            >
              {inputType1 === "password" ? "Show" : "Hide"}{" "}
            </p>
          </span>
          <Spacer y={0.7} />
          {showPasswordDontMatch ? (
            <Text size={14} color="red" css={{ margin: "$0" }}>
              Passwords do not match.
            </Text>
          ) : (
            ``
          )}

          <button
            type="submit"
            className={
              showPasswordDontMatch || showPasswordError
                ? "bg-gray-500 mt-5 w-80 disabled-button"
                : "mt-5 w-80 button-prop"
            }
            disabled={showPasswordDontMatch || showPasswordError}
          >
            {" "}
            {showLoading ? <Loading /> : `Reset Password`}
          </button>
        </div>
      </form>
    </div>
  );
}
