import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { HiPrinter } from "react-icons/hi";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../Services/validations";

export default function ReportGenerator() {
  let location = useLocation();
  let navigate = useNavigate();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DCDCDC",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const goBack = () => {
    navigate(-1);
  };

  const printReport = () => {
    window.print();
  };

  return (
    <div className="my-5 mx-5">
      <div className="flex justify-start">
        <FiChevronLeft
          className="cursor-pointer"
          size={30}
          title="Go Back"
          onClick={goBack}
        />
      </div>
      <div className="flex justify-between mb-5">
        <div></div>
        <img
          src="./images/Logo.jpg"
          alt="Default Image"
          width={100}
          height={100}
        />

        <div className="flex justify-between p-5 cursor-pointer">
          <HiPrinter size={30} title="Print" onClick={printReport} />
        </div>
      </div>

      <div className="flex justify-center bg-green-500 rounded-md">
        <div className="text-xl py-2 ">CLIENT REPORT/STATEMENT</div>
      </div>

      <div className="flex justify-end">
        <div className="text-xl py-2 ">
          {" "}
          <span className="italic font-medium"> CLIENT'S NAME: </span>{" "}
          {location.state.data.borrower.first_name +
            " " +
            location.state.data.borrower.last_name}
        </div>
      </div>

      <div className="flex justify-end">
        <div className="text-xl">
          {" "}
          <span className="italic font-medium"> PHONE NUMBER: </span>
          {location.state.data.borrower.primary_phone_number}
        </div>
      </div>

      <div className="flex justify-end">
        <div className="text-xl">
          {" "}
          <span className="italic font-medium"> LOCATION:</span>{" "}
          {location.state.data.borrower.city}
        </div>
      </div>

      <div className="flex justify-end">
        <div className="text-xl">
          {" "}
          <span className="italic font-medium"> DATE:</span>{" "}
          {location.state.data.date}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="text-2xl font-medium">LOAN TERMS</div>
      </div>

      <div className="flex justify-center mb-10">
        <table className="border-collapse border border-slate-500 w-full">
          <tbody>
            <tr className="bg-green-200">
              <td className="border border-slate-700  h-10 font-bold">LOAN ID</td>
              <td className="border border-slate-700  h-10 font-bold">
                {location.state.data.loan.id}
              </td>
            </tr>
            <tr >
              <td className="border border-slate-700 h-10 font-bold">RELEASE DATE</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {moment(`${location.state.data.loan.approved_on}`).format(
                  "lll"
                )}
              </td>
            </tr>
            <tr className="bg-green-200">
              <td className="border border-slate-700 h-10 font-bold">MATURITY DATE</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {moment(`${location.state.data.loan.due_date}`).format("ll")}
              </td>
            </tr>
            <tr >
              <td className="border border-slate-700 h-10 font-bold">REPAYMENT CYCLE</td>
              <td className="border border-slate-700 h-10 font-bold">
                {location.state.data.loan.repayment_cycle__name}
              </td>
            </tr>
            <tr className="bg-green-200">
              <td className="border border-slate-700 h-10 font-bold">INTEREST AMOUNT</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {currencyFormat.format(location.state.data.interest_amount)}
              </td>
            </tr>
            <tr>
              <td className="border border-slate-700 h-10 font-bold">
                REPLACEMENT AMOUNT
              </td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {currencyFormat.format(location.state.data.replacement_amount)}
              </td>
            </tr>
     
            <tr className="bg-green-200">
              <td className="border border-slate-700 h-10 font-bold">
                SETTLEMENT AMOUNT
              </td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {currencyFormat.format(location.state.data.settlement_amount)}
              </td>
            </tr>
            <tr>
              <td className="border border-slate-700 h-10 font-bold">PENALTY AMOUNT</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {currencyFormat.format(location.state.data.penalty_amount)}
              </td>
            </tr>
            <tr className="bg-green-200">
              <td className="border border-slate-700 h-10 font-bold">PRINCIPAL AMOUNT</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {currencyFormat.format(
                  location.state.data.loan.principal_amount
                )}
              </td>
            </tr>
            <tr>
              <td className="border border-slate-700 h-10 font-bold">TOTAL DUE AMOUNT</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {currencyFormat.format(location.state.data.loan.amount_payable)}
              </td>
            </tr>
            <tr className="bg-green-200">
              <td className="border border-slate-700 h-10 font-bold">APR</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {location.state.data.apr}&#37;
              </td>
            </tr>
            <tr>
              <td className="border border-slate-700 h-10 font-bold">PAID AMOUNT</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {currencyFormat.format(location.state.data.loan.total_paid)}
              </td>
            </tr>
            <tr className="bg-green-200">
              <td className="border border-slate-700 h-10 font-bold">DAYS IN ARREARS</td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {location.state.data.arrears_days}
              </td>
            </tr>
            <tr>
              <td className="border border-slate-700 h-10 font-bold">
                OUSTANDING BALANCE
              </td>
              <td className="border border-slate-700 h-10 font-bold">
                {" "}
                {currencyFormat.format(
                  location.state.data.loan.amount_outstanding
                )}
              </td>
            </tr>
            <tr className="bg-green-200">
              <td className="border border-slate-700 h-10 font-bold">
                NUMBER OF REPAYMENTS MADE
              </td>
              <td className="border border-slate-700 h-10 font-bold">
              {location.state.data.total_number_of_repayments}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <div className="flex justify-center">
        <div className="text-2xl font-medium">NUMBER OF REPAYMENTS</div>
      </div>

      <div className="flex justify-center mb-10">
        <table className="border-separate border border-slate-400 w-full">
          <tbody>
            <tr>
              <td className="border border-slate-300 w-[50%] h-10">
                Number of repayments made
              </td>
              <td className="border border-slate-300 w-[50%] h-10">
                {location.state.data.total_number_of_repayments}
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <div className="flex justify-center mt-32">
        <div className="text-2xl font-medium">STATEMENT OF TRANSACTIONS</div>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="spanning table">
          <TableHead sx={{ backgroundColor: "blue" }}>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: "bold" }}>
                Description
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: "bold" }}>
                Debit
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: "bold" }}>
                Balance
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {location.state.data.repayments.map((repay, index) => (
              <TableRow key={index}>
                <TableCell>
                  {moment(`${repay.collected_on}`).format("lll")}
                </TableCell>
                <TableCell align="right">{repay.repayment_type}</TableCell>
                <TableCell align="right">
                  {" "}
                  {currencyFormat.format(repay.amount)}
                </TableCell>
                <TableCell align="right">
                  {currencyFormat.format(repay.balance)}
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell rowSpan={3} />
              <TableCell colSpan={3} />
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
                Credit
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
                {currencyFormat.format(location.state.data.loan.amount_payable)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
