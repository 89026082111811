import React, { useState, useEffect } from "react";
import Api from "../../Services/api";
import { addUserFormTitle, SystemUserType } from "../../Shared/constants";
import { Modal, Text, Button } from "@nextui-org/react";
import AddFormButton from "../Shared/AddFormButton";
import { useSnackbar } from "notistack";

export default function AddUser({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [phone_number, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [staffID, setStaffID] = useState("");
  const [roleId, setRoleId] = useState("2");
  const [countryId, setCountryId] = useState("");
  const [countries, setCountries] = useState([]);
  const [showLoading, setShowLoading] = useState(false);


  const getCountries = () => {
    Api()
      .get("/general/all-countries/")
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  };

  const addSystemuser = async (e) => {
    e.preventDefault();
    const data = {
      phone_number: phone_number,
      first_name: firstName,
      last_name: lastName,
      role_id: roleId,
      country_id: countryId,
      staff_id: staffID,
    };

    setShowLoading(true);
      Api()
      .post("/auth/add-staff/", data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
        setShowLoading(false)
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={onClose}
        scroll
        width="600px"
        preventClose
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {addUserFormTitle}
          </Text>
        </Modal.Header>
          <Modal.Body className="small:m-0 m-5">
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">Phone Number</span>
                <input
                  required
                  type="tel"
                  name="phone_number"
                  defaultValue={phone_number}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="required-label">First Name</span>
                <input
                  required
                  type="text"
                  name="firstName"
                  defaultValue={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="required-label">Last Name</span>
                <input
                  required
                  type="text"
                  name="lastName"
                  defaultValue={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
              <label className="block mt-5">
                <span className="required-label">Role</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setRoleId(e.target.value);
                  }}
                >
                  {SystemUserType?.map((userType, index) => {
                    return (
                      <option value={userType.value} key={index}>
                        {userType.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="grid grid-cols-2 small:grid-cols-1 gap-4">
              <label className="block mt-5">
                <span className="not-required-label">Country</span>
                <select
                  className="input-style"
                  onChange={(e) => {
                    setCountryId(e.target.value);
                  }}
                >
                  {countries?.map((country, index) => {
                    return (
                      <option value={country.id} key={index}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="block mt-5">
                <span className="not-required-label">Staff ID</span>
                <input
                  type="text"
                  name="staffId"
                  defaultValue={staffID}
                  onChange={(e) => {
                    setStaffID(e.target.value);
                  }}
                  className="input-style"
                />
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button auto flat color="error" onClick={onClose}>
              Close
            </Button>
            <AddFormButton loading={showLoading} onClick={addSystemuser} />
          </Modal.Footer>
      </Modal>
    </>
  );
}
