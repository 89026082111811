import "./App.css";
import React, { createContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Login from "./Components/Auth/Login";
import { isLoggedIn } from "./Services/auth";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import Sidebar from "./Components/Layouts/Sidebar";
import Home from "./Views/Home";
import Borrower from "./Views/Borrower";
import DetailsOfBorrower from "./Views/DetailsOfBorrower";
import DefaultPage from "./Views/DefaultPage";
import Loans from "./Views/Loans";
import Earnings from "./Views/Earnings";
import Transactions from "./Views/Transactions";
import InactiveUsers from "./Views/InactiveUsers";
import InactiveBorrower from "./Views/InactiveBorrowers";
import OverdueLoans from "./Views/OverdueLoans";
import Repayments from "./Views/Repayments";
import DetailsOfLoan from "./Views/DetailsOfLoan";
import ChangePassword from "./Views/ChangePassword";
import Tickets from "./Views/Tickets";
import Portfolio from "./Views/Portfolio";
import GenerateReport from "./Views/GenerateReport";
import Profit from "./Views/Profit";
import ReportGenerator from "./Views/ReportGenerator";
import TransactionsStats from "./Views/TransactionsStats";
import Expenses from "./Views/Expenses";
import DueRepayments from "./Views/DueRepayments";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            preventDuplicate
          >
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="*"
                element={
                  <main className="text-center bg-red-500">
                    <p>There's nothing here!</p>
                  </main>
                }
              />
              <Route
                path="/transactions"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Transactions />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/earnings"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Earnings />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/repayments"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Repayments />} />
                  </PrivateRoute>
                }
              />
                     <Route
                path="/due-repayments"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<DueRepayments />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loans"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Loans />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/overdue-loans"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<OverdueLoans />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/default"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<DefaultPage />} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/users"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Home />} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/inactive-users"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<InactiveUsers />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/borrowers"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Borrower />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/inactive-borrowers"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<InactiveBorrower />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/borrower-details"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<DetailsOfBorrower />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loan-details"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<DetailsOfLoan />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/change-password"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<ChangePassword />} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/tasks"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Tickets />} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/statistics"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Portfolio />} />
                  </PrivateRoute>
                }
              />

              <Route path="/report" element={<ReportGenerator />} />

              <Route
                path="/profit"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Profit />} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/trans-stats"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<TransactionsStats />} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/expenses"
                element={
                  <PrivateRoute>
                    <Sidebar pages={<Expenses />} />
                  </PrivateRoute>
                }
              />
              <Route path="/report-generator" element={<ReportGenerator />} />
            </Routes>
          </SnackbarProvider>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;

function PrivateRoute({ children }) {
  let auth = isLoggedIn();
  let location = useLocation();

  if (auth === false) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

const authContext = createContext();

function AuthProvider({ children }) {
  const auth = isLoggedIn();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}


