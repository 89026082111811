import React, { useState } from "react";
import Api from "../../Services/api";
import Loading from "../Icons/Loading";
import { forgotPasswordTitle } from "../../Shared/constants";
import { useNavigate } from "react-router-dom";
import { Link } from "@nextui-org/react";
import { useSnackbar } from "notistack";

export default function ForgotPassword() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [phone_number, setPhoneNumber] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const requestForCode = (e) => {
    e.preventDefault();
    const data = {
      phone_number: phone_number,
    };
    setShowLoading(true);
    Api()
      .post("auth/password-reset-code/", data)
      .then((response) => {
        enqueueSnackbar(response.data.detail, {
          variant: "success",
          autoHideDuration: 1000,
        });
        setTimeout(() => {
          navigate("/reset-password", { replace: true });
        }, 1500);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
          autoHideDuration: 1500,
        });
      });
  };

  

  return (
    <div className="py-72 small:py-60 bg-no-repeat bg-cover bg-[url('https://coolbackgrounds.io/images/backgrounds/white/white-triangle-369b8d2d.jpg')]">
      <form onSubmit={requestForCode}>
        <div className="text-center">
          <p className="page-leading-text">{forgotPasswordTitle}</p>
          <p className="page-subtitle-text">Provide number to continue</p>
        </div>
        <div className="auth-spacing">
          <label className="block mt-5">
            <span className="required-label">Phone Number</span>
            <input
              required
              type="tel"
              name="tel"
              defaultValue={phone_number}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              className="auth-input"
            />
          </label>

          <button type="submit" className="mt-5 mb-3 w-80 button-prop">
            {" "}
            {showLoading ? <Loading /> : `Request For Code`}
          </button>

          <Link block color="primary" href="/">
            Back
          </Link>
        </div>
      </form>
    </div>
  );
}
