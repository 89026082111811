import React, { useState } from "react";
import Cookies from "js-cookie";
import { Text, Image, Spacer } from "@nextui-org/react";
import Loading from "../Icons/Loading";
import * as auth from "../../Services/auth";
import { useSnackbar } from 'notistack';
import { phoneValidation, year } from "../../Services/validations";
import { NavLink, useNavigate } from "react-router-dom";
import {
  developersCompanyname,
  forgotPasswordText,
  applicationAbbrieviation,
} from "../../Shared/constants";

export default function Login() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [inputType, setinputType] = useState("password");
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const passwordToggle = (value) => {
    if (value === "text") {
      setinputType("password");
    } else {
      setinputType("text");
    }
  };

  const Login = async (e) => {
    e.preventDefault();
    const loginData = {
      phone_number,
      password,
    };

    setShowLoading(true);
    auth
      .loginUser(loginData)
      .then((response) => {
        Cookies.set("pagetitle", "Users");
        navigate("/tasks", { replace: true });
      })
      .catch((error) => {
        setShowLoading(false);
        enqueueSnackbar(error.response.data.detail, { variant: "error", autoHideDuration: 1500 });
      });
  };

  const checkPasswordValidity = (password) => {
    if (password.length < 8) {
      setShowPasswordError(true);
    } else {
      setShowPasswordError(false);
    }
  };

  const checkPhoneValidity = (phone) => {
    if (!phone.match(phoneValidation)) {
      setShowPhoneError(true);
    } else {
      setShowPhoneError(false);
    }
  };

  

  return (
    <div className="py-40 small:py-24 bg-no-repeat bg-cover bg-[url('https://coolbackgrounds.io/images/backgrounds/white/white-triangle-369b8d2d.jpg')] " >

      <div className="text-center">
        <Image
          src="./images/Logo.jpg"
          alt="Default Image"
          width={100}
          height={100}
        />

        {/* <p className="page-leading-text">{applicationAbbrieviation}</p> */}

        <p className="page-subtitle-text">Log in to continue.</p>
      </div>

      <form onSubmit={Login}>
        <div className="auth-spacing ">
          <span className="required-label">Phone Number</span>
          <input
          required
          placeholder="Phone Number"
            className="auth-input"
            defaultValue={phone_number}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              checkPhoneValidity(e.target.value);
            }}
          />

          <Spacer y={0.7} />

          {showPhoneError ? (
            <Text size={14} color="red" css={{ margin: "$0" }}>
              Phone number must be exactly 10 characters.
            </Text>
          ) : (
            ``
          )}
          <span className="required-label mt-3">Password</span>
          <span className="flex">
            <input
            required
              type={inputType}
              defaultValue={password}
              onChange={(e) => {
                setPassword(e.target.value);
                checkPasswordValidity(e.target.value);
              }}
              className="auth-input"
              placeholder="Password"
            />
            <p
              onClick={() => {
                passwordToggle(inputType);
              }}
              className="-ml-11 mt-2 cursor-default"
              aria-label=""
            >
              {inputType === "password" ? "Show" : "Hide"}{" "}
            </p>
          </span>
          {showPasswordError ? (
            <Text size={14} color="red" css={{ margin: "$0" }}>
              Password must have min of 8 characters.
            </Text>
          ) : (
            ``
          )}

          <button
            type="submit"
            className={
              showPhoneError || showPasswordError
                ? "mt-5 w-80 disabled-button mb-3"
                : "mt-5 w-80 button-prop mb-3"
            }
            disabled={showPhoneError || showPasswordError || phone_number.length < 10}
          >
            {" "}
            {showLoading ? <Loading /> : `Log In`}
          </button>

          <NavLink
            to="/forgot-password"
            className=" hover:text-buttonColor text-buttonColor"
          >
            {forgotPasswordText}
          </NavLink>

          <Text size={14} color="black" css={{ marginTop: "$2" }}>
            @{year} {developersCompanyname}
          </Text>
        </div>
      </form>
    </div>
  );
}
